import Search from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { JSX } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { LocationReferenceInputSuffix } from '../LocationReferenceInputSuffix';
import { StyledTextInput } from '../Shared.theme';

export const GoogleInput = ({ placeholder, onClear }: { placeholder: string; onClear: () => void }): JSX.Element => {
    const {
        searchValue,
        setSearchValue,
        googleSuggestion: { selectedGoogleSuggestion },
    } = useLocationSearch();
    const selectedGoogleSuggestionName = selectedGoogleSuggestion?.name || '';
    const displayValue = searchValue || selectedGoogleSuggestionName;

    const TextInputSuffix = (): JSX.Element => (
        <LocationReferenceInputSuffix onClear={onClear} counterLength={selectedGoogleSuggestion ? 1 : 0} />
    );

    return (
        <StyledTextInput
            onChange={(event): void => {
                const searchValueText = event.target.value;
                if (searchValueText === '') {
                    onClear();

                    return;
                }

                setSearchValue(event.target.value);
            }}
            value={displayValue}
            placeholder={placeholder}
            prefix={Search}
            suffix={TextInputSuffix}
            autoFocus
        />
    );
};

import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { CheckboxInput } from '@nexus/lib-react/dist/core/CheckboxInput';
import { ListContainer, ListItem } from '@nexus/lib-react/dist/core/List';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';
import { Label2, P2, P4 } from '@nexus/lib-react/dist/core/Typography';

export const ContentContainer = styled.div<{ alwaysFullHeight?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: ${theme.space[0]} ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        min-height: ${({ alwaysFullHeight }): CSSProperties['minHeight'] => (alwaysFullHeight ? '490px' : 'auto')};
        max-height: 490px;
        padding: ${theme.space[16]} ${theme.space[0]} ${theme.space[0]} ${theme.space[0]};
        overflow: auto;
    }
`;

export const StyledListContainer = styled(ListContainer)`
    width: 100%;
    padding: ${theme.space[0]};
    background: transparent;
`;

export const StyledListItem = styled(ListItem)`
    width: 100%;
    border-bottom: none;
`;

export const StyledBackButton = styled(StyledListItem)`
    margin-top: -${theme.space[16]};
    padding-left: ${theme.space[16]};

    &:hover {
        background: ${theme.colors['background-global-primary']};
    }
`;

export const StyledListItemTitle = styled(P2)`
    margin: 0 0 ${theme.space[4]};
`;

export const StyledListItemSubtitle = styled(P4)`
    color: ${theme.colors['text-global-secondary']};
`;

export const ButtonContainer = styled.span`
    display: flex;
    justify-content: center;
    margin-left: ${theme.space['16']};
`;

export const StyledListItemWrapper = styled.div<{ nestingIndex?: number }>`
    display: flex;
    justify-content: space-between;
    padding: ${({ nestingIndex }): CSSProperties['padding'] =>
        `${theme.space[0]} ${theme.space[16]} ${theme.space[0]} ${16 + (nestingIndex ?? 0) * 24}px`};

    &:hover {
        background: ${theme.colors['background-global-primary']};
    }
`;

export const StyledLabel = styled(Label2)`
    padding: ${theme.space[0]} ${theme.space[16]};
    text-transform: uppercase;
`;

export const ListLabel = styled(Label2)`
    padding: ${theme.space[16]} ${theme.space[16]} ${theme.space[8]} ${theme.space[16]};
    text-transform: uppercase;
`;

export const StyledTextInput = styled(TextInput)`
    width: auto;
    margin: ${theme.space[0]} ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        width: inherit;
        margin: 0;
    }

    input {
        border-bottom: 2px solid;
        border-radius: 4px 4px 0 0;
        border-color: ${theme.colors['borders-brand-primary']};
        outline: none;
    }
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.space['16']};
    margin-right: ${theme.space['16']};
`;

export const ItemWrapper = styled.div`
    display: flex;
`;

import type { JSX } from 'react';

import { ConfirmButton, ConfirmContainer } from './MobileConfirm.theme';

export const MobileConfirm = ({ onClick, text }: { onClick: () => void; text: string }): JSX.Element => (
    <ConfirmContainer>
        <ConfirmButton onClick={onClick} id="confirm-location-search">
            {text}
        </ConfirmButton>
    </ConfirmContainer>
);

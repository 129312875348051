import { useTracking } from '@lib/tracking/useTracking';
import { type ReferenceInputProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { checkShouldDisplayCityView } from '../../helpers/checkShouldDisplayCityView';
import { LocationReferenceInputSuffix } from '../LocationReferenceInputSuffix';
import { CustomReferenceInput } from './CustomReferenceInput';

const TextInputSuffix = (): JSX.Element => {
    const { trackEvent } = useTracking();
    const {
        resetState,
        locations: { selectedLocations },
        config: {
            tracking: { touchPointPage },
            multiple,
        },
    } = useLocationSearch();

    const onClear = useCallback(() => {
        resetState();
        trackEvent('clear_filters', {
            event_type: 'click',
            touch_point_button: 'location_picker_main',
            touch_point_page: touchPointPage,
        });
    }, [resetState, touchPointPage, trackEvent]);

    const isMultiple = checkShouldDisplayCityView(selectedLocations[0]) && multiple;

    return (
        <LocationReferenceInputSuffix
            onClear={onClear}
            counterLength={selectedLocations.length}
            multiple={isMultiple}
        />
    );
};

export const LocationsReferenceInput = ({ setDropdownState, isOpen }: ReferenceInputProps): JSX.Element => {
    const {
        searchValue,
        locations: { selectedLocations },
    } = useLocationSearch();
    const selectedLocationsText = selectedLocations.map((location) => location.name).join(', ');
    const displayValue = isOpen ? searchValue : selectedLocationsText;

    return (
        <CustomReferenceInput
            setDropdownState={setDropdownState}
            textInputSuffix={TextInputSuffix}
            placeholder="frontend.search.form.location-input-label"
            displayValue={displayValue}
        />
    );
};

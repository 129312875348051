import { SEARCH_CONFIG } from '@config/search/searchConfig';
import type { LocationsObjects } from '@type/search/location/dataItems';

import { useLocationSearch } from '../contexts/useLocationSearch';
import type { Data } from '../queries/locationDetailsQuery';

const findLocationById = (id: string, locations: LocationsObjects[]): LocationsObjects | null => {
    for (const location of locations) {
        if (location.id === id) {
            return location;
        }
        const foundLocation = findLocationById(id, location.children ?? []);
        if (foundLocation) {
            return foundLocation;
        }
    }

    return null;
};

const findLocationByIdInCityTree = (id: string, cityTree: Data['locationDetails']): LocationsObjects | null => {
    if (cityTree.id === id) {
        return cityTree;
    }

    return findLocationById(id, cityTree.children);
};

const handleSelectLocation = ({
    locationInCityTree,
    locationLevelLikeCity,
    cityTree,
    selectedLocationsSource,
}: {
    locationInCityTree: LocationsObjects;
    locationLevelLikeCity: string[];
    cityTree: Data['locationDetails'];
    selectedLocationsSource: LocationsObjects[];
}): LocationsObjects[] => {
    // unselect all children from selected location
    const childrenIds = locationInCityTree?.children?.map((i) => i.id);

    let locationsToSelect = [...selectedLocationsSource, locationInCityTree].filter(
        (locationToSelect) =>
            !locationLevelLikeCity.includes(locationToSelect.detailedLevel) &&
            !childrenIds?.includes(locationToSelect.id),
    );

    // check for siblings select to autoselect parent location instead of multiple siblings
    const directParent = locationInCityTree.parents?.[locationInCityTree.parents.length - 1];
    if (directParent) {
        const directParentInCityTree = findLocationByIdInCityTree(directParent?.id, cityTree);
        const siblingIds = directParentInCityTree?.children?.map((i) => i.id);
        const isEverySiblingSelected = siblingIds?.every((siblingId) =>
            locationsToSelect.some(({ id }) => id === siblingId),
        );
        if (siblingIds && isEverySiblingSelected) {
            locationsToSelect.push(directParent);
            locationsToSelect = locationsToSelect.filter(({ id }) => !siblingIds.includes(id));
        } else {
            locationsToSelect = locationsToSelect.filter(({ id }) => id !== directParent.id);
        }
    }

    return locationsToSelect;
};

const handleUnselectLocation = ({
    locationInCityTree,
    cityTree,
    selectedLocationsSource,
    isTriggeredFromList,
}: {
    locationInCityTree: LocationsObjects;
    cityTree: Data['locationDetails'];
    selectedLocationsSource: LocationsObjects[];
    isTriggeredFromList?: boolean;
}): LocationsObjects[] => {
    // unselect all children from unselected location
    const childrenIds = locationInCityTree?.children?.map((i) => i.id);
    let locationsToSelect: LocationsObjects[] = selectedLocationsSource.filter(
        ({ id }) => id !== locationInCityTree.id && !childrenIds?.includes(id),
    );

    // if location have parent selected, unselect only location and select rest of the siblings
    const directParent = locationInCityTree.parents?.[locationInCityTree.parents.length - 1];
    if (directParent && cityTree) {
        const directParentInCityTree = findLocationByIdInCityTree(directParent?.id, cityTree);
        const isDirectParentSelected = locationsToSelect.some(({ id }) => id === directParent.id);

        if (isDirectParentSelected) {
            const siblings = directParentInCityTree?.children?.filter(({ id }) => id !== locationInCityTree.id);
            locationsToSelect.push(...(siblings || []));
            locationsToSelect = locationsToSelect.filter(({ id }) => id !== directParent.id);
        }
    }

    if (locationsToSelect.length === 0 && isTriggeredFromList) {
        locationsToSelect.push(cityTree);
    }

    return locationsToSelect;
};

interface UseCityViewLocationsReturnValue {
    selectLocation: (id: string) => LocationsObjects[];
    unselectLocation: (id: string, isTriggeredFromList?: boolean) => LocationsObjects[];
    checkIsSelected: (id: string) => boolean;
}

export const useCityViewLocations = ({
    cityTree,
    shouldUseTemporarySuggestions,
}: {
    cityTree?: Data['locationDetails'];
    shouldUseTemporarySuggestions: boolean;
}): UseCityViewLocationsReturnValue => {
    const { locationLevelLikeCity } = SEARCH_CONFIG;

    const {
        locations: { temporarySuggestions, selectTemporarySuggestions, selectedLocations, selectLocations },
    } = useLocationSearch();

    const selectLocationsSourceFunction = shouldUseTemporarySuggestions ? selectTemporarySuggestions : selectLocations;
    const selectedLocationsSource = shouldUseTemporarySuggestions ? temporarySuggestions : selectedLocations;
    const selectLocation = (id: string): LocationsObjects[] => {
        if (!cityTree) {
            return [];
        }

        const locationInCityTree = findLocationByIdInCityTree(id, cityTree);

        if (!locationInCityTree) {
            return [];
        }

        // stop at city and select city directly
        if (locationLevelLikeCity.includes(locationInCityTree.detailedLevel)) {
            selectLocationsSourceFunction([locationInCityTree]);

            return [locationInCityTree];
        }

        const locationsToSelect = handleSelectLocation({
            locationLevelLikeCity,
            locationInCityTree,
            cityTree,
            selectedLocationsSource,
        });

        selectLocationsSourceFunction(locationsToSelect);

        return locationsToSelect;
    };

    const unselectLocation = (id: string, isTriggeredFromList: boolean = false): LocationsObjects[] => {
        if (!cityTree) {
            return [];
        }

        const locationInCityTree = findLocationByIdInCityTree(id, cityTree);

        if (!locationInCityTree) {
            return [];
        }

        /** do not unselect city (selected city means nothing else is selected) */
        if (locationLevelLikeCity.includes(locationInCityTree.detailedLevel) && isTriggeredFromList) {
            return selectedLocationsSource;
        }

        const locationsToSelect = handleUnselectLocation({
            locationInCityTree,
            cityTree,
            selectedLocationsSource,
            isTriggeredFromList,
        });

        selectLocationsSourceFunction(locationsToSelect);

        return locationsToSelect;
    };

    const checkIsSelected = (id: string): boolean => {
        if (!cityTree) {
            return false;
        }

        const locationInCityTree = findLocationByIdInCityTree(id, cityTree);

        if (!locationInCityTree) {
            return false;
        }

        const parent = locationInCityTree.parents?.[locationInCityTree.parents.length - 1];
        const selectableParentId = parent && !locationLevelLikeCity.includes(parent.detailedLevel) ? parent.id : null;
        const isSelected = selectedLocationsSource.some(
            ({ id }) => id === locationInCityTree.id || id === selectableParentId,
        );

        return isSelected;
    };

    return {
        selectLocation,
        unselectLocation,
        checkIsSelected,
    };
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { StyledListItemTitle } from '../Shared.theme';

export const StyledSuggestionRowListItemTitle = styled(StyledListItemTitle)`
    font-weight: ${theme.fontWeights.bold};

    & > mark {
        background-color: transparent;
        font-weight: ${theme.fontWeights.regular};
    }
`;

import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useRef } from 'react';

import { useRangeField } from './hooks/useRangeField';
import { Fieldset, FieldsPair, InputWrapper, Legend, StyledInput, Suffix } from './RangeField.theme';

interface Props {
    name: string;
    legend: string;
    dataCy: string;
    suffix?: string;
    shouldAddSeparator?: boolean;
    trackOnBlur?: (min: number, max: number, trackedInput: 'min' | 'max') => void;
}

export const RangeField = ({
    name,
    legend,
    dataCy,
    suffix,
    shouldAddSeparator = true,
    trackOnBlur,
}: Props): JSX.Element => {
    const [t] = useTranslations();

    const minFieldRef = useRef<HTMLInputElement | null>(null);
    const maxFieldRef = useRef<HTMLInputElement | null>(null);

    const { inputProps, registerField, createInputBlurHandler, createInputChangeHandler } = useRangeField({
        minFieldRef,
        maxFieldRef,
        name,
        dataCy,
        shouldAddSeparator,
        trackOnBlur,
    });

    return (
        <Fieldset>
            <Legend>{legend}</Legend>
            <FieldsPair>
                <InputWrapper>
                    <StyledInput
                        {...inputProps.min}
                        autoComplete="off"
                        inputMode="decimal"
                        name={registerField.min.name}
                        ref={(input: HTMLInputElement): void => {
                            registerField.min.ref(input);
                            minFieldRef.current = input;
                        }}
                        type="text"
                        placeholder={t(GET_NON_GENERIC_TRANSLATION_KEY.label('nexus-range-min'))}
                        onBlur={createInputBlurHandler('min')}
                        onChange={createInputChangeHandler('min')}
                    />
                    {suffix ? <Suffix>{suffix}</Suffix> : null}
                </InputWrapper>
                <InputWrapper>
                    <StyledInput
                        {...inputProps.max}
                        autoComplete="off"
                        inputMode="decimal"
                        name={registerField.max.name}
                        ref={(input: HTMLInputElement): void => {
                            registerField.max.ref(input);
                            maxFieldRef.current = input;
                        }}
                        type="text"
                        placeholder={t(GET_NON_GENERIC_TRANSLATION_KEY.label('nexus-range-max'))}
                        onBlur={createInputBlurHandler('max')}
                        onChange={createInputChangeHandler('max')}
                    />
                    {suffix ? <Suffix>{suffix}</Suffix> : null}
                </InputWrapper>
            </FieldsPair>
        </Fieldset>
    );
};

import { gql } from '@urql/core';

export const LOCATION_TREE_QUERY = gql`
    query locationTree($locationId: String!) {
        locationTree(locationId: $locationId) {
            __typename
            ... on FoundLocationTree {
                locationTreeObjects {
                    id
                    name
                    detailedLevel
                    sublocations {
                        id
                        detailedLevel
                        name
                        parentIds
                    }
                }
            }
        }
    }
`;

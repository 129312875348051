import { useEffect } from 'react';

/**
 * Execute callback on windows resize
 * Will execute callback at least once
 * @param callback
 */
export const useOnWindowResize = (callback: () => void): void => {
    // only run in browser
    useEffect(() => {
        window.addEventListener('resize', callback);
        callback();

        return (): void => window.removeEventListener('resize', callback);
    }, [callback]);
};

import { Dropdown } from '@domains/shared/components/Dropdown/Dropdown';
import { NexusDropdown } from '@domains/shared/components/Dropdown/NexusDropdown';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { DropdownProps } from '@widgets/search/types/dropdownProps';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Container } from './DropdownSelect.theme';

const KEYS_WITHOUT_ACTIVE_PRESENTATION = new Set<SearchFormUniversalFieldName>([
    SEARCH_FORM_UNIVERSAL_FIELD.estate,
    SEARCH_FORM_UNIVERSAL_FIELD.transaction,
    SEARCH_FORM_UNIVERSAL_FIELD.market,
    SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius,
    SEARCH_FORM_UNIVERSAL_FIELD.investmentEstateType,
    SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
]);

export const DropdownSelect = ({
    name,
    options,
    instanceId,
    inputId,
    dataCy,
    ariaLabel,
    shouldUseNexusTheme,
    trackOnMenuOpen,
    trackOnSelect,
    getEventListeners,
    ...props
}: DropdownProps): JSX.Element => {
    const [dropdownOptions, setDropdownOptions] = useState(options);
    const { runEventListener, unbindEventListener } = getEventListeners?.(setDropdownOptions) || {};
    const { control } = useFormContext();
    const {
        field: { ref, value: controlValue, ...controlProps },
    } = useController({ control, name });

    const { defaultValue, ...rest } = props;

    useEffect(() => {
        runEventListener?.();

        return (): void => {
            unbindEventListener?.();
        };
    }, [runEventListener, unbindEventListener]);

    const replaceNumberValueWithOptionObject = (
        value: number | string | DropdownOption,
    ): DropdownOption | undefined => {
        if (typeof value === 'number' || typeof value === 'string') {
            return options.find((option) => option.value === String(value));
        }

        return value;
    };

    const value = replaceNumberValueWithOptionObject(controlValue);

    const shouldUseFilterVariant = KEYS_WITHOUT_ACTIVE_PRESENTATION.has(name as SearchFormUniversalFieldName);

    if (shouldUseNexusTheme) {
        return (
            <NexusDropdown
                variant={shouldUseFilterVariant ? 'default' : 'filter'}
                shouldTranslateGroupLabel={false}
                shouldTranslateLabels={false}
                isSearchable={false}
                isClearable={!shouldUseFilterVariant}
                trackOnMenuOpen={trackOnMenuOpen}
                trackOnSelect={trackOnSelect}
                instanceId={instanceId}
                inputId={inputId}
                options={dropdownOptions}
                aria-label={ariaLabel}
                dataCy={dataCy}
                {...controlProps}
                {...rest}
                value={value}
            />
        );
    }

    return (
        <Container>
            <Dropdown
                variant={shouldUseFilterVariant ? 'default' : 'filter'}
                shouldTranslateGroupLabel={false}
                shouldTranslateLabels={false}
                isSearchable={false}
                isClearable={!shouldUseFilterVariant}
                trackOnMenuOpen={trackOnMenuOpen}
                trackOnSelect={trackOnSelect}
                instanceId={instanceId}
                inputId={inputId}
                options={dropdownOptions}
                aria-label={ariaLabel}
                dataCy={dataCy}
                {...controlProps}
                {...rest}
                value={value}
            />
        </Container>
    );
};

import { useEffect } from 'react';
import type { FieldValues, UseFormRegister } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

interface Props {
    name: string;
    trackOnSelect: (option: { value: string }) => void;
}

export const useRadioGroupField = ({
    name,
    trackOnSelect,
}: Props): {
    register: UseFormRegister<FieldValues>;
    currentValue: string;
} => {
    const { register, watch, getValues } = useFormContext();
    const currentValue = getValues(name);

    useEffect(() => {
        const subscription = watch((value, operation) => {
            const shouldTrackEvent = operation.name === name && operation.type === 'change';

            if (shouldTrackEvent) {
                trackOnSelect({ value: value[name] });
            }
        });

        return (): void => {
            subscription.unsubscribe();
        };
    }, [name, trackOnSelect, watch]);

    return {
        register,
        currentValue,
    };
};

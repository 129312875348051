import { SEARCH_CONFIG } from '@config/search/searchConfig';
import type { LocationsObjects } from '@type/search/location/dataItems';

export const getLocationCityTreeId = (locations: LocationsObjects[]): string => {
    const { locationLevelLikeCity } = SEARCH_CONFIG;

    if (locations.length === 0) {
        return '';
    }

    if (locationLevelLikeCity.includes(locations[0].detailedLevel)) {
        return locations[0].id;
    }

    const locationParents = locations[0].parents?.filter((item) => locationLevelLikeCity.includes(item.detailedLevel));

    if (locationParents) {
        return locationParents.slice(-1)[0]?.id;
    }

    return '';
};

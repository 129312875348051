import type { LocationListNode } from '../types/list';

export const fillRootSublocationsWithLocationListNodes = (
    nodes: LocationListNode[],
    loadedLocations: LocationListNode[],
    rootId: string,
): LocationListNode[] =>
    loadedLocations.map((location) => {
        if (location.id === rootId) {
            return { ...location, sublocations: nodes };
        }

        return location;
    });

import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { type JSX } from 'react';

import { CityView } from '../components/CityView/CityView';
import { ListView } from '../components/ListView';
import { StreetView } from '../components/StreetView';
import { SuggestionsView } from '../components/SuggestionsView';
import type { LocationSearchConfig } from '../contexts/types';
import type { LocationSearchView } from '../types/locationSearchProps';
import { LOCATION_PICKER_VIEW } from '../types/locationSearchProps';
import { checkShouldDisplayCityView } from './checkShouldDisplayCityView';

export const getDropdownContent = ({
    locationSearchType,
    selectedLocations,
    temporarySuggestions,
    isDesktop,
    config,
}: {
    locationSearchType: LocationSearchView;
    selectedLocations: LocationsObjects[];
    temporarySuggestions: LocationsObjects[];
    isDesktop: boolean | null;
    config: LocationSearchConfig;
}): ((props: FlyoutProps<unknown>) => JSX.Element) => {
    const locations = isDesktop ? selectedLocations : temporarySuggestions;
    const shouldDisplayCityView = locations.length > 0 ? checkShouldDisplayCityView(locations[0]) : false;
    const isMultiLocationSearch = shouldDisplayCityView && config.multiple;

    if (isMultiLocationSearch) {
        return CityView;
    }

    if (locationSearchType === LOCATION_PICKER_VIEW.list && config.enableSearchFromList) {
        return ListView;
    }
    if (locationSearchType === LOCATION_PICKER_VIEW.street && config.streetSearchConfig.enabled) {
        return StreetView;
    }

    return SuggestionsView;
};

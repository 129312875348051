import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { JSX } from 'react';
import { memo, useContext, useMemo } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { DesktopDropdown, DropdownWrapper, MobileDropdown } from './EntryInput.theme';
import { GoogleReferenceInput } from './GoogleReferenceInput';
import { LocationsReferenceInput } from './LocationsReferenceInput';
import type { ContentProps } from './types';

const EntryInputBase = ({ Content }: ContentProps): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const {
        googleSuggestion: { selectedGoogleSuggestion },
        config,
    } = useLocationSearch();

    const DropdownComponent = useMemo(() => (isDesktop ? DesktopDropdown : MobileDropdown), [isDesktop]);

    const shouldDisplayGoogleInput = config.streetSearchConfig?.enabled && selectedGoogleSuggestion;
    const referenceComponent = shouldDisplayGoogleInput ? GoogleReferenceInput : LocationsReferenceInput;

    return (
        <DropdownWrapper>
            <DropdownComponent
                referenceComponent={referenceComponent}
                flyOutComponent={Content}
                // custom implementation because it have one render behind values in dropdownToggle
                // and cause race condition for set state during picker view during 'on close/on clear'
                closeOnClickOutside={false}
                displayPolicy={{
                    extraSmall: 'fullscreen',
                    small: 'fullscreen',
                    medium: 'fullscreen',
                }}
                flyoutContainer={isDesktop ? 'inline' : 'portal'}
            />
        </DropdownWrapper>
    );
};

export const EntryInput = memo(EntryInputBase);

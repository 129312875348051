import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

interface Props {
    shouldSpaceSiblings: boolean;
    gridArea?: string;
}

export const FieldRow = styled.div<Props>`
    @media ${theme.mediaQueries.lgMin} {
        ${({ gridArea }): SerializedStyles | undefined => {
            if (gridArea) {
                return css`
                    grid-area: ${gridArea};
                `;
            }
        }}
    }

    ${({ shouldSpaceSiblings, gridArea }): SerializedStyles | undefined => {
        if (shouldSpaceSiblings && !gridArea) {
            return css`
                & + & {
                    margin-top: 24px;
                }
            `;
        }
    }}
`;

export const FieldLabel = styled.label<{ shouldOverflowFieldLabel: boolean }>`
    display: block;
    margin-bottom: ${theme.space[4]};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.lineHeights[18]};

    ${({ shouldOverflowFieldLabel }): SerializedStyles | undefined => {
        if (shouldOverflowFieldLabel) {
            return css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            `;
        }
    }}
`;

import type { FilterLocationByDomainId } from '@type/location/filterLocation';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { FilterOption } from '@type/search/filters/option';
import { getParsedCustomCategories } from '@widgets/search/core/helpers/getParsedCustomCategories';
import { parseFormValuesToApiObject } from '@widgets/search/SearchFormSubmitButton/helpers/parseFormValuesToApiObject';
import type { Filters } from '@widgets/search/SearchFormSubmitButton/types/searchTypes';

export const getFilters = ({
    fieldsMetadataExperimentsVariants,
    values,
    sellerId,
}: {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    values: Record<string, string | string[] | FilterOption | null | boolean>;
    sellerId?: string;
}): Filters | null => {
    const { locations: selectedLocations, placeId } = values;

    // Do not perform query for street search at this moment
    if (placeId) {
        return null;
    }

    const { locations, page, limit, ...filters } = parseFormValuesToApiObject(
        values,
        fieldsMetadataExperimentsVariants,
    );

    const selectedLocationsTreeNode = selectedLocations as unknown as { id: string }[];
    const filterLocations: FilterLocationByDomainId = {
        byDomainId: selectedLocationsTreeNode?.map(({ id }) => ({ domainId: id })),
    };

    return {
        filterAttributes: { ...getParsedCustomCategories(filters), sellerId },
        filterLocations,
    };
};

import Close from '@nexus/lib-react/dist/core/Icon/icons/default/Close';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX, MouseEventHandler } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { ChipWrapper, ClickableChip } from './LocationsInput.theme';

export const LocationChip = ({
    locationObject,
    onClick,
}: {
    locationObject: LocationsObjects;
    onClick: MouseEventHandler<HTMLDivElement>;
}): JSX.Element => {
    const { dataCy } = useLocationSearch();

    return (
        // on click on Chip does not stop propagation or pass event to do it manually and result in clickoutside mechanism of dropdown.
        <ChipWrapper onClick={onClick}>
            <ClickableChip
                data-cy={dataCy?.chip}
                content={locationObject.name}
                variant="indicative-outline"
                suffixIcon={Close}
            />
        </ChipWrapper>
    );
};

import { GOOGLE_SEARCH_TYPE } from '@type/search/google';

import type { LocationSearchConfig } from '../contexts/types';
import type { InitialSelectedProps, LocationSearchView } from '../types/locationSearchProps';
import { LOCATION_PICKER_VIEW } from '../types/locationSearchProps';

export const decideInitialLocationSearchView = (
    initial: InitialSelectedProps | undefined,
    config: LocationSearchConfig,
): LocationSearchView => {
    const shouldInitializeWithStreetSearch =
        !!config.streetSearchConfig.enabled && initial?.googleSuggestion?.type === GOOGLE_SEARCH_TYPE.street;
    if (shouldInitializeWithStreetSearch) {
        return LOCATION_PICKER_VIEW.street;
    }

    return config.defaultView;
};

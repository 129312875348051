import type { ExplicitDropdownOption } from '@type/search/dropdownOption';

import type { FieldAvailableMethods } from '../../../types';

/* eslint-disable @typescript-eslint/naming-convention -- 
The reason for disabling this rule is so that the object 
keys can match the format of extras checkbox values */
const TRACKING_EVENT_PARAMETERS = {
    AIR_CONDITIONING: { eventName: 'filter_with_ac_selected', parameterName: 'with_ac' },
    BALCONY: { eventName: 'filter_with_balcony_selected', parameterName: 'with_balcony' },
    BASEMENT: { eventName: 'filter_with_cellar_selected', parameterName: 'with_cellar' },
    CABLE_TELEVISION: { eventName: 'filter_cable_tv_selected', parameterName: 'cable_tv' },
    GARAGE: { eventName: 'filter_with_garage_selected', parameterName: 'with_garage' },
    GARDEN: { eventName: 'filter_with_garden_selected', parameterName: 'with_garden' },
    HAS_DISCOUNT: { eventName: 'filter_special_offer_selected', parameterName: 'only_special_offers' },
    HAS_MOVIE: { eventName: 'filter_with_movie_selected', parameterName: 'with_movie' },
    HAS_OPEN_DAY: { eventName: 'filter_open_day_selected', parameterName: 'only_open_day' },
    HAS_PHOTOS: { eventName: 'filter_with_photo_selected', parameterName: 'with_photo' },
    HAS_REMOTE_SERVICES: { eventName: 'filter_enum_remote_services_selected', parameterName: 'remote_services' },
    HAS_WALKAROUND: { eventName: 'filter_3d_view_selected', parameterName: 'with_3d_view' },
    INTERNET: { eventName: 'filter_internet_selected', parameterName: 'internet' },
    IS_BUNGALOV: { eventName: 'filter_bungalow_selected', parameterName: 'bungalow' },
    IS_EXCLUSIVE_OFFER: { eventName: 'filter_exclusive_selected', parameterName: 'exclusive' },
    IS_FOR_STUDENTS: { eventName: 'filter_for_students_selected', parameterName: 'rent_to_students' },
    IS_PET_FRIENDLY: { eventName: 'filter_pet_friendly_selected', parameterName: 'pet_friendly' },
    IS_PRIVATE_OWNER: { eventName: 'filter_private_owner_selected', parameterName: 'only_private' },
    LIFT: { eventName: 'filter_with_lift_selected', parameterName: 'with_lift' },
    NON_SMOKERS_ONLY: { eventName: 'filter_non_smoker_selected', parameterName: 'non_smoker' },
    SEPARATE_KITCHEN: { eventName: 'filter_with_separate_kitchen_selected', parameterName: 'with_separate_kitchen' },
    TERRACE: { eventName: 'filter_with_terrace_selected', parameterName: 'with_terrace' },
    TWO_STOREY: { eventName: 'filter_with_maisonette_selected', parameterName: 'with_maisonette' },
    USABLE_ROOM: { eventName: 'filter_with_storage_room_selected', parameterName: 'storage_room' },
};
/* eslint-enable @typescript-eslint/naming-convention */

type ExtrasType = keyof typeof TRACKING_EVENT_PARAMETERS;

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (option: ExplicitDropdownOption<boolean> | null): void => {
        if (!option) {
            return;
        }

        const { label, value } = option;
        const eventParameters = TRACKING_EVENT_PARAMETERS[label as ExtrasType];

        if (eventParameters) {
            const { eventName, parameterName } = eventParameters;
            trackEvent(eventName, {
                [parameterName]: value,
                touch_point_button: 'filters',
            });
        }
    };

export const EXTRAS_FIELD_PROPS = {
    trackOnSelect,
};

import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnFocus =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_ad_id_click', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: string): void => {
        trackEvent('filter_ad_id_selected', {
            ad_id_selected: value,
            ...SHARED_EVENT_PROPS,
        });
    };

export const ADVERT_ID_FIELD_PROPS = {
    trackOnFocus,
    trackOnBlur,
};

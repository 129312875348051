import type { InvestmentEstateType } from '@type/search/filters/investmentEstateType';
import type { FilterOption } from '@type/search/filters/option';
import type { FieldAvailableMethods } from '@widgets/search/FieldsFactory/types';

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('investment_status_click', { touch_point_button: 'filters' });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (investmentState: FilterOption<InvestmentEstateType> | null): void => {
        trackEvent('investment_status_selected', {
            touch_point_button: 'filters',
            investment_status: investmentState?.value ?? null,
        });
    };

export const INVESTMENT_STATE_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { SEARCH_WIDGET_THEME } from '@widgets/search/theme';
import type { FC } from 'react';

export const IconWrapper = styled.div`
    font-size: 16px;
`;

const LocationIcon = styled(Icon)`
    width: 12px;
    height: 16px;
    margin-right: 12px;
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.icon.color};
`;

export const StyledLocationIcon: FC = () => <LocationIcon height={16} width={12} icon={faMapMarkerAlt} />;

import type { JSX } from 'react';

import { LocationSearchContextProvider } from './contexts/LocationSearchContext';
import { LocationSearchBase } from './LocationSearchBase';
import type { LocationSearchProps } from './types/locationSearchProps';

export const LocationSearch = (props: LocationSearchProps): JSX.Element => {
    const { className, ...restProps } = props;
    // when initial change we would like to re-render whole picker and re-set values in states to match new initials. Alternative to multiple useEffects per hook
    const key = JSON.stringify(props.initial);

    return (
        <LocationSearchContextProvider key={key} {...restProps}>
            <LocationSearchBase className={className} />
        </LocationSearchContextProvider>
    );
};

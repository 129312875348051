import { SEARCH_CONFIG } from '@config/search/searchConfig';
import type { LocationsObjects } from '@type/search/location/dataItems';

export const checkShouldDisplayCityView = (suggestion: LocationsObjects): boolean => {
    const { locationLevelLikeDistrictAndSubdistrict, locationLevelLikeCity } = SEARCH_CONFIG;

    const isCityWithChildren =
        locationLevelLikeCity.includes(suggestion?.detailedLevel) &&
        !!suggestion?.children &&
        locationLevelLikeDistrictAndSubdistrict.includes(suggestion?.children[0].detailedLevel);

    const isDistrictOrSubdistrict = locationLevelLikeDistrictAndSubdistrict.includes(suggestion?.detailedLevel);

    return isDistrictOrSubdistrict || isCityWithChildren;
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import { type JSX, useCallback } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { getLocationCityTreeId } from '../../helpers/getLocationCityTreeId';
import { useCityViewLocations } from '../../hooks/useCityViewLocations';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import { useLocationDetailsQuery } from '../../hooks/useLocationDetailsQuery';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { LocationsInput } from '../LocationsInput';
import { MobileConfirm } from '../MobileConfirm';
import { MobileContainer } from '../MobileContainer';
import { CityWithChildrenList } from './CityWithChildrenList';

export const MobileCity = ({ setDropdownState }: FlyoutProps<unknown>): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const {
        setSearchValue,
        changeLocationSearchView,
        dataCy,
        locations: {
            selectLocations,
            temporarySuggestions,
            clearTemporarySuggestion,
            selectTemporarySuggestions,
            selectedLocations,
        },
        config: {
            tracking: { touchPointPage },
            defaultView,
        },
    } = useLocationSearch();

    const id = getLocationCityTreeId(temporarySuggestions);

    const { data, fetching } = useLocationDetailsQuery(id);

    const { checkIsSelected, selectLocation, unselectLocation } = useCityViewLocations({
        cityTree: data?.locationDetails,
        shouldUseTemporarySuggestions: true,
    });

    const { getTrackingSharedValue } = useGetTrackingSharedValues();

    const onMobileLocationClick: SelectableLocations['onLocationClick'] = (
        suggestion,
        { touchPointButton, locationParentId, locationListPosition },
    ): void => {
        const isSelected = checkIsSelected(suggestion.id);
        const newTemporarySuggestions = isSelected
            ? unselectLocation(suggestion.id, true)
            : selectLocation(suggestion.id);
        const eventName = isSelected ? 'additional_location_removed' : 'additional_location_included';
        trackEvent(
            eventName,
            getTrackingSharedValue({
                eventType: 'click',
                touchPointButton,
                locationParentId,
                locationListPosition,
                locations: newTemporarySuggestions,
                additionalLocationId: suggestion.id,
            }),
        );
    };

    const touchPointButton = 'multi_autosuggest_locations';

    const onBackClick = (): void => {
        setDropdownState?.(false);
        selectTemporarySuggestions(selectedLocations);
        changeLocationSearchView(defaultView);
        trackEvent('back_button_click', {
            event_type: 'click',
            touch_point_button: touchPointButton,
            touch_point_page: touchPointPage,
        });
    };

    const onClearClick = (): void => {
        clearTemporarySuggestion();
        setSearchValue('');
        trackEvent('clear_filters', {
            event_type: 'click',
            touch_point_button: touchPointButton,
            touch_point_page: touchPointPage,
        });
    };

    const onConfirmClick = (): void => {
        selectLocations(temporarySuggestions);
        setDropdownState?.(false);

        trackEvent(
            'apply_location',
            getTrackingSharedValue({
                eventType: 'click',
                touchPointButton: touchPointButton,
                locations: temporarySuggestions,
            }),
        );
    };

    const onUnselectLocationChip = useCallback(
        (locationObjectId: string) => {
            const newTemporarySuggestions = unselectLocation(locationObjectId);
            trackEvent(
                'additional_location_removed',
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton: 'filters',
                    locations: newTemporarySuggestions,
                    additionalLocationId: locationObjectId,
                }),
            );
        },
        [getTrackingSharedValue, trackEvent, unselectLocation],
    );

    return (
        <MobileContainer
            title={t('frontend.search.location-search.mobile-title')}
            onBackClick={onBackClick}
            onClearClick={onClearClick}
        >
            <LocationsInput selectedLocations={temporarySuggestions} onUnselectLocation={onUnselectLocationChip} />
            {fetching ? <LoadingSkeleton /> : null}
            {data ? (
                <CityWithChildrenList
                    onLocationClick={onMobileLocationClick}
                    selectedLocations={temporarySuggestions}
                    cityTree={data.locationDetails}
                    cityTreeListDataCy={dataCy?.cityTreeList}
                />
            ) : null}
            <MobileConfirm text={t('frontend.search.city-view.confirm-button')} onClick={onConfirmClick} />
        </MobileContainer>
    );
};

import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ChangeEventHandler, FocusEventHandler, MutableRefObject } from 'react';
import { useEffect } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    name: string;
    dataCy: string;
    maxFieldRef: MutableRefObject<HTMLInputElement | null>;
    minFieldRef: MutableRefObject<HTMLInputElement | null>;
    shouldAddSeparator?: boolean;
    trackOnBlur?: (min: number, max: number, trackedInput: 'min' | 'max') => void;
}

interface InputProps {
    id: string;
    placeholder: string;
    'aria-label': string;
    'data-cy': string;
}

export const useRangeField = ({
    name,
    dataCy,
    minFieldRef,
    maxFieldRef,
    shouldAddSeparator = false,
    trackOnBlur,
}: Props): {
    inputProps: {
        min: InputProps;
        max: InputProps;
    };
    registerField: {
        min: UseFormRegisterReturn;
        max: UseFormRegisterReturn;
    };
    createInputBlurHandler: (type: 'min' | 'max') => FocusEventHandler<HTMLInputElement>;
    createInputChangeHandler: (type: 'min' | 'max') => ChangeEventHandler<HTMLInputElement>;
} => {
    const [t] = useTranslations();
    const { register, setValue, control } = useFormContext();
    const { lang } = useSiteSettings();

    const minInputName = `${name}Min`;
    const maxInputName = `${name}Max`;

    const minValue = useWatch({ control, name: minInputName });
    const maxValue = useWatch({ control, name: maxInputName });

    const registerField = {
        min: register(minInputName),
        max: register(maxInputName),
    };

    useEffect(() => {
        if (shouldAddSeparator) {
            updateFieldValueWithSeparator({ fieldRef: minFieldRef, lang });
        }
    }, [minFieldRef, shouldAddSeparator, lang]);

    useEffect(() => {
        if (shouldAddSeparator) {
            updateFieldValueWithSeparator({ fieldRef: maxFieldRef, lang });
        }
    }, [maxFieldRef, shouldAddSeparator, lang]);

    const createInputProps = (inputName: string): InputProps => ({
        id: inputName,
        placeholder: t(GET_NON_GENERIC_TRANSLATION_KEY.placeholder(inputName)),
        'aria-label': t(GET_NON_GENERIC_TRANSLATION_KEY.label(inputName)),
        'data-cy': `${dataCy}${inputName === minInputName ? 'Min' : 'Max'}`,
    });

    const inputProps = {
        min: createInputProps(minInputName),
        max: createInputProps(maxInputName),
    };

    const createInputBlurHandler =
        (type: 'min' | 'max'): FocusEventHandler<HTMLInputElement> =>
        (event) => {
            if (minValue && maxValue && Number(minValue) > Number(maxValue)) {
                setValue(minInputName, maxValue);
                setValue(maxInputName, minValue);

                if (shouldAddSeparator) {
                    updateFieldValueWithSeparator({ fieldRef: minFieldRef, lang });
                    updateFieldValueWithSeparator({ fieldRef: maxFieldRef, lang });
                }
            }

            trackOnBlur?.(minValue, maxValue, type);

            if (shouldAddSeparator) {
                const { currentTarget, target } = event;
                const modifiedTarget = {
                    name: currentTarget.name,
                    value: removeNonNumeric(target.value),
                };

                registerField[type].onBlur({
                    ...event,
                    target: modifiedTarget,
                });

                target.value = addSeparator({ value: target.value, lang });
            } else {
                registerField[type].onBlur(event);
            }
        };

    const createInputChangeHandler =
        (type: 'min' | 'max'): ChangeEventHandler<HTMLInputElement> =>
        (event) => {
            const { currentTarget, target } = event;

            if (shouldAddSeparator) {
                const modifiedTarget = {
                    name: currentTarget.name,
                    value: removeNonNumeric(target.value),
                };

                registerField[type].onChange({
                    ...event,
                    target: modifiedTarget,
                });

                target.value = addSeparator({ value: target.value, lang });
            } else {
                target.value = removeNonNumeric(target.value);
                registerField[type].onChange(event);
            }
        };

    return {
        inputProps,
        registerField,
        createInputBlurHandler,
        createInputChangeHandler,
    };
};

const addSeparator = ({ value, lang }: { value: string; lang: string }): string => {
    const numericValue = Number.parseFloat(removeNonNumeric(value));

    if (Number.isNaN(numericValue)) {
        return removeNonNumeric(value);
    }

    return new Intl.NumberFormat(lang).format(numericValue);
};

const removeNonNumeric = (value: string): string => {
    return value.replace(/\D/g, '');
};

const updateFieldValueWithSeparator = ({
    fieldRef,
    lang,
}: {
    fieldRef: MutableRefObject<HTMLInputElement | null>;
    lang: string;
}): void => {
    if (fieldRef.current?.value) {
        fieldRef.current.value = addSeparator({ value: fieldRef.current.value, lang });
    }
};

import { getNumberValue } from '../../../../core/helpers/getNumberValue';
import type { FieldAvailableMethods } from '../../../types';

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (fromSurface: string | null, toSurface: string | null, trackedInput: 'min' | 'max'): void => {
        const touchPointButton = trackedInput === 'min' ? 'from_surface' : 'to_surface';

        trackEvent('surface_selected', {
            from_surface: getNumberValue(fromSurface),
            to_surface: getNumberValue(toSurface),
            touch_point_button: touchPointButton,
        });
    };

export const AREA_FIELD_PROPS = {
    trackOnBlur,
};

import type { GqlDomainIdNode } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/locations';

import type { LocationListNode } from '../types/list';

export const createLocationListNodesFromGqlDomainIdNodes = (
    nodes: GqlDomainIdNode[],
    parentId: string | null,
): LocationListNode[] =>
    nodes.map((node) => ({
        ...node,
        parentId,
        sublocations: createLocationListNodesFromGqlDomainIdNodes(node.sublocations ?? [], node.id),
    }));

import type { FieldMetadata } from '@type/search/fields';
import { FIELD_TYPE } from '@type/search/fieldType';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';

import { FIELD_NAMES, FIELD_TYPES } from '../const/fieldInfo';

export const checkShouldShowLabel = ({
    fieldName,
    metadata,
    isDesktop,
    isExtendedForm,
    shouldSetMobileLabels,
    shouldRenderLabels,
}: {
    fieldName: SearchFormUniversalFieldName;
    metadata: FieldMetadata;
    isDesktop: boolean | null;
    isExtendedForm: boolean;
    shouldSetMobileLabels: boolean;
    shouldRenderLabels?: boolean;
}): boolean => {
    let shouldShowLabel = false;

    if (FIELD_TYPES.withLegend.has(metadata.fieldType)) {
        return false;
    }

    if (shouldRenderLabels) {
        return true;
    }

    if (!isDesktop) {
        shouldShowLabel = shouldSetMobileLabels && !FIELD_NAMES.withoutLabelsOnMobile.has(fieldName);
    }

    if (isExtendedForm) {
        shouldShowLabel = metadata.fieldType !== FIELD_TYPE.checkbox;
    }

    return shouldShowLabel;
};

import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useQuery } from 'urql';

import { type Data, LOCATION_DETAILS_QUERY } from '../queries/locationDetailsQuery';

export const useLocationDetailsQuery = (
    id: string,
): {
    data: Data | undefined;
    fetching: boolean;
} => {
    const { locationLevelLikeDistrictAndSubdistrict } = SEARCH_CONFIG;

    const [{ data, error, operation, fetching }] = useQuery({
        query: LOCATION_DETAILS_QUERY,
        variables: {
            id,
            locationLevelLikeDistrictAndSubdistrict,
        },
        pause: !id,
    });

    useHandleGraphqlError({ descriptor: '[LocationSearch - CityTree][locationDetailsQuery]', error, operation });

    return {
        data,
        fetching,
    };
};

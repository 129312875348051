import { logError } from '@domains/shared/helpers/logger';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { LocationLevels } from '@type/location/locationLevels';
import { LocationIcon } from '@widgets/search/LocationIcon';
import { LOCATION_PICKER_INPUT_ID } from '@widgets/search/LocationPicker/components/LocationPickerTree/constants/elementVariables';
import type { JSX } from 'react';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { Context as UrqlContext } from 'urql';

import {
    type InputOnSuggestionsLoad,
    RANKING_TYPE_INPUT,
} from '../LocationPicker/components/LocationPickerTree/types/suggestions';
import { runQueryAndSetSuggestions } from './helpers/runQueryAndSetSuggestions';
import { Container, LocationInput, NexusContainer, NexusLocationInput, SpinnerIcon } from './LocationPickerInput.theme';

interface Props {
    onSuggestionsLoad: InputOnSuggestionsLoad;
    minLength: number;
    shouldUseNexusTheme?: boolean;
    initialSearchValue?: string;
    levels?: LocationLevels;
    shouldUseFullScreenVersionOnMobile?: boolean;
    disallowedCharactersRegex?: RegExp;
    onFocus?: () => void;
}

const LocationPickerInputBase = ({
    onSuggestionsLoad,
    minLength,
    shouldUseNexusTheme,
    initialSearchValue,
    levels,
    shouldUseFullScreenVersionOnMobile = true,
    disallowedCharactersRegex,
    onFocus,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const urqlClient = useContext(UrqlContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState(initialSearchValue ?? '');
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onInputChange = useCallback(() => {
        if (!inputRef.current) {
            return;
        }

        let currentValue = inputRef.current.value;

        if (disallowedCharactersRegex) {
            currentValue = currentValue.replace(disallowedCharactersRegex, '');
        }

        setSearchValue(currentValue);
    }, [disallowedCharactersRegex]);

    useDebounce(
        async () => {
            const isAutocompleteTriggered = searchValue.length >= minLength;

            if (!isAutocompleteTriggered) {
                onSuggestionsLoad({ searchValue, suggestions: [] });

                return;
            }

            setIsFetching(true);

            const { error, data } = await runQueryAndSetSuggestions({
                urqlClient,
                searchValue: searchValue.trim(),
                levels,
                rankingType: RANKING_TYPE_INPUT.default,
            });

            if (error) {
                setError(error.message);
            }

            if (data) {
                const suggestions = data?.autocomplete.locationsObjects;

                onSuggestionsLoad({ searchValue, suggestions });
            }
            setIsFetching(false);
        },
        250,
        [searchValue, setIsFetching],
    );

    if (error) {
        logError(error);
    }

    if (shouldUseNexusTheme) {
        return (
            <NexusContainer>
                <SearchIcon />
                <NexusLocationInput
                    autoComplete="off"
                    autoFocus
                    onChange={onInputChange}
                    onFocus={onFocus}
                    ref={inputRef}
                    type="text"
                    aria-label={t('frontend.search.form.location-input-label')}
                    placeholder={t('frontend.search.form.location-input-label')}
                    data-cy="location-picker-input"
                    id={LOCATION_PICKER_INPUT_ID}
                    value={searchValue}
                />
                {searchValue.length > 0 && isFetching ? <SpinnerIcon /> : null}
            </NexusContainer>
        );
    }

    return (
        <Container>
            <LocationIcon />
            <LocationInput
                autoComplete="off"
                autoFocus
                onChange={onInputChange}
                onFocus={onFocus}
                ref={inputRef}
                type="text"
                aria-label={t('frontend.search.form.location-input-label')}
                placeholder={t('frontend.search.form.location-input-label')}
                data-cy="location-picker-input"
                id={LOCATION_PICKER_INPUT_ID}
                value={searchValue}
                shouldUseFullScreenVersionOnMobile={shouldUseFullScreenVersionOnMobile}
            />
            {searchValue.length > 0 && isFetching ? <SpinnerIcon /> : null}
        </Container>
    );
};

export const LocationPickerInput = memo(LocationPickerInputBase);

import type { LocationsObjects } from '@type/search/location/dataItems';

export const checkIsAnyChildrenSelected = (
    location: LocationsObjects,
    selectedLocations: LocationsObjects[],
): boolean => {
    const children = location.children;
    if (children) {
        const isChildrenSelected = children.some(({ id: childrenId }) =>
            selectedLocations.some(({ id: selectedId }) => childrenId === selectedId),
        );
        if (isChildrenSelected) {
            return true;
        }

        return children.some((childrenLocation) => checkIsAnyChildrenSelected(childrenLocation, selectedLocations));
    }

    return false;
};

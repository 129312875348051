import type { Client, OperationResult } from 'urql';

import { LOCATION_TREE_QUERY } from '../../../graphql/queries/LocationTreeQuery';
import type { TreeResponseStructure } from '../../../types/locations';

export const runSubLocationsQuery = (
    urqlClient: Client,
    rootItem: { id: string },
): Promise<OperationResult<TreeResponseStructure>> => {
    return urqlClient
        .query<TreeResponseStructure>(LOCATION_TREE_QUERY, {
            locationId: rootItem.id,
        })
        .toPromise();
};

import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { getLocationCityTreeId } from '../../helpers/getLocationCityTreeId';
import { useCityViewLocations } from '../../hooks/useCityViewLocations';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import { useLocationDetailsQuery } from '../../hooks/useLocationDetailsQuery';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { DesktopContainer } from '../DesktopContainer';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { LocationsInput } from '../LocationsInput';
import { CityWithChildrenList } from './CityWithChildrenList';

export const DesktopCity = (props: FlyoutProps<unknown>): JSX.Element => {
    const { trackEvent } = useTracking();
    const {
        locations: { selectedLocations },
        dataCy,
    } = useLocationSearch();

    const id = getLocationCityTreeId(selectedLocations);

    const { data, fetching } = useLocationDetailsQuery(id);

    const { checkIsSelected, selectLocation, unselectLocation } = useCityViewLocations({
        cityTree: data?.locationDetails,
        shouldUseTemporarySuggestions: false,
    });

    const { getTrackingSharedValue } = useGetTrackingSharedValues();

    const onDesktopLocationClick: SelectableLocations['onLocationClick'] = useCallback(
        (suggestion, { touchPointButton, locationParentId, locationListPosition }): void => {
            const isSelected = checkIsSelected(suggestion.id);
            const newSuggestions = isSelected ? unselectLocation(suggestion.id, true) : selectLocation(suggestion.id);
            const eventName = isSelected ? 'additional_location_removed' : 'additional_location_included';
            trackEvent(
                eventName,
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton,
                    locationParentId,
                    locationListPosition,
                    locations: newSuggestions,
                    additionalLocationId: suggestion.id,
                }),
            );
        },
        [checkIsSelected, getTrackingSharedValue, selectLocation, trackEvent, unselectLocation],
    );

    const onUnselectLocation = useCallback(
        (locationObjectId: string) => {
            const newSuggestions = unselectLocation(locationObjectId);
            trackEvent(
                'additional_location_removed',
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton: 'filters',
                    locations: newSuggestions,
                    additionalLocationId: locationObjectId,
                }),
            );
        },
        [getTrackingSharedValue, trackEvent, unselectLocation],
    );

    return (
        <DesktopContainer {...props}>
            <LocationsInput selectedLocations={selectedLocations} onUnselectLocation={onUnselectLocation} />
            {fetching ? <LoadingSkeleton /> : null}
            {data ? (
                <CityWithChildrenList
                    onLocationClick={onDesktopLocationClick}
                    selectedLocations={selectedLocations}
                    cityTree={data.locationDetails}
                    cityTreeListDataCy={dataCy?.cityTreeList}
                />
            ) : null}
        </DesktopContainer>
    );
};

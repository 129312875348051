import { useEffect, useRef } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import type { FormFields } from '../types/form';
import type { UseSyncValues } from '../types/syncValues';

export const useFormSync: UseSyncValues = ({
    selected: { locations, googleSuggestion, geometry, distanceRadius },
    onClear,
}) => {
    const isFirstRender = useRef(true);
    const { setValue, getValues } = useFormContext<FormFields>();
    const { isDirty, isSubmitted } = useFormState();

    useEffect(() => {
        const distanceRadiusValue = distanceRadius?.toString() ?? null;

        if (googleSuggestion) {
            setValue('locations', null);
            setValue('placeId', googleSuggestion.placeId, { shouldDirty: true });
            setValue('geometry', null);
            setValue('distanceRadius', distanceRadiusValue);

            return;
        }

        if (geometry) {
            setValue('locations', null);
            setValue('placeId', null);
            setValue('geometry', geometry, { shouldDirty: true });
            setValue('distanceRadius', null);

            return;
        }

        if (locations.length > 0) {
            setValue(
                'locations',
                locations.map((location) => ({ id: location.id, detailedLevel: location.detailedLevel })),
                { shouldDirty: true },
            );
            setValue('placeId', null);
            setValue('geometry', null);
            setValue('distanceRadius', distanceRadiusValue);

            return;
        }

        setValue('locations', null);
        setValue('placeId', null);
        setValue('geometry', null);
        setValue('distanceRadius', distanceRadiusValue);
    }, [distanceRadius, geometry, googleSuggestion, locations, setValue]);

    // reset location fields when form reset
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;

            return;
        }
        const values = getValues();
        const isDistanceRadiusClear = !values.distanceRadius || Number(values.distanceRadius) === 0; // backward compatibility, to change to only '!values.distanceRadius' after remove old search
        const isAnythingSelected = !!googleSuggestion || !!geometry || locations.length > 0 || !!distanceRadius; // no need to clear if nothing is selected
        const isValuesClear = !values.placeId && !values.geometry && !values.locations && isDistanceRadiusClear; // make sure reset action cleared form values
        if (!isDirty && !isSubmitted && isAnythingSelected && isValuesClear) {
            onClear();
        }
    }, [isDirty, isSubmitted, onClear, setValue, googleSuggestion, geometry, locations, getValues, distanceRadius]);
};

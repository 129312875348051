import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[16]};
    width: 100%;
    min-height: 100%;
`;

export const Header = styled.div`
    display: grid;
    position: sticky;
    z-index: ${theme.zIndices.sticky};
    top: calc(0px + env(safe-area-inset-top, 0px));
    grid-template-areas: 'back title clear';
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: start;
    width: 100%;
    min-height: 56px;
    overflow: hidden;
    border-bottom: ${theme.borderWidths['1']} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    background: ${theme.colors['background-global-primary']};
`;

export const Back = styled(Button)`
    grid-area: back;
    height: auto;
    padding: ${theme.space[16]};
`;

export const Title = styled(H4)`
    grid-area: title;
    margin: 0 0 -4px;
`;

export const ClearButton = styled(Button)`
    grid-area: clear;
    margin-left: auto;
    padding-right: ${theme.space[16]};
    font-size: ${theme.fontSizes[12]};
    text-transform: uppercase;
`;

import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Badge } from '@nexus/lib-react/dist/core/Badge';
import { CheckboxInput, CheckboxLabel } from '@nexus/lib-react/dist/core/CheckboxInput';

interface IsExpandedProps {
    isExpanded: boolean;
}

const getDropdownValueWrapperBorder = ({ isExpanded }: IsExpandedProps): SerializedStyles => {
    if (isExpanded) {
        return css`
            border-bottom: 2px solid ${theme.colors['background-brand-primary']};
            border-radius: ${theme.radii[4]} ${theme.radii[4]} 0 0;
        `;
    }

    return css`
        border-bottom: 2px solid transparent;
        border-radius: ${theme.radii[4]};
    `;
};

export const Wrapper = styled.div`
    position: relative;
`;

export const DropdownValueWrapper = styled.div<IsExpandedProps>`
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    min-height: ${theme.space[48]};
    padding: ${theme.space[8]} ${theme.space[48]} ${theme.space[8]} ${theme.space[16]};
    ${({ isExpanded }): SerializedStyles => getDropdownValueWrapperBorder({ isExpanded })}
    background-color: ${theme.colors['background-global-secondary']};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[16]};
    cursor: pointer;
`;

export const Placeholder = styled.span`
    margin-right: ${theme.space[8]};
    overflow: hidden;
    color: ${theme.colors['text-global-secondary']};
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledBadge = styled(Badge)`
    margin-left: auto;
`;

export const ValueString = styled.div`
    margin-right: ${theme.space[8]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IconWrapper = styled.div<IsExpandedProps>`
    display: flex;
    position: absolute;
    top: 0;
    right: ${theme.space[16]};
    align-items: center;
    height: ${theme.space[48]};
    transform: ${({ isExpanded }): string => (isExpanded ? 'rotate(180deg)' : 'none')};

    svg {
        display: block;
    }
`;

export const OptionsListWrapper = styled.div`
    position: absolute;
    z-index: ${theme.zIndices.dropdown};
    right: 0;
    left: 0;
    background-color: ${theme.colors['background-global-secondary']};

    &::before {
        content: '';
        position: absolute;
        border-radius: ${theme.radii[4]};
        box-shadow: 0 0 ${theme.sizes[6]} 0 rgb(0 0 0 / 30%);
        inset: -${theme.sizes[48]} 0 0 0;
        pointer-events: none;
    }
`;

export const OptionsList = styled.ul`
    max-height: 400px;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style-type: none;
    border-radius: 0 0 ${theme.radii[4]} ${theme.radii[4]};
`;

export const StyledCheckboxLabel = styled(CheckboxLabel)`
    display: flex;
    position: unset;
    align-items: center;

    &::after {
        content: '';
        position: absolute;
        opacity: 0;
        inset: 0;
    }
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
    background-color: ${theme.colors['background-global-secondary']};
`;

export const OptionListItem = styled.li`
    display: grid;
    position: relative;
    grid-template-columns: min-content 1fr;
    padding: ${theme.space[8]} ${theme.space[16]};

    &:hover {
        background-color: ${theme.colors['background-global-primary']};
    }
`;

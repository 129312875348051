import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { useGoogleSearch } from '../../hooks/useGoogleSearch';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { ContentContainer } from '../Shared.theme';
import { InitialView } from './InitialView';
import type { StreetListProps } from './StreetList';
import { StreetList } from './StreetList';

const MIN_LENGTH = 2;

export const StreetViewContent = ({ setDropdownState }: FlyoutProps<unknown>): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const {
        searchValue,
        setSearchValue,
        googleSuggestion: { selectGoogleSuggestion, selectedGoogleSuggestion },
        locations: { unselectAllLocations },
        config: {
            tracking: { touchPointPage },
            streetSearchConfig: { googleSuggestionTypes },
        },
    } = useLocationSearch();

    const { isFetching, isSuccess, suggestions } = useGoogleSearch({
        searchValue,
        minLength: MIN_LENGTH,
        googleSuggestionTypes,
    });

    const onSuggestionClick: StreetListProps['onSuggestionClick'] = (suggestion, { locationListPosition }): void => {
        selectGoogleSuggestion(suggestion);
        unselectAllLocations();
        setSearchValue('');
        setDropdownState?.(false);
        trackEvent('search_box_list_selection_click', {
            event_type: 'click',
            touch_point_button: 'autosuggest_address',
            touch_point_page: touchPointPage,
            selected_locations_id: [suggestion.placeId],
            location_list_position: locationListPosition,
            location_query: searchValue,
            selected_locations_display_name: [suggestion.name],
            selected_locations_scope: ['street'],
        });
    };

    const shouldDisplayInitialView = !selectedGoogleSuggestion && searchValue.length < MIN_LENGTH;

    if (shouldDisplayInitialView) {
        return (
            <ContentContainer>
                <InitialView />
            </ContentContainer>
        );
    }

    const shouldDisplaySelectedView = selectedGoogleSuggestion && !searchValue;

    const captionPrefix = t('frontend.search.form.location-street-suggestions-caption-prefix');

    if (shouldDisplaySelectedView) {
        return (
            <ContentContainer>
                <StreetList
                    suggestions={[selectedGoogleSuggestion]}
                    selectedSuggestion={selectedGoogleSuggestion}
                    onSuggestionClick={onSuggestionClick}
                    captionPrefix={captionPrefix}
                />
            </ContentContainer>
        );
    }

    return (
        <ContentContainer>
            {isFetching ? <LoadingSkeleton /> : null}
            {isSuccess ? (
                <StreetList
                    suggestions={suggestions}
                    selectedSuggestion={selectedGoogleSuggestion}
                    onSuggestionClick={onSuggestionClick}
                    captionPrefix={captionPrefix}
                />
            ) : null}
        </ContentContainer>
    );
};

import { gql } from '@urql/core';

interface GooglePlacePrediction {
    placeID: string;
    description: string;
}
interface GoogleSuggestionsResult {
    __typename: 'FoundGooglePlacesPredictions';
    googlePlacePredictions: GooglePlacePrediction[];
}
interface GetGoogleSuggestionsResult {
    googleAutocomplete: GoogleSuggestionsResult;
}
interface GetGoogleSuggestionsInput {
    searchValue: string;
    types: string[];
    sessionToken?: string;
}

export const GET_GOOGLE_SUGGESTIONS = gql<GetGoogleSuggestionsResult, GetGoogleSuggestionsInput>`
    query GetGoogleAutocomplete($searchValue: String!, $types: [String!], $sessionToken: String) {
        googleAutocomplete(input: { query: $searchValue, types: $types, sessionToken: $sessionToken }) {
            __typename
            ... on FoundGooglePlacesPredictions {
                googlePlacePredictions {
                    placeID
                    description
                }
            }
            ... on ErrorBadRequest {
                code
                message
            }
            ... on ErrorInternal {
                code
                message
            }
        }
    }
`;

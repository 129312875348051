import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';
import { useCallback, useContext } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { DesktopContainer } from '../DesktopContainer';
import { GoogleInput } from '../GoogleInput';
import { MobileContainer } from '../MobileContainer';
import { StreetViewContent } from './StreetViewContent';

export const StreetView = (props: FlyoutProps<unknown>): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);

    const {
        changeLocationSearchView,
        setSearchValue,
        resetState,
        googleSuggestion: { unselectGoogleSuggestion },
        config: {
            tracking: { touchPointPage },
            defaultView,
        },
    } = useLocationSearch();

    const onBackClick = useCallback(() => {
        setSearchValue('');
        unselectGoogleSuggestion();
        changeLocationSearchView(defaultView);
        trackEvent('back_button_click', {
            event_type: 'click',
            touch_point_button: 'autosuggest_address',
            touch_point_page: touchPointPage,
        });
    }, [changeLocationSearchView, defaultView, setSearchValue, touchPointPage, trackEvent, unselectGoogleSuggestion]);

    const onClear = useCallback(() => {
        unselectGoogleSuggestion();
        setSearchValue('');
        trackEvent('clear_filters', {
            event_type: 'click',
            touch_point_button: 'autosuggest_address',
            touch_point_page: touchPointPage,
        });
    }, [setSearchValue, touchPointPage, trackEvent, unselectGoogleSuggestion]);

    if (isDesktop) {
        return (
            <DesktopContainer {...props}>
                <GoogleInput placeholder={t('frontend.search.street-view.input-label')} onClear={resetState} />
                <StreetViewContent {...props} />
            </DesktopContainer>
        );
    }

    // Mobile view
    return (
        <MobileContainer
            title={t('frontend.search.location-search.mobile-title')}
            onBackClick={onBackClick}
            onClearClick={onClear}
        >
            <GoogleInput placeholder={t('frontend.search.street-view.input-label')} onClear={onClear} />
            <StreetViewContent {...props} />
        </MobileContainer>
    );
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { ChangeEvent, JSX } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { LocationReferenceInputSuffix } from '../LocationReferenceInputSuffix';
import { StyledTextInput } from '../Shared.theme';

interface Props {
    selectedLocation?: LocationsObjects;
    onClear: () => void;
}

export const LocationInput = ({ selectedLocation, onClear }: Props): JSX.Element => {
    const [t] = useTranslations();
    const {
        searchValue,
        setSearchValue,
        locationSearchView,
        changeLocationSearchView,
        dataCy,
        config: {
            defaultView,
            referenceInputId,
            referenceInputPrefix,
            referenceInputPlaceholder = 'frontend.search.form.location-input-label',
        },
    } = useLocationSearch();
    const selectedLocationsText = selectedLocation?.name || '';
    const displayValue = searchValue || selectedLocationsText;

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
        if (value === '') {
            onClear();

            return;
        }

        if (locationSearchView !== defaultView) {
            changeLocationSearchView(defaultView);
        }

        setSearchValue(value);
    };

    return (
        <StyledTextInput
            id={referenceInputId}
            value={displayValue}
            placeholder={t(referenceInputPlaceholder)}
            prefix={referenceInputPrefix}
            suffix={<LocationReferenceInputSuffix onClear={onClear} counterLength={selectedLocation ? 1 : 0} />}
            autoFocus
            data-cy={dataCy?.locationInput}
            onChange={handleChange}
        />
    );
};

import { getNumberValue } from '../../../../core/helpers/getNumberValue';
import type { FieldAvailableMethods } from '../../../types';

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (fromFloors: string | null, toFloors: string | null, trackedInput: 'min' | 'max'): void => {
        const touchPointButton = trackedInput === 'min' ? 'from_nb_floors' : 'to_nb_floors';

        trackEvent('nb_floors_selected', {
            from_nb_floors: getNumberValue(fromFloors),
            to_nb_floors: getNumberValue(toFloors),
            touch_point_button: touchPointButton,
        });
    };

export const FLOORS_NUMBER_FIELD_PROPS = {
    trackOnBlur,
};

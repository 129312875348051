export const getNumberValue = (value: unknown): number | null => {
    if (!value) {
        return null;
    }

    const finalValue = Number(value);

    if (Number.isNaN(finalValue)) {
        return null;
    }

    return finalValue;
};

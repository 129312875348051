import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import ChevronUp from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronUp';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { getLocationDetailedLevelTranslation } from '@widgets/search/core/helpers/getLocationDetailedLevelTranslation';
import type { JSX } from 'react';
import { useMemo, useState } from 'react';

import { checkIsAnyChildrenSelected } from '../../helpers/checkIsAnyChildrenSelected';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import type { SelectableLocations } from '../../types/locationSearchProps';
import {
    ItemWrapper,
    StyledCheckboxInput,
    StyledListItem,
    StyledListItemSubtitle,
    StyledListItemTitle,
    StyledListItemWrapper,
} from '../Shared.theme';

interface Props {
    option: LocationsObjects;
    onLocationClick: SelectableLocations['onLocationClick'];
    isSelected: boolean;
    selectedLocations: LocationsObjects[];
    nestingIndex?: number;
    sourceRowIndex: number;
    sourceRowId?: string;
}

export const CityTreeRow = ({
    option,
    onLocationClick,
    isSelected,
    selectedLocations,
    nestingIndex = 0,
    sourceRowIndex,
    sourceRowId,
}: Props): JSX.Element | null => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { locationLevelLikeDistrict } = SEARCH_CONFIG;

    const locationParentId = nestingIndex === 0 ? undefined : sourceRowId;

    const caption = getLocationDetailedLevelTranslation(t, option.detailedLevel);

    const checkShouldBeOpen = (): boolean | undefined =>
        locationLevelLikeDistrict.includes(option.detailedLevel) &&
        option.children &&
        option.children.some((item) => selectedLocations.map((selected) => selected.id).includes(item.id));

    const checked = useMemo(() => {
        if (isSelected) {
            return true;
        }

        const isIndeterminate = checkIsAnyChildrenSelected(option, selectedLocations);
        if (isIndeterminate) {
            return 'indeterminate';
        }

        return false;
    }, [isSelected, option, selectedLocations]);

    const [isOpen, setIsOpen] = useState(checkShouldBeOpen);

    const { getTrackingSharedValue } = useGetTrackingSharedValues();

    const sharedTrackingData = getTrackingSharedValue({
        eventType: 'click',
        touchPointButton: 'multi_autosuggest_locations',
        locationParentId,
        locationListPosition: sourceRowIndex,
        locations: selectedLocations,
        additionalLocationId: option.id,
    });

    const onCloseClick = (): void => {
        setIsOpen(false);
        trackEvent('location_collapse_click', sharedTrackingData);
    };

    const onOpenClick = (): void => {
        setIsOpen(true);
        trackEvent('location_expand_click', sharedTrackingData);
    };

    return (
        <>
            <StyledListItemWrapper nestingIndex={nestingIndex}>
                <ItemWrapper
                    onClick={(): void =>
                        onLocationClick(option, {
                            touchPointButton: 'multi_autosuggest_locations',
                            locationListPosition: sourceRowIndex,
                            locationParentId: locationParentId,
                        })
                    }
                >
                    <StyledCheckboxInput checked={checked} />
                    <StyledListItem aria-selected={isSelected}>
                        <StyledListItemTitle>{option.name}</StyledListItemTitle>
                        <StyledListItemSubtitle>{caption}</StyledListItemSubtitle>
                    </StyledListItem>
                </ItemWrapper>
                {option.children && !isOpen ? (
                    <Button variant="flat" aria-label={t('frontend.global.label.open')} onClick={onOpenClick}>
                        <ChevronDown />
                    </Button>
                ) : null}

                {option.children && isOpen ? (
                    <Button variant="flat" aria-label={t('frontend.global.label.close')} onClick={onCloseClick}>
                        <ChevronUp />
                    </Button>
                ) : null}
            </StyledListItemWrapper>

            {option.children && isOpen
                ? option.children.map((item) => {
                      // parent selected or row directly
                      const isOptionSelected = selectedLocations.some(({ id }) => id === item.id || id === option.id);

                      return (
                          <CityTreeRow
                              key={item.id}
                              onLocationClick={(o, c) => onLocationClick(o, c)}
                              aria-selected={isOptionSelected}
                              isSelected={isOptionSelected}
                              option={item}
                              selectedLocations={selectedLocations}
                              nestingIndex={nestingIndex + 1}
                              sourceRowIndex={sourceRowIndex}
                              sourceRowId={sourceRowId}
                          />
                      );
                  })
                : null}
        </>
    );
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { useLocationSearch } from '@widgets/search/LocationSearch/contexts/useLocationSearch';
import { buildSuggestionCaption } from '@widgets/search/LocationSearch/helpers/buildSuggestionCaption';
import { useRecentLocations } from '@widgets/search/LocationSearch/hooks/useRecentLocations';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';

import type { SelectableLocations } from '../../../types/locationSearchProps';
import {
    StyledLabel,
    StyledListContainer,
    StyledListItem,
    StyledListItemSubtitle,
    StyledListItemTitle,
    StyledListItemWrapper,
} from '../../Shared.theme';

export const RecentLocations = ({ onLocationClick }: SelectableLocations): JSX.Element | null => {
    const {
        config,
        locations: { selectedLocations },
    } = useLocationSearch();
    const { getRecentLocations } = useRecentLocations({ config });
    const [recentLocations, setRecentLocations] = useState<LocationsObjects[]>([]);

    // useEffect is used to avoid logWarn in useLocalStorageCache on first render - [useLocalStorageCache] get, storage is not accessible
    // replace when available to: recentLocations = getRecentLocations()
    useEffect(() => {
        const recentLocationsFromCache = getRecentLocations();
        const filteredRecentLocations = recentLocationsFromCache.filter(
            (recentLocation) =>
                !selectedLocations.some((selectedLocation) => selectedLocation.id === recentLocation.id),
        );
        setRecentLocations(filteredRecentLocations);
    }, [getRecentLocations, selectedLocations]);

    const [t] = useTranslations();

    if (recentLocations.length === 0) {
        return null;
    }

    return (
        <>
            <StyledLabel variant="bold">
                {t('frontend.search.location-picker-tree.location-dropdown-recent-label')}
            </StyledLabel>
            <StyledListContainer>
                {recentLocations.map((recentLocation, index) => {
                    const caption = buildSuggestionCaption(t, recentLocation);

                    return (
                        <StyledListItemWrapper key={recentLocation.id}>
                            <StyledListItem
                                onClick={(): void =>
                                    onLocationClick(recentLocation, {
                                        touchPointButton: 'recently_selected',
                                        locationListPosition: index,
                                    })
                                }
                            >
                                <StyledListItemTitle>{recentLocation.name}</StyledListItemTitle>
                                <StyledListItemSubtitle>{caption}</StyledListItemSubtitle>
                            </StyledListItem>
                        </StyledListItemWrapper>
                    );
                })}
            </StyledListContainer>
            <Divider variant="secondary-light" />
        </>
    );
};

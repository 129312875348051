import type { LocationLevels } from '@type/location/locationLevels';
import type { LocationsObjects } from '@type/search/location/dataItems';

export const RANKING_TYPE_INPUT = {
    default: 'DEFAULT',
    blendedInfixLookupSuggest: 'BLENDED_INFIX_LOOKUP_SUGGEST',
} as const;

export type RankingTypeInput = ObjectValues<typeof RANKING_TYPE_INPUT>;

export interface SuggestionsQueryVariables {
    query: string;
    ranking?: {
        type: RankingTypeInput;
    };
    isLocationSearch?: boolean;
    locationLevelLikeDistrictAndSubdistrict?: string[];
    levels?: LocationLevels;
}

export interface SuggestionsResponseStructure {
    autocomplete: { locationsObjects: LocationsObjects[] };
}

export type SuggestionsType = LocationsObjects[];

export type InputOnSuggestionsLoad = (params: { searchValue: string; suggestions: SuggestionsType }) => void;

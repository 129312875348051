import type { DetailedLevel } from '@type/location/detailedLevel';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { gql } from '@urql/core';

export interface Data {
    locationDetails: {
        id: string;
        name: string;
        fullName: string;
        detailedLevel: DetailedLevel;
        parentIds: string[];
        children: LocationsObjects[];
        parents?: LocationsObjects[];
        __typename: string;
    };
}

export interface Variables {
    id: string;
    locationLevelLikeDistrictAndSubdistrict: string[];
}

export const LOCATION_DETAILS_QUERY = gql<Data, Variables>`
    query locationDetails($id: String!, $locationLevelLikeDistrictAndSubdistrict: [String!]!) {
        locationDetails(locationId: $id, withGeometry: false, simplifiedGeometry: false) {
            ... on LocationObject {
                id
                name
                fullName
                detailedLevel
                parentIds
                parents {
                    id
                    detailedLevel
                    name
                    fullName
                }
                children(input: { filters: { levels: $locationLevelLikeDistrictAndSubdistrict } }) {
                    id
                    name
                    fullName
                    detailedLevel
                    parentIds
                    parents {
                        id
                        detailedLevel
                        name
                        fullName
                        children(input: { limit: 1 }) {
                            id
                            name
                            fullName
                            detailedLevel
                        }
                    }
                    children(input: { filters: { levels: $locationLevelLikeDistrictAndSubdistrict } }) {
                        id
                        name
                        fullName
                        detailedLevel
                        parentIds
                        parents {
                            id
                            detailedLevel
                            name
                            fullName
                            parents {
                                id
                                detailedLevel
                            }
                            children(input: { filters: { levels: $locationLevelLikeDistrictAndSubdistrict } }) {
                                id
                            }
                        }
                    }
                }
                __typename
            }
            ... on ErrorInternal {
                message
            }
        }
    }
`;

import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useTracking } from '@lib/tracking/useTracking';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { useState } from 'react';
import { useQuery } from 'urql';

import { buildSuggestionCaption } from '../helpers/buildSuggestionCaption';
import { type Data, LOCATION_DETAILS_QUERY, type Variables } from '../queries/locationDetailsQuery';
import { useGetTrackingSharedValues } from './useGetTrackingSharedValues';

interface UseChildrenSuggestionReturnValue {
    hasChildren: boolean;
    isOpen: boolean;
    isExpanded: boolean;
    data?: Data;
    fetching: boolean;
    onShowMoreClick: () => void;
    onOpenClick: () => void;
    onCloseClick: () => void;
    caption: string;
}

export const useChildrenSuggestion = ({
    suggestion,
    nestingIndex,
    sourceSuggestionIndex,
    locationParentId,
}: {
    suggestion: LocationsObjects;
    nestingIndex: number;
    sourceSuggestionIndex: number;
    locationParentId?: string;
}): UseChildrenSuggestionReturnValue => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const { locationLevelLikeCity, locationLevelLikeDistrictAndSubdistrict } = SEARCH_CONFIG;
    const hasChildren = !!suggestion.children;
    const shouldBeOpen = locationLevelLikeCity.includes(suggestion.detailedLevel) && hasChildren;
    const [isOpen, setIsOpen] = useState(shouldBeOpen);
    const [isExpanded, setIsExpanded] = useState(false);

    const [{ data, error, operation, fetching }, executeQuery] = useQuery<Data, Variables>({
        query: LOCATION_DETAILS_QUERY,
        variables: {
            id: suggestion.id,
            locationLevelLikeDistrictAndSubdistrict,
        },
        pause: true,
    });

    useHandleGraphqlError({ descriptor: '[LocationSearch - SuggestionRow][locationDetailsQuery]', error, operation });

    const { getTrackingSharedValue } = useGetTrackingSharedValues();

    const sharedTrackingData = getTrackingSharedValue({
        eventType: 'click',
        touchPointButton: 'autosuggest_locations',
        locationParentId,
        locationListPosition: sourceSuggestionIndex,
        locations: [suggestion],
    });

    const onExpand = async (): Promise<void> => {
        executeQuery();
        setIsExpanded(true);
    };

    const onCloseClick = (): void => {
        setIsOpen(false);
        setIsExpanded(false);

        trackEvent('location_collapse_click', sharedTrackingData);
    };

    const onOpenClick = (): void => {
        setIsOpen(true);
        if (!locationLevelLikeCity.includes(suggestion.detailedLevel)) {
            onExpand();
        }

        trackEvent('location_expand_click', sharedTrackingData);
    };

    const onShowMoreClick = (): void => {
        onExpand();

        trackEvent('show_more_locations_click', sharedTrackingData);
    };

    const caption = buildSuggestionCaption(t, suggestion, { displayOnlyDetailedLevelCaption: nestingIndex > 0 });

    return {
        hasChildren,
        isOpen,
        isExpanded,
        data,
        fetching,
        onShowMoreClick,
        onOpenClick,
        onCloseClick,
        caption,
    };
};

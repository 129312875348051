import { getNumberValue } from '../../../../core/helpers/getNumberValue';
import type { FieldAvailableMethods } from '../../../types';

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (fromPrice: string | null, toPrice: string | null, trackedInput: 'min' | 'max'): void => {
        const touchPointButton = trackedInput === 'min' ? 'from_price' : 'to_price';
        trackEvent('price_selected', {
            from_price: getNumberValue(fromPrice),
            to_price: getNumberValue(toPrice),
            touch_point_button: touchPointButton,
        });
    };

export const PRICE_FIELD_PROPS = {
    trackOnBlur,
};

import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { useCallback } from 'react';

import type { LocationSearchConfig } from '../contexts/types';
import { checkIsLocationsObjectsArray } from '../helpers/checkIsLocationsObjectsArray';
import { uniqueLocationsFilter } from '../helpers/uniqueLocationsFilter';

export const useRecentLocations = ({
    config,
}: {
    config: LocationSearchConfig;
}): {
    getRecentLocations: () => LocationsObjects[];
    saveNextRecentLocations: (locationsObjects: LocationsObjects[]) => void;
} => {
    const { enabled, localStorageKey, locationsToRemember } = config.recentSuggestions;
    const [getCacheRecentLocations, saveCacheRecentLocations] = useLocalStorageCache(localStorageKey);

    const getRecentLocations = useCallback((): LocationsObjects[] => {
        if (!enabled) {
            return [];
        }
        const cacheData = getCacheRecentLocations();

        // todo: revalidate and clear cache when version of dataset is different that the one user used(try to migrate?)
        return checkIsLocationsObjectsArray(cacheData) ? cacheData : [];
    }, [enabled, getCacheRecentLocations]);

    const saveNextRecentLocations = useCallback(
        (locationsObjects: LocationsObjects[]): void => {
            if (!enabled) {
                return;
            }
            // keep only last 6 searched locations
            const newRecentLocations = [...locationsObjects, ...getRecentLocations()]
                .filter(uniqueLocationsFilter)
                .slice(0, locationsToRemember);
            saveCacheRecentLocations(newRecentLocations);
        },
        [enabled, getRecentLocations, locationsToRemember, saveCacheRecentLocations],
    );

    return {
        getRecentLocations,
        saveNextRecentLocations,
    };
};

import type { ExplicitDropdownOption } from '@type/search/dropdownOption';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    name: string;
    trackOnSelect: (option: ExplicitDropdownOption<boolean> | null, allCheckedOptions?: string[]) => void;
}

export const useMultiCheckboxField = ({
    name,
    trackOnSelect,
}: Props): {
    fieldValue: string[] | undefined;
    handleOnCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
} => {
    const { setValue } = useFormContext();
    const fieldValue = useWatch({ name });

    const handleOnCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            const itemValue = event.target.value;
            const currentFieldValues: string[] = fieldValue ?? [];
            const isItemValueInCheckFields = currentFieldValues.includes(itemValue);
            const checkFields = isItemValueInCheckFields
                ? currentFieldValues.filter((value) => value !== itemValue)
                : [...currentFieldValues, itemValue];
            setValue(name, checkFields, { shouldDirty: true });

            trackOnSelect?.({ value: !isItemValueInCheckFields, label: itemValue }, checkFields);
        },
        [fieldValue, name, setValue, trackOnSelect],
    );

    return { fieldValue, handleOnCheckboxChange };
};

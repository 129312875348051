import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { ReferenceInputProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { DropdownOpener, StyledTextInput } from './EntryInput.theme';

export const CustomReferenceInput = ({
    setDropdownState,
    displayValue,
    textInputSuffix,
    placeholder,
}: ReferenceInputProps & {
    displayValue: string;
    textInputSuffix: () => JSX.Element;
    placeholder: string;
}): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const {
        setSearchValue,
        config: {
            tracking: { touchPointPage, entryInputTouchPointButton },
            referenceInputId,
            referenceInputPlaceholder = placeholder,
            referenceInputPrefix,
        },
        dataCy,
    } = useLocationSearch();

    return (
        <DropdownOpener
            onClick={(): void => {
                setDropdownState?.(true);
                trackEvent('search_box_click', {
                    event_type: 'click',
                    touch_point_button: entryInputTouchPointButton,
                    touch_point_page: touchPointPage,
                });
            }}
        >
            <StyledTextInput
                id={referenceInputId}
                onChange={(event): void => {
                    setSearchValue(event.target.value);
                }}
                readOnly
                value={displayValue}
                placeholder={t(referenceInputPlaceholder)}
                prefix={referenceInputPrefix}
                suffix={textInputSuffix}
                data-cy={dataCy?.entryInput}
            />
        </DropdownOpener>
    );
};

import { getNumberValue } from '../../../../core/helpers/getNumberValue';
import type { FieldAvailableMethods } from '../../../types';

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (fromPriceM2: string | null, toPriceM2: string | null, trackedInput: 'min' | 'max'): void => {
        const touchPointButton = trackedInput === 'min' ? 'from_price_m2' : 'to_price_m2';

        trackEvent('price_m2_selected', {
            from_price_m2: getNumberValue(fromPriceM2),
            to_price_m2: getNumberValue(toPriceM2),
            touch_point_button: touchPointButton,
        });
    };

export const PRICE_PER_METER_FIELD_PROPS = {
    trackOnBlur,
};

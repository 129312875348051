import { GET_LOCATION_DATASET_VERSION } from '@domains/shared/graphql/GetLocationDatasetVersion';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useQuery } from 'urql';

export const useLocationDatasetVersion = (): {
    isFetching: boolean;
    isError: boolean;
    locationDatasetVersion?: string;
} => {
    const [{ data: locationDatasetVersionResponse, fetching, error, operation }] = useQuery({
        query: GET_LOCATION_DATASET_VERSION,
    });

    const locationDatasetVersion = locationDatasetVersionResponse?.locationsDatasetVersion.locations;

    useHandleGraphqlError({
        descriptor: 'useLocationDatasetVersion',
        error,
        operation,
    });

    return {
        locationDatasetVersion,
        isFetching: fetching,
        isError: !!error,
    };
};

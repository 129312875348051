import { logError } from '@domains/shared/helpers/logger';
import { useCallback, useContext, useRef } from 'react';
import { Context } from 'urql';

import { GET_NEW_GOOGLE_SESSION_TOKEN } from '../graphql/queries/getNewGoogleSessionToken';

const THREE_MINUTES_IN_MS = 180000;

export const useGoogleSessionToken = (): {
    getGoogleSessionToken: () => Promise<string | null>;
    clearCachedGoogleSessionToken: () => void;
} => {
    const urqlClient = useContext(Context);
    const sessionRef = useRef<{
        token: string;
        createdAt: number;
    } | null>();

    const clearCachedGoogleSessionToken = useCallback(() => {
        sessionRef.current = null;
    }, []);

    const getGoogleSessionToken = useCallback(async (): Promise<string | null> => {
        const currentDate = Date.now();
        const currentToken = sessionRef.current?.token;
        const currentTokenCreatedAt = sessionRef.current?.createdAt;
        const isTokenCreatedMoreThan3MinutesAgo =
            currentTokenCreatedAt && Math.abs(currentTokenCreatedAt - currentDate) >= THREE_MINUTES_IN_MS;
        if (!currentToken || isTokenCreatedMoreThan3MinutesAgo) {
            const { data, error } = await urqlClient.query(GET_NEW_GOOGLE_SESSION_TOKEN, {}).toPromise();
            const googleSessionToken = data?.googleSessionToken;

            if (!googleSessionToken) {
                logError('[useGoogleSessionToken], googleSessionToken not found in data', { error });

                clearCachedGoogleSessionToken();

                return null;
            }
            sessionRef.current = { token: googleSessionToken, createdAt: currentDate };

            return googleSessionToken;
        }

        return currentToken;
    }, [clearCachedGoogleSessionToken, urqlClient]);

    return { getGoogleSessionToken, clearCachedGoogleSessionToken };
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const SuffixWrapper = styled.div`
    display: flex;
    right: ${theme.space[0]};
    align-items: center;
    pointer-events: none;
`;

export const CloseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${theme.space[8]};
    cursor: pointer;
    pointer-events: auto;
`;

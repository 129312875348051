import type { FieldAvailableMethods } from '../../../types';

const trackOnChange =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: boolean): void => {
        trackEvent('filter_open_day_selected', {
            only_open_day: value,
            touch_point_button: 'filters',
        });
    };

export const HAS_OPEN_DAY_FIELD_PROPS = {
    trackOnChange,
};

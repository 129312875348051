import { getTransactionVariant } from '@domains/shared/helpers/getTransactionVariant';
import { getTransactionVariantId } from '@domains/shared/helpers/getTransactionVariantId';
import type { FilterOption } from '@type/search/filters/option';
import type { Transaction } from '@type/search/filters/transaction';

import type { FieldAvailableMethods } from '../../../types';

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods, _: unknown, trackingData?: Record<string, unknown>) =>
    (): void => {
        trackEvent('category_click', { touch_point_button: 'filters', ...trackingData });
    };

const trackOnSelect =
    ({ trackEvent, getValues }: FieldAvailableMethods, _: unknown, trackingData?: Record<string, unknown>) =>
    (option: FilterOption<Transaction> | null): void => {
        if (!option) {
            return;
        }

        const estate = getValues('estate');
        const estateToTrack = estate.value;
        const transactionVariant = getTransactionVariant({
            estate: estateToTrack,
            transaction: option.value as Transaction,
        });

        trackEvent('category_selected', {
            touch_point_button: 'filters',
            cat_l1_id: getTransactionVariantId(transactionVariant),
            cat_l1_name: option.label,
            business: option.value.toLowerCase(),
            ...trackingData,
        });
    };

export const TRANSACTION_FIELD_PROPS = {
    trackOnSelect,
    trackOnMenuOpen,
};

import type { JSX, PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import { decideInitialLocationSearchView } from '../helpers/decideInitialLocationSearchView';
import { fillPartialConfigToRequired } from '../helpers/fillPartialConfigToRequired';
import { useDistanceRadius } from '../hooks/useDistanceRadius';
import { useFormSync } from '../hooks/useFormSync';
import { useGoogleSuggestion } from '../hooks/useGoogleSuggestion';
import { useLocations } from '../hooks/useLocations';
import type { LocationSearchProps, LocationSearchView } from '../types/locationSearchProps';
import { LOCATION_PICKER_DEFAULT_VIEW, LOCATION_PICKER_VIEW } from '../types/locationSearchProps';
import { DEFAULT_LOCATION_PICKER_CONTEXT_RETURN_VALUE } from './constants';
import type { LocationSearchContextProps } from './types';

export const LocationSearchContext = createContext<LocationSearchContextProps>(
    DEFAULT_LOCATION_PICKER_CONTEXT_RETURN_VALUE,
);
type Props = PropsWithChildren<LocationSearchProps>;

export const LocationSearchContextProvider = ({
    children,
    initial,
    distanceRadiusOptions,
    distanceRadiusDefaultValues,
    config,
    dataCy,
    useSyncValues = useFormSync,
}: Props): JSX.Element => {
    const requiredConfig = fillPartialConfigToRequired(config);
    const [locationSearchView, setLocationSearchView] = useState<LocationSearchView>(
        decideInitialLocationSearchView(initial, requiredConfig),
    );
    const [searchValue, setSearchValue] = useState<string>(initial?.searchValue ?? '');
    const distanceRadius = useDistanceRadius({
        defaultInitialDistanceRadiusType:
            requiredConfig.defaultView === LOCATION_PICKER_DEFAULT_VIEW.street ? 'm' : 'km',
        initial,
        distanceRadiusOptions,
        distanceRadiusDefaultValues,
    });
    const locations = useLocations({ initial: initial?.locations, config: requiredConfig });
    const googleSuggestion = useGoogleSuggestion({ initial: initial?.googleSuggestion });

    const changeLocationSearchView = useCallback(
        (nextLocationSearchView: LocationSearchView) => {
            const nextDistanceRadiusType = nextLocationSearchView === LOCATION_PICKER_VIEW.street ? 'm' : 'km';
            if (distanceRadius.distanceRadiusType !== nextDistanceRadiusType) {
                distanceRadius.selectDistanceRadius(null);
                distanceRadius.setDistanceRadiusType(nextDistanceRadiusType);
            }
            setLocationSearchView(nextLocationSearchView);
        },
        [distanceRadius],
    );

    const resetState = useCallback((): void => {
        changeLocationSearchView(requiredConfig.defaultView);
        setSearchValue('');
        distanceRadius.setDistanceRadiusType('km');
        distanceRadius.selectDistanceRadius(null);
        locations.unselectAllLocations();
        googleSuggestion.unselectGoogleSuggestion();
    }, [changeLocationSearchView, requiredConfig.defaultView, distanceRadius, locations, googleSuggestion]);

    useSyncValues({
        selected: {
            locations: locations.selectedLocations,
            googleSuggestion: googleSuggestion.selectedGoogleSuggestion,
            geometry: null,
            distanceRadius: distanceRadius.selectedDistanceRadius,
        },
        onClear: resetState,
    });

    const value = useMemo(
        () => ({
            locationSearchView,
            changeLocationSearchView,
            searchValue,
            setSearchValue,
            locations,
            googleSuggestion,
            distanceRadius,
            config: requiredConfig,
            resetState,
            dataCy,
        }),
        [
            locationSearchView,
            changeLocationSearchView,
            searchValue,
            locations,
            googleSuggestion,
            distanceRadius,
            requiredConfig,
            resetState,
            dataCy,
        ],
    );

    return <LocationSearchContext.Provider value={value}>{children}</LocationSearchContext.Provider>;
};

import { checkIsOption } from '@type/search/dropdownOption';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { FilterOption } from '@type/search/filters/option';
import type { SearchingFilters } from '@type/search/searchingFilters';

import { parseQueryParamsToSearchingFilters } from '../../parseQueryParamsToSearchingFilters';

export const parseFormValuesToApiObject = (
    params: Record<string, string | string[] | FilterOption | null | boolean>,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): Partial<SearchingFilters> => {
    const formData: Record<string, string | string[] | boolean | undefined> = {};

    for (const [paramKey, paramValue] of Object.entries(params)) {
        let value = null;

        /**
         * Get value only from every key that is Option type
         * ex: {value, '', label: ''}
         */
        value = checkIsOption(paramValue) ? paramValue.value : paramValue;

        if (value !== null) {
            formData[paramKey] = value;
        }
    }

    return parseQueryParamsToSearchingFilters({
        fieldsMetadataExperimentsVariants,
        queryParams: formData,
        paramsScope: undefined,
        shouldFilterOutEstateAndTransaction: false,
    });
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Fieldset = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;
`;

export const Legend = styled.legend`
    margin-bottom: ${theme.space[4]};
    padding: 0;
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.space[18]};
`;

export const FieldsPair = styled.div`
    display: grid;
    grid-column-gap: ${theme.space[8]};
    grid-template-columns: 1fr 1fr;
`;

export const InputWrapper = styled.label`
    display: inline-flex;
    align-items: center;
    gap: ${theme.space[8]};
    height: ${theme.sizes[48]};
    padding: 0 ${theme.space[16]};
    border-bottom: ${theme.borderWidths[2]} solid transparent;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-secondary']};
    cursor: text;

    &:focus-within {
        border-radius: ${theme.radii[4]} ${theme.radii[4]} 0 0;
        border-bottom-color: ${theme.colors['background-brand-primary']};
    }
`;

export const StyledInput = styled.input`
    width: 100%;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[16]};

    &::placeholder {
        color: ${theme.colors['text-global-secondary']};
    }
`;

export const Suffix = styled.div`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p2};
    pointer-events: none;
    user-select: none;
`;

import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { getLocationDetailedLevelTranslation } from '@widgets/search/core/helpers/getLocationDetailedLevelTranslation';

export const buildSuggestionCaption = (
    t: Translator,
    suggestion: LocationsObjects,
    { displayOnlyDetailedLevelCaption = false }: { displayOnlyDetailedLevelCaption?: boolean } = {},
): string => {
    const detailedLevelText = getLocationDetailedLevelTranslation(t, suggestion.detailedLevel);
    const captionSuffix = `${suggestion.fullName.split(', ').splice(1).join(', ')}`;
    const shouldUseSuffix = !displayOnlyDetailedLevelCaption && captionSuffix;
    const caption = shouldUseSuffix ? `${detailedLevelText}, ${captionSuffix}` : detailedLevelText;

    return caption;
};

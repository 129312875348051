import { Button } from '@domains/shared/components/Button/Button';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button as NexusButton } from '@nexus/lib-react/dist/core/Button';

import { SEARCH_WIDGET_THEME } from '../theme/[[[SITECODE]]]';

export const FormButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 128px;
    background-color: ${SEARCH_WIDGET_THEME.home.investmentsSearchSection.bgColor};

    &:hover {
        background-color: ${SEARCH_WIDGET_THEME.home.investmentsSearchSection.hoverBgColor};
    }

    &:focus,
    &:active {
        background-color: ${SEARCH_WIDGET_THEME.home.investmentsSearchSection.bgColor};
    }

    &:disabled {
        &,
        &:focus {
            background-color: ${SEARCH_WIDGET_THEME.home.investmentsSearchSection.bgColor};
        }
        &:hover {
            background-color: ${SEARCH_WIDGET_THEME.home.investmentsSearchSection.hoverBgColor};
        }
    }

    @media ${theme.mediaQueries.lgMin} {
        width: 100%;
    }
`;

export const StyledNexusButton = styled(NexusButton)`
    width: 100%;
`;

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Dropdown } from '@nexus/lib-react/dist/core/Dropdown';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';

export const DropdownWrapper = styled.div`
    width: 100%;
`;

// transform and inset as unset important to disable popper placement mechanism
export const DesktopDropdown = styled(Dropdown)`
    margin-top: -48px;
    padding: 0;
    overflow: hidden;
    transform: none !important;
    border: none;
    box-shadow: ${theme.shadows[4]};
    inset: unset !important;

    --maxHeight: 100%;
`;

export const MobileDropdown = styled(Dropdown)`
    position: fixed !important;
    height: 100% !important;
    margin: 0;
    padding: 0;
    background-color: ${theme.colors['background-global-primary']};

    --maxHeight: none;
`;

export const DropdownOpener = styled.div`
    width: 100%;
`;

/* To remove after fixing the issue in nexus lib - readonly filed got disabled color */
export const StyledTextInput = styled(TextInput)`
    input:first-of-type {
        color: ${theme.colors['text-global-primary']};
    }
`;

import type { FieldAvailableMethods } from '../../../types';

const trackOnChange =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: boolean): void => {
        trackEvent('filter_with_movie_selected', {
            with_movie: value,
            touch_point_button: 'filters',
        });
    };

export const HAS_MOVIE_FIELD_PROPS = {
    trackOnChange,
};

import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ChangeEvent, JSX } from 'react';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { StyledTextInput } from './InputField.theme';

interface Props {
    name: string;
    value: string;
    inputId: string;
    dataCy?: string;
    ariaLabel?: string;
    trackOnFocus?: () => void;
    trackOnBlur?: (value: string) => void;
}

export const InputField = ({
    name,
    inputId,
    dataCy = 'text-input-field',
    trackOnBlur,
    trackOnFocus,
    ...props
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { register } = useFormContext();
    const value = useWatch({ name });
    const { onBlur: registeredOnBlur, ...registerField } = register(name);

    const ariaLabel = t(GET_NON_GENERIC_TRANSLATION_KEY.label(name));

    const handleOnFocus = useCallback(() => {
        trackOnFocus?.();
    }, [trackOnFocus]);

    const handleOnBlur = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            registeredOnBlur?.(event);
            trackOnBlur?.(value);
        },
        [registeredOnBlur, trackOnBlur, value],
    );

    return (
        <StyledTextInput
            {...registerField}
            id={inputId}
            inputMode="text"
            type="text"
            data-cy={dataCy}
            aria-label={ariaLabel}
            autoComplete="off"
            {...props}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
        />
    );
};

import { CheckboxInput, CheckboxLabel } from '@nexus/lib-react/dist/core/CheckboxInput';
import type { JSX } from 'react';

import { useCheckboxField } from './hooks/useCheckboxField';

interface Props {
    name: string;
    dataCy: string;
    inputId: string;
    trackOnChange: (option: boolean) => void;
}

export const CheckboxField = ({ name, inputId, dataCy, trackOnChange }: Props): JSX.Element => {
    const { label, registerField, handleOnChange } = useCheckboxField({ name, trackOnChange });

    return (
        <div>
            <CheckboxInput data-cy={dataCy} id={inputId} {...registerField} onChange={handleOnChange} />
            <CheckboxLabel htmlFor={inputId}>{label}</CheckboxLabel>
        </div>
    );
};

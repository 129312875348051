import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import type { JSX, PropsWithChildren } from 'react';

import { Back, ClearButton, Container, Header, Title } from './MobileContainer.theme';

interface MobileContainerProps {
    title: string;
    onClearClick?: () => void;
    onBackClick?: () => void;
}
export const MobileContainer = ({
    children,
    title,
    onClearClick,
    onBackClick,
}: PropsWithChildren<MobileContainerProps>): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container>
            <Header>
                {onBackClick ? (
                    <Back
                        id="close-location-search"
                        aria-label={t('frontend.global.navigation.go-back')}
                        variant="flat"
                        prefixIcon={ChevronLeft}
                        onClick={onBackClick}
                    />
                ) : null}

                <Title variant="regular">{title}</Title>
                {onClearClick ? (
                    <ClearButton variant="flat" onClick={onClearClick} id="clear-location-search">
                        {t('frontend.search.location-search.clear')}
                    </ClearButton>
                ) : null}
            </Header>
            {children}
        </Container>
    );
};

import { SITE_CONFIG } from '@config/siteConfig';

const { area, currency, distance } = SITE_CONFIG.defaultUnits;

export const UNITS: Record<string, string> = {
    area,
    terrainArea: area,
    height: distance,
    pricePerMeter: `${currency}/${area}`,
    price: currency,
};

import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX } from 'react';
import { useCallback, useContext, useRef } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { checkShouldDisplayCityView } from '../../helpers/checkShouldDisplayCityView';
import { getAllParentsForLocationListNode } from '../../helpers/getAllParentsForLocationListNode';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import { useLocationList } from '../../hooks/useLocationList';
import type { SelectableLocationListNode } from '../../types/list';
import { DesktopContainer } from '../DesktopContainer';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { LocationInput } from '../LocationInput';
import { MobileConfirm } from '../MobileConfirm';
import { MobileContainer } from '../MobileContainer';
import { ContentContainer, StyledBackButton, StyledListContainer } from '../Shared.theme';
import { LocationItems } from './LocationItems';

export const ListView = ({ setDropdownState }: FlyoutProps<unknown>): JSX.Element => {
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);
    const refExpandNestingLevel = useRef(0);

    const [t] = useTranslations();
    const {
        changeLocationSearchView,
        setSearchValue,
        locations: { selectedLocations, selectLocations, selectTemporarySuggestions, unselectAllLocations },
        config: {
            tracking: { touchPointPage },
            multiple,
            defaultView,
        },
    } = useLocationSearch();

    const { allLocations, locations, parentLocation, isFetching, previousParent, setParentLocation } =
        useLocationList();

    const { getTrackingSharedValue } = useGetTrackingSharedValues();

    const onBackClick = useCallback(() => {
        trackEvent('back_button_click', {
            event_type: 'click',
            touch_point_button: `back_L${refExpandNestingLevel.current}`,
            touch_point_page: touchPointPage,
        });

        if (refExpandNestingLevel.current > 0) {
            refExpandNestingLevel.current--;
        }

        // parentLocation mean we are inside of nested view
        if (parentLocation) {
            // back to parentLocation.parent, null mean root locations list view
            setParentLocation(previousParent);

            return;
        }
        // get out of location search if there is no parent location, root location does not have parent
        changeLocationSearchView(defaultView);
    }, [
        trackEvent,
        touchPointPage,
        parentLocation,
        changeLocationSearchView,
        defaultView,
        setParentLocation,
        previousParent,
    ]);

    const onLocationClick: SelectableLocationListNode['onLocationClick'] = useCallback(
        (location, { tryOpenSublocations = true, touchPointButton }): void => {
            const isLocationToOpen = location.sublocations.length > 0 || !location.parentId;
            const isOpenable = tryOpenSublocations && isLocationToOpen;
            const eventName = isOpenable ? 'location_tree_navigation_click' : 'search_box_list_selection_click';

            trackEvent(
                eventName,
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton,
                    locationParentId: location.parentId ?? undefined,
                    locations: [location],
                }),
            );

            if (isOpenable) {
                refExpandNestingLevel.current++;
                setParentLocation(location);

                return;
            }

            const suggestion: LocationsObjects = {
                id: location.id,
                name: location.name,
                fullName: location.name,
                detailedLevel: location.detailedLevel,
                parentIds: [],
            };

            // fill with children to validate if city view can be displayed
            const suggestionWithChildren: LocationsObjects = {
                ...suggestion,
                ...(location.sublocations.length > 0
                    ? {
                          children: location.sublocations.map((subLocation) => ({
                              id: subLocation.id,
                              name: subLocation.name,
                              fullName: subLocation.name,
                              detailedLevel: subLocation.detailedLevel,
                              parentIds: [],
                          })),
                      }
                    : null),
            };

            const shouldDisplayCityView = checkShouldDisplayCityView(suggestionWithChildren);

            if (shouldDisplayCityView && multiple) {
                // build parents for City View to display correct data
                const allParentsForSuggestion = getAllParentsForLocationListNode(location, allLocations);
                const suggestionWithParentsAndChildren = {
                    ...suggestionWithChildren,
                    parents: allParentsForSuggestion.map((parent) => ({
                        id: parent.id,
                        name: parent.name,
                        fullName: parent.name,
                        detailedLevel: parent.detailedLevel,
                        parentIds: [],
                    })),
                };
                isDesktop
                    ? selectLocations([suggestionWithParentsAndChildren])
                    : selectTemporarySuggestions([suggestionWithParentsAndChildren]);
                changeLocationSearchView('city');

                return;
            }

            selectLocations([suggestion]);

            changeLocationSearchView(defaultView);
            setDropdownState?.(false);
        },
        [
            trackEvent,
            getTrackingSharedValue,
            multiple,
            selectLocations,
            changeLocationSearchView,
            defaultView,
            setDropdownState,
            setParentLocation,
            allLocations,
            isDesktop,
            selectTemporarySuggestions,
        ],
    );

    if (isDesktop) {
        return (
            <DesktopContainer setDropdownState={setDropdownState}>
                <LocationInput
                    selectedLocation={selectedLocations[0]}
                    onClear={() => {
                        unselectAllLocations();
                        setSearchValue('');

                        trackEvent('clear_filters', {
                            event_type: 'click',
                            touch_point_button: 'location_tree',
                            touch_point_page: touchPointPage,
                        });
                    }}
                />
                <ContentContainer alwaysFullHeight>
                    <StyledListContainer>
                        <StyledBackButton prefixIcon={ChevronLeft} onClick={onBackClick}>
                            {t('frontend.global.navigation.go-back')}
                        </StyledBackButton>
                    </StyledListContainer>

                    <Divider variant="secondary-dark" />
                    {isFetching ? (
                        <LoadingSkeleton />
                    ) : (
                        <LocationItems
                            onLocationClick={onLocationClick}
                            locations={locations}
                            parentLocation={parentLocation}
                        />
                    )}
                </ContentContainer>
            </DesktopContainer>
        );
    }

    // Mobile view
    return (
        <MobileContainer title={t('frontend.search.location-search.mobile-title')} onBackClick={onBackClick}>
            <ContentContainer>
                {isFetching ? (
                    <LoadingSkeleton />
                ) : (
                    <LocationItems
                        onLocationClick={onLocationClick}
                        locations={locations}
                        parentLocation={parentLocation}
                    />
                )}
            </ContentContainer>
            {parentLocation ? (
                <MobileConfirm
                    text={t('frontend.search.location-search.search-in', {
                        name: parentLocation.name,
                    })}
                    onClick={() =>
                        onLocationClick(parentLocation, { tryOpenSublocations: false, touchPointButton: 'search_in' })
                    }
                />
            ) : null}
        </MobileContainer>
    );
};

import { FIELDS_PROPS } from '../rendering/props/collection';
import type { FieldAvailableMethods } from '../types';

export const createCallbackProps = (
    fieldName: string,
    props: Record<string, unknown> & { trackingData?: Record<string, unknown> },
    fieldAvailableMethods: FieldAvailableMethods,
): Record<string, unknown> => {
    const callbackProps: Record<string, unknown> = {};
    const fieldProps = FIELDS_PROPS[fieldName];
    const options = props?.options || null;

    if (fieldProps) {
        /**
         * Here we are adding to props all callbacks/methods contained in fieldsProps.ts. By that we are able
         * to execute tracking, transformations or custom events on dynamically rendered fields
         */
        for (const [callbackName, callbackFunction] of Object.entries(fieldProps)) {
            callbackProps[callbackName] = callbackFunction(fieldAvailableMethods, options, props.trackingData);
        }
    }

    return callbackProps;
};

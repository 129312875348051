import { repeatItem } from '@domains/shared/helpers/repeatItem';
import type { JSX } from 'react';

import { ListItemSkeletonWrapper, StyledSkeleton, Wrapper } from './LoadingSkeleton.theme';

const DEFAULT_COUNT = 6;

const ListItemSkeleton = (): JSX.Element => (
    <ListItemSkeletonWrapper data-testid="location-search-list-item-skeleton">
        <StyledSkeleton height={20} width={145} />
        <StyledSkeleton height={16} width={220} />
    </ListItemSkeletonWrapper>
);

export const LoadingSkeleton = (): JSX.Element => {
    return (
        <Wrapper>
            {repeatItem(DEFAULT_COUNT, (id) => (
                <ListItemSkeleton key={id} />
            ))}
        </Wrapper>
    );
};

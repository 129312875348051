import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ChangeEvent, RefCallback } from 'react';
import { useCallback } from 'react';
import type { ChangeHandler } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    name: string;
    trackOnChange: (option: boolean) => void;
}

export const useCheckboxField = ({
    name,
    trackOnChange,
}: Props): {
    label: string;
    registerField: {
        onBlur: ChangeHandler;
        ref: RefCallback<HTMLInputElement>;
        name: string;
    };
    handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
} => {
    const value = useWatch({ name });
    const [t] = useTranslations();
    const { register } = useFormContext();
    const { onChange: registeredOnChange, ...registerField } = register(name);

    const label = t(GET_NON_GENERIC_TRANSLATION_KEY.label(name));

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            registeredOnChange?.(event);
            trackOnChange?.(!value);
        },
        [registeredOnChange, trackOnChange, value],
    );

    return {
        label,
        registerField,
        handleOnChange,
    };
};

import { logWarn } from '@domains/shared/helpers/logger';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { DetailedLevel, DetailedLevelPL, DetailedLevelPT, DetailedLevelRO } from '@type/location/detailedLevel';

const TRANSLATION_KEY_PL: Record<DetailedLevelPL, string> = {
    voivodeship: 'frontend.search.detailed-location.voivodeship',
    county: 'frontend.search.detailed-location.county',
    commune: 'frontend.search.detailed-location.commune',
    urban_commune: 'frontend.search.detailed-location.urban_commune',
    urban_rural_commune: 'frontend.search.detailed-location.urban_rural_commune',
    rural_commune: 'frontend.search.detailed-location.rural_commune',
    city: 'frontend.search.detailed-location.city',
    village: 'frontend.search.detailed-location.village',
    district: 'frontend.search.detailed-location.district',
    residential: 'frontend.search.detailed-location.residential',
};

const TRANSLATION_KEY_RO: Record<DetailedLevelRO, string> = {
    county: 'frontend.search.detailed-location.county',
    county_capital: 'frontend.search.detailed-location.county_capital',
    municipality: 'frontend.search.detailed-location.municipality',
    town: 'frontend.search.detailed-location.town',
    commune: 'frontend.search.detailed-location.commune',
    sector: 'frontend.search.detailed-location.sector',
    district: 'frontend.search.detailed-location.district',
    village: 'frontend.search.detailed-location.village',
};

const TRANSLATION_KEY_PT: Record<DetailedLevelPT, string> = {
    district: 'frontend.search.detailed-location.district',
    council: 'frontend.search.detailed-location.council',
    parish: 'frontend.search.detailed-location.parish',
    neighborhood: 'frontend.search.detailed-location.neighborhood',
};

// all duplicated keys should contain same value!
const TRANSLATION_KEY = { ...TRANSLATION_KEY_PL, ...TRANSLATION_KEY_RO, ...TRANSLATION_KEY_PT };

export const getLocationDetailedLevelTranslation = (t: Translator, detailedLevel: DetailedLevel): string => {
    if (TRANSLATION_KEY[detailedLevel]) {
        return t(TRANSLATION_KEY[detailedLevel]);
    }

    logWarn('[getLocationDetailedLevelTranslation] used unsupported detailedLevel', { data: detailedLevel });

    return '';
};

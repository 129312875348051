import type { LocationListNode } from '../types/list';

export const findLocationListNodeById = (id: string, locations: LocationListNode[]): LocationListNode | null => {
    for (const location of locations) {
        if (location.id === id) {
            return location;
        }
        const foundLocation = findLocationListNodeById(id, location.sublocations ?? []);
        if (foundLocation) {
            return foundLocation;
        }
    }

    return null;
};

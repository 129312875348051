import { NexusDropdownWithCheckboxes } from '@domains/shared/components/DropdownWithCheckboxes/NexusDropdownWithCheckboxes';
import type { DropdownProps } from '@widgets/search/types/dropdownProps';
import type { JSX } from 'react';

export const MultiSelectField = ({
    name,
    options,
    inputId,
    dataCy,
    ariaLabel,
    trackOnMenuOpen,
    trackOnSelect,
    ...props
}: DropdownProps<string[]>): JSX.Element => {
    return (
        <NexusDropdownWithCheckboxes
            id={inputId}
            name={name}
            trackOnSelect={trackOnSelect}
            trackingOnDropdownExpanded={trackOnMenuOpen}
            options={options}
            aria-label={ariaLabel}
            cyCheckboxLabel={`${dataCy}--checkbox`}
            cyLabel={`${dataCy}--label`}
            cyButton={`${dataCy}`}
            cyList={`${dataCy}--list`}
            {...props}
        />
    );
};

import type { DropdownOption } from '@type/search/dropdownOption';
import type { FilterOption as Option } from '@type/search/filters/option';
import { useEffect, useState } from 'react';

type Options = readonly Option<string>[] & readonly DropdownOption<string>[];

const getCommaSeparatedOptions = (options: Options, values: string[]): string => {
    return options
        .filter((option) => values.includes(option.value))
        .map((option) => option.label)
        .sort((a, b) => Number(a) - Number(b))
        .join(', ');
};

const getChipsOptions = (options: Options, values: string[]): Option<string>[] => {
    return options.filter((option) => values.includes(option.value)).sort((a, b) => Number(a.label) - Number(b.label));
};

interface Props {
    fieldValue?: string[];
    options: Options;
    withChips: boolean;
}

export const usePickedOptions = ({ fieldValue, options, withChips }: Props): Option<string>[] | string | null => {
    const [pickedOptions, setPickedOptions] = useState<Option<string>[] | string | null>(null);

    useEffect(() => {
        const values = fieldValue;

        if (!values || values.length === 0) {
            setPickedOptions(null);

            return;
        }

        const pickedOptions = withChips ? getChipsOptions(options, values) : getCommaSeparatedOptions(options, values);

        setPickedOptions(pickedOptions);
    }, [fieldValue, withChips, options]);

    return pickedOptions;
};

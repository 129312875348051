interface NonGenericTranslationsHelper {
    fieldValue: (fieldName: string, fieldValue: string | undefined) => string;
    placeholder: (key: string) => string;
    placeholderExtended: (key: string) => string;
    label: (key: string) => string;
    inlineLabel: (key: string) => string;
    badge: (key: string) => string;
    alert: (key: string) => string;
}

// @TODO: EURE-27021 remove all the other dynamic keys too
export const GET_NON_GENERIC_TRANSLATION_KEY: NonGenericTranslationsHelper = {
    fieldValue: (fieldName, fieldValue) => {
        if (fieldName === 'transaction') {
            switch (fieldValue?.toUpperCase()) {
                case 'RENT': {
                    return 'frontend.global.search.transaction-rent';
                }
                case 'SELL': {
                    return 'frontend.global.search.transaction-sell';
                }
                default: {
                    return 'frontend.global.search.transaction-all';
                }
            }
        }

        if (fieldName === 'market') {
            switch (fieldValue) {
                case 'PRIMARY': {
                    return 'frontend.global.search.market-primary';
                }
                case 'SECONDARY': {
                    return 'frontend.global.search.market-secondary';
                }
                default: {
                    return 'frontend.global.search.market-all';
                }
            }
        }

        return `frontend.search.form-${fieldName?.toLowerCase()}-${fieldValue?.toLowerCase()}-fieldvalue`;
    },
    placeholder: (key) => `frontend.search.form-${key.toLowerCase()}-placeholder`,
    placeholderExtended: (key) => `frontend.search.form-${key.toLowerCase()}-placeholder-extended`,
    label: (key) => `frontend.search.form-${key.toLowerCase()}-label`,
    inlineLabel: (key) => `frontend.search.form-${key.toLowerCase()}-inline-label`,
    badge: (key) => `frontend.search.form-${key.toLowerCase()}-badge`,
    alert: (key) => `frontend.search.form-${key.toLowerCase()}-alert`,
};

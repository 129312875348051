import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';

import type { FieldAvailableMethods } from '../../types';
import { ADVERT_ID_FIELD_PROPS } from './impl/advertId';
import { AREA_FIELD_PROPS } from './impl/area';
import { BUILDING_MATERIAL_FIELD_PROPS } from './impl/buildingMaterial';
import { BUILDING_TYPE_FIELD_PROPS } from './impl/buildingType';
import { BUILD_YEAR_FIELD_PROPS } from './impl/buildYear';
import { DAYS_SINCE_CREATED_FIELD_PROPS } from './impl/daysSinceCreated';
import { DESCRIPTION_FIELD_PROPS } from './impl/description';
import { ESTATE_FIELD_PROPS } from './impl/estate';
import { EXTRAS_FIELD_PROPS } from './impl/extras';
import { FLOOR_FIELD_PROPS } from './impl/floor';
import { FLOORS_NUMBER_FIELD_PROPS } from './impl/floorsNumber';
import { FREE_FROM_FIELD_PROPS } from './impl/freeFrom';
import { HAS_MOVIE_FIELD_PROPS } from './impl/hasMovie';
import { HAS_OPEN_DAY_FIELD_PROPS } from './impl/hasOpenDay';
import { HAS_PHOTOS_FIELD_PROPS } from './impl/hasPhotos';
import { HAS_WALKAROUND_FIELD_PROPS } from './impl/hasWalkaround';
import { INVESTMENT_ESTATE_TYPE_FIELD_PROPS } from './impl/investmentEstateType';
import { INVESTMENT_STATE_FIELD_PROPS } from './impl/investmentState';
import { IS_EXCLUSIVE_OFFER_FIELD_PROPS } from './impl/isExclusiveOffer';
import { MARKET_FIELD_PROPS } from './impl/market';
import { OWNER_TYPE_SINGLE_SELECT_FIELD_PROPS } from './impl/ownerTypeSingleSelect';
import { PEOPLE_PER_ROOM_FIELD_PROPS } from './impl/peoplePerRoom';
import { PRICE_FIELD_PROPS } from './impl/price';
import { PRICE_PER_METER_FIELD_PROPS } from './impl/pricePerMeter';
import { ROOMS_NUMBER_FIELD_PROPS } from './impl/roomsNumber';
import { TRANSACTION_FIELD_PROPS } from './impl/transaction';

type FieldsProps = Record<
    string,
    Record<
        string,
        (arguments_: FieldAvailableMethods, options?: unknown, trackingData?: Record<string, unknown>) => unknown
    >
>;

/**
 * Map all props needed to be passed down to the rendered component.
 * Key of this object is an ID of rendered field and key of specific property value is prop name.
 */
export const FIELDS_PROPS: FieldsProps = {
    [SEARCH_FORM_UNIVERSAL_FIELD.estate]: {
        trackOnMenuOpen: ESTATE_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: ESTATE_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.transaction]: {
        trackOnMenuOpen: TRANSACTION_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: TRANSACTION_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.price]: {
        trackOnBlur: PRICE_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.area]: {
        trackOnBlur: AREA_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber]: {
        trackOnMenuOpen: ROOMS_NUMBER_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: ROOMS_NUMBER_FIELD_PROPS.trackOnSelect,
        trackOnClear: ROOMS_NUMBER_FIELD_PROPS.trackOnClear,
        trackOnChipClear: ROOMS_NUMBER_FIELD_PROPS.trackOnChipClear,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.peoplePerRoom]: {
        trackOnMenuOpen: PEOPLE_PER_ROOM_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: PEOPLE_PER_ROOM_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.freeFrom]: {
        trackOnMenuOpen: FREE_FROM_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: FREE_FROM_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.investmentEstateType]: {
        trackOnMenuOpen: INVESTMENT_ESTATE_TYPE_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: INVESTMENT_ESTATE_TYPE_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.market]: {
        onValueChange: MARKET_FIELD_PROPS.onValueChange,
        trackOnMenuOpen: MARKET_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: MARKET_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect]: {
        getEventListeners: OWNER_TYPE_SINGLE_SELECT_FIELD_PROPS.getEventListeners,
        trackOnMenuOpen: OWNER_TYPE_SINGLE_SELECT_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: OWNER_TYPE_SINGLE_SELECT_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter]: {
        trackOnBlur: PRICE_PER_METER_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.buildingType]: {
        trackOnMenuOpen: BUILDING_TYPE_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: BUILDING_TYPE_FIELD_PROPS.trackOnSelect,
        trackOnClear: BUILDING_TYPE_FIELD_PROPS.trackOnClear,
        trackOnChipClear: BUILDING_TYPE_FIELD_PROPS.trackOnChipClear,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.buildingMaterial]: {
        trackOnMenuOpen: BUILDING_MATERIAL_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: BUILDING_MATERIAL_FIELD_PROPS.trackOnSelect,
        trackOnClear: BUILDING_MATERIAL_FIELD_PROPS.trackOnClear,
        trackOnChipClear: BUILDING_MATERIAL_FIELD_PROPS.trackOnChipClear,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.floors]: {
        trackOnMenuOpen: FLOOR_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: FLOOR_FIELD_PROPS.trackOnSelect,
        trackOnClear: FLOOR_FIELD_PROPS.trackOnClear,
        trackOnChipClear: FLOOR_FIELD_PROPS.trackOnChipClear,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.floorsNumber]: {
        trackOnBlur: FLOORS_NUMBER_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.buildYear]: {
        trackOnBlur: BUILD_YEAR_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.hasOpenDay]: {
        trackOnChange: HAS_OPEN_DAY_FIELD_PROPS.trackOnChange,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.isExclusiveOffer]: {
        trackOnChange: IS_EXCLUSIVE_OFFER_FIELD_PROPS.trackOnChange,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated]: {
        trackOnMenuOpen: DAYS_SINCE_CREATED_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: DAYS_SINCE_CREATED_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.advertId]: {
        trackOnFocus: ADVERT_ID_FIELD_PROPS.trackOnFocus,
        trackOnBlur: ADVERT_ID_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.description]: {
        trackOnFocus: DESCRIPTION_FIELD_PROPS.trackOnFocus,
        trackOnBlur: DESCRIPTION_FIELD_PROPS.trackOnBlur,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.hasPhotos]: {
        trackOnChange: HAS_PHOTOS_FIELD_PROPS.trackOnChange,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.hasMovie]: {
        trackOnChange: HAS_MOVIE_FIELD_PROPS.trackOnChange,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.hasWalkaround]: {
        trackOnChange: HAS_WALKAROUND_FIELD_PROPS.trackOnChange,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.extras]: {
        trackOnSelect: EXTRAS_FIELD_PROPS.trackOnSelect,
    },
    [SEARCH_FORM_UNIVERSAL_FIELD.investmentState]: {
        trackOnMenuOpen: INVESTMENT_STATE_FIELD_PROPS.trackOnMenuOpen,
        trackOnSelect: INVESTMENT_STATE_FIELD_PROPS.trackOnSelect,
    },
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Badge } from '@nexus/lib-react/dist/core/Badge';
import { Chip } from '@nexus/lib-react/dist/core/Chip';
import type { CSSProperties } from 'react';

export const ChipsInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[8]};
    width: 100%;
`;
// width: 42px - space for badge
// max-height: 32px * 2 from chips, 8px from gaps, 3px from padding top, 3px from padding bottom
// padding: 1px due to overflow & 2px due to chip hover outline width
export const LocationsChipListContainer = styled.div`
    display: flex;
    position: relative;
    flex: 1 1 0;
    flex-wrap: wrap;
    width: calc(100% - 42px);
    max-height: 78px;
    padding: 3px;
    overflow: hidden;
    gap: ${theme.space[8]};
`;

export const ChipWrapper = styled.div`
    max-width: 100%;
    cursor: pointer;
    pointer-events: all;
`;

export const ClickableChip = styled(Chip)`
    max-width: 100%;
    cursor: pointer;
    pointer-events: all;

    div:first-of-type {
        overflow: hidden;
        color: ${theme.colors['text-global-primary']};
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const Label = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${theme.space[0]} ${theme.space[16]};
    padding: ${theme.space[12]} ${theme.space[16]};
    border-bottom: 2px solid transparent;
    background-color: ${theme.colors['background-global-secondary']};
    gap: ${theme.space[8]};

    &:focus-within {
        border-bottom: 2px solid;
        border-radius: 4px 4px 0 0;
        border-color: ${theme.colors['borders-brand-primary']};
        outline: none;
    }

    @media ${theme.mediaQueries.lgMin} {
        margin: 0;
    }
`;

export const StyledBadge = styled(Badge)<{ badgePosition: { left: number; top: number } }>`
    position: absolute;
    top: ${({ badgePosition }): CSSProperties['top'] => `${badgePosition.top}px`};
    left: ${({ badgePosition }): CSSProperties['left'] => `${badgePosition.left}px`};
    align-self: end;
    width: fit-content;
    margin-bottom: ${theme.space[4]};
`;

export const LocationsChipListWithBadgeContainer = styled.div`
    position: relative;
`;

import type { JSX } from 'react';

import type { SelectableLocations } from '../../../types/locationSearchProps';
import { ContentContainer } from '../../Shared.theme';
import { ActionButtons } from './ActionButtons';
import { RecentLocations } from './RecentLocations';

export const InitialView = (props: SelectableLocations): JSX.Element => {
    return (
        <ContentContainer>
            <RecentLocations {...props} />
            <ActionButtons />
        </ContentContainer>
    );
};

import type { InvestmentEstateType } from '@type/search/filters/investmentEstateType';
import type { FilterOption } from '@type/search/filters/option';

import type { FieldAvailableMethods } from '../../../types';

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('investment_estate_type_click');
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (investmentEstateType: FilterOption<InvestmentEstateType> | null): void => {
        trackEvent('investment_estate_type_selected', {
            investment_estate_type: investmentEstateType ? investmentEstateType.value.toLowerCase() : null,
        });
    };

export const INVESTMENT_ESTATE_TYPE_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

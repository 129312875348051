import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import type { OwnerType } from '@type/ad/ownerType';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { FilterOption } from '@type/search/filters/option';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { Dispatch, SetStateAction } from 'react';

import type { FieldAvailableMethods } from '../../../types';

const getEventListeners =
    ({ getValues, setValue }: FieldAvailableMethods, options: unknown) =>
    (
        setDropdownOptions: Dispatch<SetStateAction<DropdownOption[]>>,
    ): {
        runEventListener: () => void;
        unbindEventListener: () => void;
    } => {
        const eventCallback = (): void => {
            const marketValue = getValues(SEARCH_FORM_UNIVERSAL_FIELD.market) ?? 'ALL';

            const currentMarketValue = typeof marketValue === 'string' ? marketValue : marketValue.value;

            const currentOptions = options as DropdownOption[];
            const ownerTypeOptionsForSecondaryVariant = currentOptions.filter(
                ({ value }) => value !== OWNER_TYPE.developer,
            );

            const validOwnerTypeOptions =
                currentMarketValue === 'SECONDARY' ? ownerTypeOptionsForSecondaryVariant : currentOptions;

            setDropdownOptions(validOwnerTypeOptions);

            const defaultOwnerTypeValue = (options as DropdownOption[]).find(({ value }) => value === OWNER_TYPE.all);

            setValue(SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect, defaultOwnerTypeValue);
        };

        const runEventListener = (): void => {
            listenForPlatformEvent('MARKET_CHANGED', eventCallback);
        };

        const unbindEventListener = (): void => {
            unmountPlatformEvent('MARKET_CHANGED', eventCallback);
        };

        return {
            runEventListener,
            unbindEventListener,
        };
    };

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_seller_type_click');
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (option: FilterOption<OwnerType>): void => {
        if (!option) {
            return;
        }

        const ownerTypeToTrack = option.value.toLowerCase();

        trackEvent('filter_seller_type_selected', {
            touch_point_button: 'filters',
            re_seller_type: ownerTypeToTrack,
        });
    };

export const OWNER_TYPE_SINGLE_SELECT_FIELD_PROPS = {
    getEventListeners,
    trackOnMenuOpen,
    trackOnSelect,
};

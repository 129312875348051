import type { LocationsObjects } from '@type/search/location/dataItems';
import { RANKING_TYPE_INPUT } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/suggestions';
import { runQueryAndSetSuggestions } from '@widgets/search/LocationPickerInput';
import { useContext, useEffect, useRef, useState } from 'react';
import type { CombinedError } from 'urql';
import { Context as UrqlContext } from 'urql';

import { useLocationSearch } from '../contexts/useLocationSearch';

interface Props {
    searchValue: string;
    onFetchError?: (error: CombinedError) => void;
    locationLevelLikeDistrictAndSubdistrict?: string[];
}

export const useDebounceFetchSuggestions = ({
    searchValue,
    onFetchError,
    locationLevelLikeDistrictAndSubdistrict,
}: Props): { suggestions: LocationsObjects[]; isPreloading: boolean } => {
    const urqlClient = useContext(UrqlContext);

    const {
        config: { autocompleteLevels },
    } = useLocationSearch();

    const [fetchResult, setFetchResult] = useState<{ requestedSearch: string; suggestions: LocationsObjects[] }>({
        requestedSearch: '',
        suggestions: [],
    });
    const [isPreloading, setIsPreloading] = useState(false);
    const timeoutRef = useRef<number>();

    useEffect(() => {
        const request = async (): Promise<void> => {
            const { error, data } = await runQueryAndSetSuggestions({
                urqlClient,
                searchValue,
                isLocationSearch: true,
                locationLevelLikeDistrictAndSubdistrict,
                levels: autocompleteLevels,
                rankingType: RANKING_TYPE_INPUT.blendedInfixLookupSuggest,
            });

            if (error) {
                onFetchError?.(error);
                setFetchResult({ requestedSearch: searchValue, suggestions: [] });
            }

            if (data?.autocomplete.locationsObjects) {
                const suggestions = data.autocomplete.locationsObjects;

                setFetchResult({ requestedSearch: searchValue, suggestions });
            }
            setIsPreloading(false);
        };

        const isAutocompleteTriggered = searchValue.length >= 2;

        // block infinite loop from use effect
        const isSameSearchPerformed = searchValue === fetchResult.requestedSearch;

        if (!isAutocompleteTriggered || isSameSearchPerformed) {
            return;
        }

        // clear existing timeout
        window.clearTimeout(timeoutRef.current);

        setIsPreloading(true);

        timeoutRef.current = window.setTimeout(request, 250);

        return (): void => window.clearTimeout(timeoutRef.current);
    }, [
        autocompleteLevels,
        fetchResult.requestedSearch,
        locationLevelLikeDistrictAndSubdistrict,
        onFetchError,
        searchValue,
        urqlClient,
    ]);

    return {
        suggestions: fetchResult.suggestions,
        isPreloading,
    };
};

import type { FieldMetadata } from '@type/search/fields';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';

type SearchFormUniversalFieldType = FieldMetadata['fieldType'];

// Note: `new Set<Something>` should be replaced with `satisfies` operator once its available.

export const FIELD_TYPES = {
    withLabelsToFind: new Set<SearchFormUniversalFieldType>(['select', 'multiSelect', 'multiCheckbox', 'radioGroup']),
    withPlaceholder: new Set<SearchFormUniversalFieldType>(['select', 'multiSelect']),
    withLegend: new Set<SearchFormUniversalFieldType>(['radioGroup', 'multiCheckbox', 'range']),
    withSuffix: new Set<SearchFormUniversalFieldType>(['range']),
} as const;

export const FIELD_NAMES = {
    toOmitOnMobile: new Set<SearchFormUniversalFieldName>(['submit']),
    withoutLabelsOnMobile: new Set<SearchFormUniversalFieldName>(['ownerTypeSingleSelect', 'market']),
    onMainAndExtendedForm: new Set<SearchFormUniversalFieldName>(['roomsNumber', 'freeFrom']),
} as const;

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Fieldset = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;
`;

export const Legend = styled.legend`
    margin-bottom: ${theme.space[4]};
    padding: 0;
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.space[18]};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space[16]} ${theme.space[32]};
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    gap: ${theme.space[16]};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[16]};
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        width: ${theme.sizes[24]};
        height: ${theme.sizes[24]};
        border: ${theme.borderWidths[2]} ${theme.borderStyles.solid} ${theme.colors['borders-brand-primary']};
        border-radius: 50%;
    }
`;

export const Radio = styled.input`
    position: absolute;
    width: ${theme.sizes[24]};
    height: ${theme.sizes[24]};
    margin: 0;
    padding: 0;
    border-radius: 50%;
    appearance: none;

    &:checked + label::before {
        border-width: ${theme.borderWidths[8]};
    }

    &:focus-visible {
        outline: ${theme.sizes[4]} solid ${theme.colors['background-brand-chat-highlight']};
    }
`;

import type { LocationsObjects } from '@type/search/location/dataItems';
import { useCallback, useEffect, useState } from 'react';

import type { LocationSearchConfig } from '../contexts/types';
import { uniqueLocationsFilter } from '../helpers/uniqueLocationsFilter';
import { useRecentLocations } from './useRecentLocations';

export interface UseLocationsReturnValue {
    selectedLocations: LocationsObjects[];
    /** override selectedLocations */
    selectLocations: (newSelectedLocation: LocationsObjects[]) => void;
    /** add next locations to selectedLocations */
    selectNextLocations: (nextSelectedLocation: LocationsObjects[]) => LocationsObjects[];
    unselectLocationsById: (ids: string[]) => LocationsObjects[];
    unselectAllLocations: () => void;
    temporarySuggestions: LocationsObjects[];
    selectTemporarySuggestions: (newTemporarySelected: LocationsObjects[]) => void;
    selectTemporarySuggestion: (nextTemporarySelected: LocationsObjects[]) => LocationsObjects[];
    unselectTemporarySuggestion: (ids: string[]) => LocationsObjects[];
    clearTemporarySuggestion: () => void;
}

export const DEFAULT_USE_LOCATIONS = {
    selectedLocations: [],
    selectLocations: (): void => undefined,
    selectNextLocations: (): LocationsObjects[] => [],
    unselectLocationsById: (): LocationsObjects[] => [],
    unselectAllLocations: (): void => undefined,
    temporarySuggestions: [],
    selectTemporarySuggestions: (): void => undefined,
    selectTemporarySuggestion: (): LocationsObjects[] => [],
    unselectTemporarySuggestion: (): LocationsObjects[] => [],
    clearTemporarySuggestion: (): void => undefined,
};

export const useLocations = ({
    initial,
    config,
}: {
    initial?: LocationsObjects[] | null;
    config: LocationSearchConfig;
}): UseLocationsReturnValue => {
    const [selectedLocations, setSelectedLocations] = useState<LocationsObjects[]>(initial ?? []);
    const [temporarySuggestions, setTemporarySuggestions] = useState<LocationsObjects[]>(selectedLocations);

    const selectLocations = (newSelectedLocations: LocationsObjects[]): void => {
        setSelectedLocations(newSelectedLocations.filter(uniqueLocationsFilter));
    };

    const selectTemporarySuggestions = (newSelectedLocations: LocationsObjects[]): void => {
        setTemporarySuggestions(newSelectedLocations.filter(uniqueLocationsFilter));
    };

    const selectTemporarySuggestion = (suggestions: LocationsObjects[]): LocationsObjects[] => {
        const nextTemporaryLocations = [...temporarySuggestions, ...suggestions].filter(uniqueLocationsFilter);
        setTemporarySuggestions(nextTemporaryLocations);

        return nextTemporaryLocations;
    };

    const unselectTemporarySuggestion = (ids: string[]): LocationsObjects[] => {
        const nextTemporaryLocations = temporarySuggestions.filter((item) => !ids.includes(item.id));
        setTemporarySuggestions(nextTemporaryLocations);

        return nextTemporaryLocations;
    };

    const clearTemporarySuggestion = (): void => {
        setTemporarySuggestions([]);
    };

    const selectNextLocations = useCallback(
        (newSelectedLocations: LocationsObjects[]): LocationsObjects[] => {
            const nextSelectedLocations = [...selectedLocations, ...newSelectedLocations].filter(uniqueLocationsFilter);
            setSelectedLocations(nextSelectedLocations);

            return nextSelectedLocations;
        },
        [selectedLocations],
    );

    const unselectLocationsById = useCallback(
        (ids: string[]): LocationsObjects[] => {
            const nextSelectedLocations = selectedLocations.filter((location) => !ids.includes(location.id));
            setSelectedLocations(nextSelectedLocations);

            return nextSelectedLocations;
        },
        [selectedLocations],
    );

    const unselectAllLocations = (): void => {
        setSelectedLocations([]);
    };

    const { saveNextRecentLocations } = useRecentLocations({ config });

    useEffect(() => {
        if (initial) {
            saveNextRecentLocations(initial);
        }
    }, [initial, saveNextRecentLocations]);

    return {
        selectedLocations,
        selectLocations,
        selectNextLocations,
        unselectLocationsById,
        unselectAllLocations,
        temporarySuggestions,
        selectTemporarySuggestions,
        selectTemporarySuggestion,
        unselectTemporarySuggestion,
        clearTemporarySuggestion,
    };
};

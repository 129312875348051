import type { FieldAvailableMethods } from '../../../types';

const trackOnChange =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: boolean): void => {
        trackEvent('filter_exclusive_selected', {
            exclusive: value,
            touch_point_button: 'filters',
        });
    };

export const IS_EXCLUSIVE_OFFER_FIELD_PROPS = {
    trackOnChange,
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { checkShouldDisplayCityView } from '../../helpers/checkShouldDisplayCityView';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { LocationInput } from '../LocationInput';
import { MobileConfirm } from '../MobileConfirm';
import { MobileContainer } from '../MobileContainer';
import { InitialView } from './InitialView';
import { SuggestionsList } from './SuggestionsList';

export const MobileSuggestions = ({ setDropdownState }: FlyoutProps<unknown>): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const {
        searchValue,
        setSearchValue,
        changeLocationSearchView,
        locations: {
            selectedLocations,
            selectLocations,
            temporarySuggestions,
            selectTemporarySuggestions,
            clearTemporarySuggestion,
        },
        config: {
            tracking: { touchPointPage },
            multiple,
        },
    } = useLocationSearch();

    const { getTrackingSharedValue } = useGetTrackingSharedValues();
    const isSingleLocationSelected = temporarySuggestions.length === 1;
    const shouldDisplaySuggestionsList = searchValue || isSingleLocationSelected;
    const touchPointButton = shouldDisplaySuggestionsList ? 'autosuggest_locations' : 'location_picker_main';

    const onLocationClick: SelectableLocations['onLocationClick'] = useCallback(
        (suggestion: LocationsObjects, { touchPointButton, locationListPosition, locationParentId }): void => {
            trackEvent(
                'search_box_list_selection_click',
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton,
                    locationParentId,
                    locationListPosition,
                    locations: [suggestion],
                }),
            );

            setSearchValue('');

            const shouldDisplayCityView = checkShouldDisplayCityView(suggestion);

            if (shouldDisplayCityView && multiple) {
                changeLocationSearchView('city');
                selectTemporarySuggestions([suggestion]);

                return;
            }

            selectLocations([suggestion]);
            setDropdownState?.(false);
        },
        [
            changeLocationSearchView,
            getTrackingSharedValue,
            multiple,
            selectLocations,
            selectTemporarySuggestions,
            setDropdownState,
            setSearchValue,
            trackEvent,
        ],
    );

    const onBackClick = useCallback(() => {
        setDropdownState?.(false);
        selectTemporarySuggestions(selectedLocations);
        trackEvent('back_button_click', {
            event_type: 'click',
            touch_point_button: touchPointButton,
            touch_point_page: touchPointPage,
        });
    }, [selectTemporarySuggestions, selectedLocations, setDropdownState, touchPointButton, touchPointPage, trackEvent]);

    const onClearClick = useCallback(() => {
        clearTemporarySuggestion();
        setSearchValue('');
        trackEvent('clear_filters', {
            event_type: 'click',
            touch_point_button: touchPointButton,
            touch_point_page: touchPointPage,
        });
    }, [clearTemporarySuggestion, setSearchValue, touchPointButton, touchPointPage, trackEvent]);

    const onConfirmClick = (): void => {
        selectLocations(temporarySuggestions);
        setDropdownState?.(false);

        trackEvent(
            'apply_location',
            getTrackingSharedValue({
                eventType: 'click',
                touchPointButton,
                locations: temporarySuggestions,
            }),
        );
    };

    return (
        <MobileContainer
            title={t('frontend.search.location-search.mobile-title')}
            onBackClick={onBackClick}
            onClearClick={onClearClick}
        >
            <LocationInput selectedLocation={temporarySuggestions[0]} onClear={onClearClick} />
            {shouldDisplaySuggestionsList ? (
                <SuggestionsList
                    searchValue={searchValue}
                    onLocationClick={onLocationClick}
                    selectedLocations={selectedLocations}
                />
            ) : (
                <InitialView onLocationClick={onLocationClick} />
            )}
            <MobileConfirm text={t('frontend.search.city-view.confirm-button')} onClick={onConfirmClick} />
        </MobileContainer>
    );
};

import type { FieldsMetadata } from '@type/search/fields';
import type { DistanceRadiusType } from '@type/search/filters/distanceRadius';

const FALLBACK_OPTIONS: number[] = [];

// @FIXME: move SearchFormUniversal component inside widget/search and move this helper to /search/core/helpers
export const getDistanceRadiusOptions = (fieldsMetadata: FieldsMetadata): Record<DistanceRadiusType, number[]> => {
    const metadata = fieldsMetadata.distanceRadius.default;

    return {
        km: metadata.payload.options.DEFAULT.map(Number) || FALLBACK_OPTIONS,
        m: metadata.payload.options.METERS.map(Number) || FALLBACK_OPTIONS,
    };
};

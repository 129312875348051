import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX } from 'react';
import { useContext } from 'react';

import { DesktopCity } from './DesktopCity';
import { MobileCity } from './MobileCity';

export const CityView = (props: FlyoutProps<unknown>): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);

    if (isDesktop) {
        return <DesktopCity {...props} />;
    }

    return <MobileCity {...props} />;
};

import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { FilterOption as Option } from '@type/search/filters/option';
import type { JSX } from 'react';

import { useDropdownWithCheckboxes } from './hooks/useDropdownWithCheckboxes';
import {
    DropdownValueWrapper,
    IconWrapper,
    OptionListItem,
    OptionsList,
    OptionsListWrapper,
    Placeholder,
    StyledBadge,
    StyledCheckboxInput,
    StyledCheckboxLabel,
    ValueString,
    Wrapper,
} from './NexusDropdownWithCheckboxes.theme';

interface Props {
    id: string;
    name: string;
    options: ReadonlyArray<Option> & ReadonlyArray<DropdownOption>;
    cyButton?: string;
    cyCheckboxLabel?: string;
    cyLabel?: string;
    cyList?: string;
    placeholder?: string;
    title?: string;
    trackingOnDropdownExpanded?: () => void;
    trackOnSelect?: (value: string[]) => void;
}

export const NexusDropdownWithCheckboxes = ({
    id,
    name,
    options,
    cyButton = 'button',
    cyCheckboxLabel = 'checkboxLabel',
    cyLabel = 'label',
    cyList = 'list',
    placeholder,
    title,
    trackingOnDropdownExpanded = (): void => undefined,
    trackOnSelect = (): void => undefined,
}: Props): JSX.Element => {
    const { dropdownRef, fieldValue, isDropdownExpanded, pickedOptions, handleCheckboxChange, setIsDropdownExpanded } =
        useDropdownWithCheckboxes({ name, options, trackingOnDropdownExpanded, trackOnSelect });

    return (
        <Wrapper ref={dropdownRef} id={id} data-cy={cyButton}>
            <DropdownValueWrapper
                role="combobox"
                aria-haspopup="listbox"
                isExpanded={isDropdownExpanded}
                onClick={(): void => setIsDropdownExpanded((state) => !state)}
                data-cy={cyLabel}
            >
                {pickedOptions ? (
                    <>
                        <ValueString>
                            {title ? `${title}: ` : null}
                            {String(pickedOptions)}
                        </ValueString>
                        <StyledBadge size="normal" value={fieldValue.length ?? 0} />
                    </>
                ) : (
                    <Placeholder>{placeholder}</Placeholder>
                )}

                <IconWrapper isExpanded={isDropdownExpanded}>
                    <ChevronDown />
                </IconWrapper>
            </DropdownValueWrapper>

            {isDropdownExpanded ? (
                <OptionsListWrapper>
                    <OptionsList data-cy={cyList}>
                        {options.map((item) => {
                            const itemIdentifier = `dropdown-checkbox-${name}-${item.value}`;

                            return (
                                <OptionListItem key={itemIdentifier} role="menuitem">
                                    <StyledCheckboxInput
                                        id={itemIdentifier}
                                        value={item.value}
                                        onChange={handleCheckboxChange}
                                        checked={fieldValue?.includes(item.value) ?? false}
                                    />
                                    <StyledCheckboxLabel
                                        data-cy={`${cyCheckboxLabel}-${item.value}`}
                                        htmlFor={itemIdentifier}
                                    >
                                        {item.label}
                                    </StyledCheckboxLabel>
                                </OptionListItem>
                            );
                        })}
                    </OptionsList>
                </OptionsListWrapper>
            ) : null}
        </Wrapper>
    );
};

import type { TransactionVariant } from '@type/search/transactionVariant';
import { TRANSACTION_VARIANT } from '@type/search/transactionVariant';

const TRANSACTION_VARIANT_ID: Record<TransactionVariant, number> = {
    [TRANSACTION_VARIANT.flatSell]: 101,
    [TRANSACTION_VARIANT.flatRent]: 102,
    [TRANSACTION_VARIANT.studioFlatSell]: 101,
    [TRANSACTION_VARIANT.studioFlatRent]: 102,
    [TRANSACTION_VARIANT.houseSell]: 201,
    [TRANSACTION_VARIANT.houseRent]: 202,
    [TRANSACTION_VARIANT.roomRent]: 302,
    [TRANSACTION_VARIANT.terrainSell]: 401,
    [TRANSACTION_VARIANT.terrainRent]: 402,
    [TRANSACTION_VARIANT.commercialPropertySell]: 501,
    [TRANSACTION_VARIANT.commercialPropertyRent]: 502,
    [TRANSACTION_VARIANT.hallSell]: 601,
    [TRANSACTION_VARIANT.hallRent]: 602,
    [TRANSACTION_VARIANT.garageSell]: 701,
    [TRANSACTION_VARIANT.garageRent]: 702,
    [TRANSACTION_VARIANT.investment]: 11,
};

export const getTransactionVariantId = (variant: TransactionVariant): number => {
    return TRANSACTION_VARIANT_ID[variant];
};

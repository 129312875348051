import type { JSX } from 'react';

import { useRadioGroupField } from './hooks/useRadioGroupField';
import { Fieldset, Label, Legend, Radio, Wrapper } from './RadioGroupField.theme';

interface Option {
    label: string;
    value: string;
}

interface Props {
    name: string;
    options: Option[];
    defaultValue: Option | undefined;
    legend: string;
    inputId: string;
    dataCy: string;
    trackOnSelect: (option: { value: string }) => void;
}

export const RadioGroupField = ({
    name,
    options,
    defaultValue: defaultOption,
    legend,
    inputId,
    dataCy,
    trackOnSelect,
}: Props): JSX.Element => {
    const { currentValue, register } = useRadioGroupField({
        name,
        trackOnSelect,
    });

    return (
        <Fieldset data-cy={dataCy}>
            <Legend>{legend}</Legend>
            <Wrapper>
                {options.map(({ label, value }) => {
                    const id = `${inputId}-${value}`;

                    let isDefaultChecked;

                    if (value === currentValue) {
                        isDefaultChecked = true;
                    }

                    if (currentValue === undefined) {
                        const isDefaultOption = value === defaultOption?.value;
                        isDefaultChecked = isDefaultOption;
                    }

                    if (currentValue === undefined && defaultOption === undefined) {
                        const isFirstOption = value === options[0].value;
                        isDefaultChecked = isFirstOption;
                    }

                    return (
                        <div key={id}>
                            <Radio
                                {...register(name)}
                                type="radio"
                                id={id}
                                value={value}
                                defaultChecked={isDefaultChecked}
                            />
                            <Label htmlFor={id}>{label}</Label>
                        </div>
                    );
                })}
            </Wrapper>
        </Fieldset>
    );
};

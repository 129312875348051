import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { MultiSelectProps } from '@nexus/lib-react/dist/core/MultiSelect';
import { MultiSelect } from '@nexus/lib-react/dist/core/MultiSelect';
import type { OptionsCollection } from '@nexus/lib-react/dist/core/MultiSelect/useSelectionState';
import type { JSX } from 'react';

export const SingleValueSelect = ({
    value,
    onChange,
    setDropdownState,
}: MultiSelectProps & FlyoutProps<OptionsCollection>): JSX.Element => {
    const customHandler: MultiSelectProps['onChange'] = (value): void => {
        onChange?.(value);
        setDropdownState?.(false);
    };

    return <MultiSelect value={value} onChange={customHandler} selectionMode="unique" />;
};

import type { FieldAvailableMethods } from '../../../types';

const trackOnChange =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: boolean): void => {
        trackEvent('filter_3d_view_selected', {
            with_3d_view: value,
            touch_point_button: 'filters',
        });
    };

export const HAS_WALKAROUND_FIELD_PROPS = {
    trackOnChange,
};

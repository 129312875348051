import { dispatchPlatformEvent } from '@lib/events';
import type { Market } from '@type/search/filters/market';
import type { FilterOption } from '@type/search/filters/option';

import type { FieldAvailableMethods } from '../../../types';

const onValueChange = () => {
    return (): void => {
        dispatchPlatformEvent('MARKET_CHANGED', {});
    };
};

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_market_click', {
            touch_point_button: 'filters',
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (option: FilterOption<Market>): void => {
        if (!option) {
            return;
        }

        const marketToTrack = option.value.toLowerCase();

        trackEvent('filter_market_selected', {
            touch_point_button: 'filters',
            market: marketToTrack,
        });
    };

export const MARKET_FIELD_PROPS = {
    onValueChange,
    trackOnMenuOpen,
    trackOnSelect,
};

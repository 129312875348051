import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P5 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    right: 0;
    align-items: center;
    align-self: flex-end;
    padding: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        position: relative;
    }
`;

export const Image = styled.img`
    width: 59.5px;
    min-width: 59.5px;
    height: 18px;
    min-height: 18px;
`;

export const Label = styled(P5)`
    padding: ${theme.space[0]} ${theme.space[4]};
`;

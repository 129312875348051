import type { DaysSinceCreated } from '@type/search/daysSinceCreated';
import type { FilterOption } from '@type/search/filters/option';

import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_ad_inserted_since_click', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (option: FilterOption<DaysSinceCreated>): void => {
        if (!option) {
            return;
        }

        trackEvent('filter_ad_inserted_since_selected', {
            ad_inserted_since: option.value,
            ...SHARED_EVENT_PROPS,
        });
    };

export const DAYS_SINCE_CREATED_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

import { useOnWindowResize } from '@lib/utils/useOnWindowResize';
import type { JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { LocationChip } from './LocationChip';
import { LocationsChipListContainer, LocationsChipListWithBadgeContainer, StyledBadge } from './LocationsInput.theme';
import type { LocationsInputProps } from './types';

const FLEX_GAP = 8;

const INITIAL_BADGE_DATA = {
    value: 0,
    css: {
        left: 0,
        top: 0,
    },
};
const BadgePluxPrefix = (): JSX.Element => <>+</>;

export const LocationsChipList = ({
    selectedLocations,
    onUnselectLocation,
}: LocationsInputProps): JSX.Element | null => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [badgeData, setBadgeData] = useState<{ value: number; css: { left: number; top: number } }>(
        INITIAL_BADGE_DATA,
    );

    const onResize = useCallback(() => {
        // no chips loaded in dom yet
        if (!containerRef.current || containerRef.current.children.length === 0) {
            setBadgeData(INITIAL_BADGE_DATA);

            return;
        }

        // calculate max visible position and check how many chips are visible
        const containerRect = containerRef.current.getBoundingClientRect();

        const visibleChips = [...containerRef.current.children].filter((element) => {
            const rect = element.getBoundingClientRect();
            const isChipInContainerBoundaries =
                element instanceof HTMLDivElement && rect.top - containerRect.top < containerRect.height;

            return isChipInContainerBoundaries;
        });

        if (visibleChips.length === 0) {
            setBadgeData(INITIAL_BADGE_DATA);

            return;
        }

        // set badge position right after last visible position (margin on container will make sure it won't overlap outside of input)
        const counterChips = selectedLocations.length - visibleChips.length;
        const lastVisibleChipRect = visibleChips[visibleChips.length - 1]?.getBoundingClientRect();
        if (lastVisibleChipRect) {
            setBadgeData({
                value: counterChips,
                css: {
                    // (chip left position - container left position)[due to position absolute calculation] + element width + flex gap
                    left: lastVisibleChipRect.left - containerRect.left + lastVisibleChipRect.width + FLEX_GAP,
                    top: lastVisibleChipRect.height + lastVisibleChipRect.height / 2, // always on second row, get height of one row + half
                },
            });
        }
    }, [selectedLocations]);

    useOnWindowResize(onResize);

    useEffect(() => {
        const menuBar = containerRef.current?.closest("div[role='menubar']"); // react to popper style changes, first render is full width...
        if (menuBar) {
            const observer = new MutationObserver(onResize);
            observer.observe(menuBar, { attributes: true });

            return (): void => observer.disconnect();
        }
    }, [onResize]);

    if (selectedLocations.length === 0) {
        return null;
    }

    return (
        <LocationsChipListWithBadgeContainer>
            <LocationsChipListContainer ref={containerRef}>
                {selectedLocations.map((selectedLocation) => (
                    <LocationChip
                        key={selectedLocation.id}
                        locationObject={selectedLocation}
                        onClick={(): void => {
                            onUnselectLocation(selectedLocation.id);
                        }}
                    />
                ))}
            </LocationsChipListContainer>
            {badgeData.value > 0 ? (
                <StyledBadge
                    value={badgeData.value}
                    prefixIcon={BadgePluxPrefix}
                    badgePosition={badgeData.css}
                    role="status"
                />
            ) : null}
        </LocationsChipListWithBadgeContainer>
    );
};

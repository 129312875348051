import type { DistanceRadiusType } from '@type/search/filters/distanceRadius';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { InitialSelectedProps } from '../types/locationSearchProps';

export interface UseDistanceRadiusReturnValue {
    distanceRadiusType: DistanceRadiusType;
    distanceRadiusDefaultValue: number;
    availableDistanceRadiusOptions: number[];
    setDistanceRadiusType: Dispatch<SetStateAction<DistanceRadiusType>>;
    isDistanceRadiusVisible: boolean;
    selectedDistanceRadius: number | null;
    selectDistanceRadius: (distanceRadius: number | null) => void;
}

export const DEFAULT_USE_DISTANCE_RADIUS: UseDistanceRadiusReturnValue = {
    distanceRadiusType: 'km',
    distanceRadiusDefaultValue: 0,
    availableDistanceRadiusOptions: [],
    setDistanceRadiusType: (): void => undefined,
    isDistanceRadiusVisible: false,
    selectedDistanceRadius: null,
    selectDistanceRadius: (): void => undefined,
};

export interface UseDistanceRadiusProps {
    initial?: InitialSelectedProps;
    distanceRadiusOptions?: Record<DistanceRadiusType, number[]>;
    distanceRadiusDefaultValues?: Record<DistanceRadiusType, number>;
    defaultInitialDistanceRadiusType?: DistanceRadiusType;
}

const EMPTY_ARRAY: number[] = [];

export const useDistanceRadius = ({
    initial,
    distanceRadiusOptions,
    distanceRadiusDefaultValues,
    defaultInitialDistanceRadiusType = 'km',
}: UseDistanceRadiusProps): UseDistanceRadiusReturnValue => {
    const initialDistanceRadiusType = initial?.googleSuggestion ? 'm' : defaultInitialDistanceRadiusType;
    const initialDistanceRadius =
        initial?.distanceRadius &&
        distanceRadiusOptions &&
        distanceRadiusOptions[initialDistanceRadiusType].includes(initial?.distanceRadius)
            ? initial.distanceRadius
            : null;
    const [selectedDistanceRadius, setSelectedDistanceRadius] = useState<number | null>(initialDistanceRadius);
    const [distanceRadiusType, setDistanceRadiusType] = useState<DistanceRadiusType>(initialDistanceRadiusType);

    const availableDistanceRadiusOptions = distanceRadiusOptions?.[distanceRadiusType] ?? EMPTY_ARRAY;
    const isDistanceRadiusVisible = availableDistanceRadiusOptions.length > 0;

    const selectDistanceRadius = useCallback(
        (newDistanceRadius: number | null) => {
            if (!newDistanceRadius) {
                setSelectedDistanceRadius(null);

                return;
            }

            if (distanceRadiusOptions && distanceRadiusOptions[distanceRadiusType].includes(newDistanceRadius)) {
                setSelectedDistanceRadius(newDistanceRadius);
            }
        },
        [distanceRadiusOptions, distanceRadiusType],
    );

    const distanceRadiusDefaultValue = distanceRadiusDefaultValues?.[distanceRadiusType] ?? 0;

    const value = useMemo(
        () => ({
            distanceRadiusType,
            distanceRadiusDefaultValue,
            availableDistanceRadiusOptions,
            setDistanceRadiusType,
            isDistanceRadiusVisible,
            selectedDistanceRadius,
            selectDistanceRadius,
        }),
        [
            availableDistanceRadiusOptions,
            distanceRadiusDefaultValue,
            distanceRadiusType,
            isDistanceRadiusVisible,
            selectDistanceRadius,
            selectedDistanceRadius,
        ],
    );

    return value;
};

import type { FreeFrom } from '@type/search/filters/freeFrom';
import type { FilterOption } from '@type/search/filters/option';

import type { FieldAvailableMethods } from '../../../types';

/* eslint-disable @typescript-eslint/naming-convention -- SearchForm uses transaction variants as uppercase. */
const FREE_FROM_VALUE = {
    NOW: 'from_now',
    THIRTY_DAYS: '30',
    NINETY_DAYS: '90',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('free_from_click', {
            touch_point_page: 'listing',
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (freeFrom: FilterOption<FreeFrom> | null): void => {
        trackEvent('free_from_selected', {
            free_from: freeFrom ? FREE_FROM_VALUE[freeFrom.value] : null,
            touch_point_page: 'listing',
        });
    };

export const FREE_FROM_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX } from 'react';

import { buildSuggestionCaption } from '../../helpers/buildSuggestionCaption';
import type { SelectableLocations } from '../../types/locationSearchProps';
import {
    ContentContainer,
    StyledCheckboxInput,
    StyledListContainer,
    StyledListItem,
    StyledListItemSubtitle,
    StyledListItemTitle,
    StyledListItemWrapper,
} from '../Shared.theme';
import { CityTreeRow } from './CityTreeRow';

export const CityWithChildrenList = ({
    selectedLocations,
    onLocationClick,
    cityTree,
    cityTreeListDataCy,
}: {
    selectedLocations: LocationsObjects[];
    cityTree: LocationsObjects;
    cityTreeListDataCy?: string;
} & SelectableLocations): JSX.Element => {
    const [t] = useTranslations();

    const caption = buildSuggestionCaption(t, cityTree, { displayOnlyDetailedLevelCaption: false });

    const isChecked = selectedLocations.some(({ id }) => id === cityTree.id);

    return (
        <ContentContainer>
            <StyledListContainer data-cy={cityTreeListDataCy}>
                <StyledListItemWrapper
                    onClick={(): void =>
                        onLocationClick(cityTree, {
                            touchPointButton: 'multi_autosuggest_locations',
                            locationListPosition: 0,
                        })
                    }
                >
                    <StyledCheckboxInput checked={isChecked} />
                    <StyledListItem aria-selected={isChecked}>
                        <StyledListItemTitle>
                            {cityTree.name}, {t('frontend.search.detailed-location.whole-city')}
                        </StyledListItemTitle>
                        <StyledListItemSubtitle>{caption}</StyledListItemSubtitle>
                    </StyledListItem>
                </StyledListItemWrapper>
                {cityTree?.children?.map((option, index) => {
                    const isSelected = selectedLocations.some(({ id }) => id === option.id);

                    return (
                        <CityTreeRow
                            key={option.id}
                            option={option}
                            isSelected={isSelected}
                            onLocationClick={onLocationClick}
                            selectedLocations={selectedLocations}
                            sourceRowIndex={index}
                            sourceRowId={option.id}
                        />
                    );
                })}
            </StyledListContainer>
        </ContentContainer>
    );
};

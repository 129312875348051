import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { DropdownOption } from '@type/search/dropdownOption';

interface Props {
    t: Translator;
    name: string;
    prefix?: string;
    options: string[];
}

// @TODO: EURE-27021 remove all the other dynamic keys too
const getTranslationKey = (name: string, value: string): string => {
    if (name === 'transaction') {
        switch (value) {
            case 'RENT': {
                return 'frontend.global.search.transaction-rent';
            }
            case 'SELL': {
                return 'frontend.global.search.transaction-sell';
            }
            default: {
                return 'frontend.global.search.transaction-all';
            }
        }
    }

    if (name === 'market') {
        switch (value) {
            case 'PRIMARY': {
                return 'frontend.global.search.market-primary';
            }
            case 'SECONDARY': {
                return 'frontend.global.search.market-secondary';
            }
            default: {
                return 'frontend.global.search.market-all';
            }
        }
    }

    return `frontend.search.form-${name}-${value}-fieldvalue`.toLowerCase();
};

export const buildOptionsFromValues = ({ t, name, options }: Props): ReadonlyArray<Required<DropdownOption>> =>
    options.map((value) => ({
        label: t(getTranslationKey(name, value)),
        value,
    }));

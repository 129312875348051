import { assertGraphqlResponse } from '@lib/graphql/assertGraphqlResponse';
import { runBaseLocationsQuery } from '@widgets/search/LocationPicker/components/LocationPickerTree/hooks/useLocationPickerService/helpers/runBaseLocationsQuery';
import { runSubLocationsQuery } from '@widgets/search/LocationPicker/components/LocationPickerTree/hooks/useLocationPickerService/helpers/runSubLocationsQuery';
import type { GqlDomainIdNode } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/locations';
import { useCallback, useContext, useState } from 'react';
import { Context } from 'urql';

import type { LocationListNode } from '../types/list';

type HandleSuccessResponse = (
    fetchedNodes: GqlDomainIdNode[],
    rootId: string | null,
    loadedLocations: LocationListNode[],
) => void;
interface UseExecuteLocationTreeFetchReturnValue {
    executeFetching: (
        rootId: string | null,
        loadedLocations: LocationListNode[],
        handleSuccessResponse: HandleSuccessResponse,
    ) => Promise<void>;
    isFetching: boolean;
    isError: boolean;
}

export const useExecuteLocationTreeFetch = (): UseExecuteLocationTreeFetchReturnValue => {
    const urqlClient = useContext(Context);

    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);

    const executeFetching = useCallback(
        async (
            rootId: string | null,
            loadedLocations: LocationListNode[],
            handleSuccessResponse: HandleSuccessResponse,
        ): Promise<void> => {
            setIsFetching(true);
            const query = rootId ? runSubLocationsQuery(urqlClient, { id: rootId }) : runBaseLocationsQuery(urqlClient);
            const { data, error, operation } = await query;

            const locationTreeData = assertGraphqlResponse({
                data: data?.locationTree,
                expectedTypenames: ['FoundLocationTree'],
                graphqlError: error,
                logErrorPrefix: '[useExecuteLocationTreeFetch][LocationTreeQuery]',
                operation,
            });

            if (locationTreeData) {
                handleSuccessResponse(locationTreeData.locationTreeObjects, rootId ?? null, loadedLocations);
            } else {
                setIsError(true);
            }
            setIsFetching(false);
        },
        [urqlClient],
    );

    return {
        executeFetching,
        isFetching,
        isError,
    };
};

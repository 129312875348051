import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { Dispatch, RefObject, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

interface Props {
    onClickOutside: () => void;
    onDropdownExpanded: () => void;
}

export const useIsDropdownExpanded = ({
    onClickOutside,
    onDropdownExpanded,
}: Props): [RefObject<HTMLDivElement> | null, boolean, Dispatch<SetStateAction<boolean>>] => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

    useClickOutside(
        dropdownRef,
        () => {
            setIsDropdownExpanded(false);
            onClickOutside();
        },
        isDropdownExpanded,
    );

    useEffect(() => {
        if (!isDropdownExpanded) {
            return;
        }

        onDropdownExpanded();
    }, [isDropdownExpanded, onDropdownExpanded]);

    return [dropdownRef, isDropdownExpanded, setIsDropdownExpanded];
};

import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnFocus =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_keyword_click', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (value: string): void => {
        trackEvent('filter_keyword_selected', {
            keyword: value,
            ...SHARED_EVENT_PROPS,
        });
    };

export const DESCRIPTION_FIELD_PROPS = {
    trackOnFocus,
    trackOnBlur,
};

/**
 * Fields names reflects names used by backend to select proper field.
 */
export const FIELD_TYPE = {
    checkbox: 'checkbox',
    input: 'input',
    multiCheckbox: 'multiCheckbox',
    multiSelect: 'multiSelect',
    range: 'range',
    select: 'select',
    radioGroup: 'radioGroup',
} as const;

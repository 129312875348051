import { AUTOCOMPLETE_QUERY } from '@domains/shared/graphql/queries/AutocompleteQuery';
import type { LocationLevels } from '@type/location/locationLevels';
import type {
    RankingTypeInput,
    SuggestionsQueryVariables,
    SuggestionsResponseStructure,
} from '@widgets/search/LocationPicker/components/LocationPickerTree/types/suggestions';
import { RANKING_TYPE_INPUT } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/suggestions';
import type { Client, CombinedError, OperationResult } from 'urql';

export const runQueryAndSetSuggestions = async ({
    urqlClient,
    searchValue,
    rankingType = RANKING_TYPE_INPUT.default,
    isLocationSearch = false,
    locationLevelLikeDistrictAndSubdistrict,
    levels,
}: {
    urqlClient: Client;
    searchValue: string;
    rankingType?: RankingTypeInput;
    isLocationSearch?: boolean;
    locationLevelLikeDistrictAndSubdistrict?: string[];
    levels?: LocationLevels;
}): Promise<{
    error: CombinedError | undefined;
    data: SuggestionsResponseStructure | undefined;
}> => {
    const { error, data }: OperationResult<SuggestionsResponseStructure> = await urqlClient
        .query<SuggestionsResponseStructure, SuggestionsQueryVariables>(AUTOCOMPLETE_QUERY, {
            query: searchValue,
            ranking: { type: rankingType },
            isLocationSearch,
            locationLevelLikeDistrictAndSubdistrict,
            levels,
        })
        .toPromise();

    return { error, data };
};

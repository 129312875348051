import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { Input } from '@domains/shared/components/Input/Input';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { theme } from '@lib/styles/themes/nexus';
import { SEARCH_WIDGET_THEME } from '@widgets/search/theme/[[[SITECODE]]]';
import type { FC } from 'react';

export const Container = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    height: 38px;
    padding: ${theme.space[2]} ${theme.space[18]};
    cursor: pointer;

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.space[2]} ${theme.space[8]};
    }
`;

export const NexusContainer = styled.div`
    display: flex;
    gap: ${theme.space[8]};
    position: relative;
    align-items: center;
    height: ${theme.sizes[48]};
    margin: 0 16px;
    padding: ${theme.space[12]} ${theme.space[16]};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-secondary']};
    cursor: pointer;

    @media ${theme.mediaQueries.lgMin} {
        margin: 0;
        border-radius: 0;
        background-color: transparent;
    }

    svg {
        flex-shrink: 0;
    }
`;

const SHARED_LOCATION_INPUT_STYLES = css`
    transition: none;
    border: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
`;

export const LocationInput = styled(Input)<{ shouldUseFullScreenVersionOnMobile: boolean }>`
    position: relative;
    z-index: 1;
    height: 100%;
    padding-left: ${theme.space[4]};

    ${({ shouldUseFullScreenVersionOnMobile }): SerializedStyles | undefined => {
        return shouldUseFullScreenVersionOnMobile
            ? css`
                  @media ${theme.mediaQueries.lgMin} {
                      ${SHARED_LOCATION_INPUT_STYLES}
                  }
              `
            : css`
                  ${SHARED_LOCATION_INPUT_STYLES}
                  font-size: ${theme.space[14]};
              `;
    }}
`;

export const NexusLocationInput = styled(Input)`
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 0;
    transition: none;
    border: none;
    background-color: transparent;
    box-shadow: none;

    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.fontSizes[16]};
    }
`;

const SpinnerStyledIcon = styled(Icon)`
    position: absolute;
    z-index: 1;
    right: ${theme.space[32]};
    color: ${SEARCH_WIDGET_THEME.searchForm.locationPicker.color};

    @media ${theme.mediaQueries.lgMin} {
        position: inherit;
        right: ${theme.space[0]};
    }
`;

export const SpinnerIcon: FC = () => <SpinnerStyledIcon size="1x" icon={faSpinner} width={14} height={14} spin />;

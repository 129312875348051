import { buildOptionsFromValues } from '@domains/shared/helpers/buildOptionsFromValues';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { DropdownOption } from '@type/search/dropdownOption';
import { checkIsOption } from '@type/search/dropdownOption';
import type { DynamicTransactionVariant } from '@type/search/dynamicTransactionVariant';
import type { FieldMetadata } from '@type/search/fields';
import type { Estate } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';

import type { DefaultValueOptionVariant } from '../const/defaultValueOptionVariant';
import { DEFAULT_VALUE_OPTION_VARIANT } from '../const/defaultValueOptionVariant';

export const createFieldOptions = (
    fieldName: SearchFormUniversalFieldName,
    metadata: FieldMetadata,
    estate: Estate | DefaultValueOptionVariant,
    transaction: Transaction | undefined,
    formDefaultValues: Record<string, unknown> | undefined,
    t: Translator,
): {
    values: readonly Required<DropdownOption<string>>[];
    defaultValue: Required<DropdownOption<string>> | undefined;
} | null => {
    if (!('options' in metadata.payload)) {
        return null;
    }

    const configOptions = metadata.payload.options;
    let options = configOptions[estate] || configOptions[DEFAULT_VALUE_OPTION_VARIANT];

    if (estate !== 'DEFAULT' && transaction) {
        const transactionVariant: DynamicTransactionVariant = `${estate}_${transaction}`;

        // If it is available, use more specific values
        if (configOptions[transactionVariant]) {
            options = configOptions[transactionVariant];
        }
    }

    const componentInitialValue = formDefaultValues?.[fieldName] || null;
    const componentValue = checkIsOption(componentInitialValue) ? componentInitialValue.value : componentInitialValue;

    const defaultOptions = options ? [...options] : [];

    // Setup labels for every value in payload.options
    const values = buildOptionsFromValues({
        t,
        name: fieldName,
        options: defaultOptions,
    });

    const defaultValue = values.find(({ value }) => String(value) === String(componentValue));

    return {
        values,
        defaultValue,
    };
};

import { COUNT_ADS_QUERY, EXPECTED_COUNT_ADS_TYPENAMES } from '@domains/shared/graphql/queries/CountAdsQuery';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import { getFilters } from '@widgets/search/SearchFormSubmitButton/helpers/getFilters';
import type { Filters } from '@widgets/search/SearchFormSubmitButton/types/searchTypes';
import { useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import useDebounce from 'react-use/lib/useDebounce';
import { useQuery } from 'urql';

export const useSearchFormSubmit = ({
    sellerId,
    fieldsMetadataExperimentsVariants,
}: {
    sellerId?: string;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}): {
    isDefaultState: boolean;
    fetching: boolean;
    isSubmitting: boolean;
    title: string;
} => {
    const [t] = useTranslations();
    const { getValues, control } = useFormContext();
    const { isDirty, isSubmitting } = useFormState();
    const [apiFormValues, setApiFormValues] = useState<Filters | null>(null);
    const formValues = useWatch({ control });

    const isQueryPaused = apiFormValues === null;
    const [{ data, fetching, error, operation }] = useQuery({
        query: COUNT_ADS_QUERY,
        ...(apiFormValues ? { variables: apiFormValues } : {}),
        pause: isQueryPaused,
        requestPolicy: 'network-only',
    });

    useDebounce(
        () => {
            if (isDirty) {
                setApiFormValues(
                    getFilters({
                        values: getValues(),
                        sellerId,
                        fieldsMetadataExperimentsVariants,
                    }),
                );
            }
        },
        200,
        [fieldsMetadataExperimentsVariants, formValues, isDirty],
    );

    const countAds = useAssertGraphqlResponse({
        fetching,
        pause: isQueryPaused,
        data: data?.countAds,
        expectedTypenames: EXPECTED_COUNT_ADS_TYPENAMES,
        graphqlError: error,
        logErrorPrefix: '[SearchFormSubmitButton]',
        operation,
    });

    const isDefaultState = !isDirty || !countAds || isQueryPaused;
    const title = isDefaultState
        ? t('frontend.search.form.search')
        : `${t('frontend.search.form.results')} ${countAds.count}`;

    return { isDefaultState, fetching, isSubmitting, title };
};

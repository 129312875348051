import { getRoomNumberByStringValue } from '@domains/shared/helpers/getRoomNumberByTheString';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';

import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('nb_rooms_click', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (roomsNumberValues: RoomsNumber[] | null, allCheckedOptions?: RoomsNumber[]): void => {
        const options = allCheckedOptions ?? roomsNumberValues;

        trackEvent('nb_rooms_selected', {
            nb_rooms: options?.map(getRoomNumberByStringValue) ?? [],
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnClear =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_nb_rooms_cleared', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnChipClear =
    ({ trackEvent }: FieldAvailableMethods) =>
    ({ name, value }: { name: string; value: string }): void => {
        trackEvent('filter_chip_cleared', {
            ...SHARED_EVENT_PROPS,
            filter_name: name,
            filter_value: getRoomNumberByStringValue(value as RoomsNumber),
        });
    };

export const ROOMS_NUMBER_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
    trackOnClear,
    trackOnChipClear,
};

import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import { Container, Image, Label } from './GoogleLicence.theme';

export const GoogleLicence: FC = () => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();

    return (
        <Container>
            <Label>{t('frontend.search.form.location.data-provided-by')}</Label>
            <Image src={`${staticAssetsPrefix}/images/search/googleOnWhite.png`} alt="google-logo" />
        </Container>
    );
};

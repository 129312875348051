import type { GoogleSuggestion } from '@type/search/google';
import { useState } from 'react';

export interface UseGoogleSuggestionReturnValue {
    selectedGoogleSuggestion: GoogleSuggestion | null;
    selectGoogleSuggestion: (newGoogleSuggestion: GoogleSuggestion) => void;
    unselectGoogleSuggestion: () => void;
}

export const DEFAULT_USE_GOOGLE_SUGGESTION = {
    selectedGoogleSuggestion: null,
    selectGoogleSuggestion: (): void => undefined,
    unselectGoogleSuggestion: (): void => undefined,
};

export const useGoogleSuggestion = ({
    initial,
}: {
    initial?: GoogleSuggestion | null;
}): UseGoogleSuggestionReturnValue => {
    const [selectedGoogleSuggestion, setSelectedGoogleSuggestion] = useState<GoogleSuggestion | null>(initial ?? null);

    const selectGoogleSuggestion = (newGoogleSuggestion: GoogleSuggestion): void => {
        setSelectedGoogleSuggestion(newGoogleSuggestion);
    };

    const unselectGoogleSuggestion = (): void => {
        setSelectedGoogleSuggestion(null);
    };

    return {
        selectedGoogleSuggestion,
        selectGoogleSuggestion,
        unselectGoogleSuggestion,
    };
};

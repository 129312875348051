import { getNonEmptyLowerCasedValues } from '../../../../core/helpers/getNonEmptyLowerCasedValues';
import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_floor_click', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (filterFloor: string[]): void => {
        trackEvent('filter_floor_selected', {
            floor: getNonEmptyLowerCasedValues(filterFloor),
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnClear =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('filter_floor_cleared', {
            ...SHARED_EVENT_PROPS,
        });
    };

const trackOnChipClear =
    ({ trackEvent }: FieldAvailableMethods) =>
    ({ name, value }: { name: string; value: string }): void => {
        trackEvent('filter_chip_cleared', {
            ...SHARED_EVENT_PROPS,
            filter_name: name,
            filter_value: value.toLocaleLowerCase(),
        });
    };

export const FLOOR_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
    trackOnClear,
    trackOnChipClear,
};

import { getTransactionVariant } from '@domains/shared/helpers/getTransactionVariant';
import { getTransactionVariantId } from '@domains/shared/helpers/getTransactionVariantId';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { FilterOption } from '@type/search/filters/option';
import { TRANSACTION } from '@type/search/filters/transaction';

import type { FieldAvailableMethods } from '../../../types';

const SHARED_EVENT_PROPS = {
    touch_point_button: 'filters',
};

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods, _: unknown, trackingData?: Record<string, unknown>) =>
    (): void => {
        trackEvent('category_click', { ...SHARED_EVENT_PROPS, ...trackingData });
    };

const trackOnSelect =
    ({ trackEvent, getValues }: FieldAvailableMethods, _: unknown, trackingData?: Record<string, unknown>) =>
    (option: FilterOption<Estate> | null): void => {
        if (!option) {
            return;
        }

        const transaction = getValues('transaction');
        const estateToTrack = option.value;
        const isRoom = estateToTrack === ESTATE.room;
        const transactionVariant = getTransactionVariant({
            estate: estateToTrack,
            transaction: transaction.value,
        });

        trackEvent('category_selected', {
            ...SHARED_EVENT_PROPS,
            cat_l1_id: getTransactionVariantId(transactionVariant),
            cat_l1_name: option.label,
            business: isRoom ? TRANSACTION.rent.toLowerCase() : transaction.value.toLowerCase(),
            ...trackingData,
        });
    };

export const ESTATE_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const ConfirmContainer = styled.div`
    position: sticky;
    z-index: ${theme.zIndices.sticky};
    bottom: calc(0px + env(safe-area-inset-bottom, 0px));
    padding: ${theme.space[16]} ${theme.space[12]} ${theme.space[12]} ${theme.space[12]};
    border-top: ${theme.borderWidths['1']} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    background: ${theme.colors['background-global-primary']};
`;

export const ConfirmButton = styled(Button)`
    width: 100%;
    min-height: 48px;
`;

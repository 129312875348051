import { gql } from '@urql/core';

interface GetGoogleSessionTokenResult {
    googleSessionToken: string;
}

export const GET_NEW_GOOGLE_SESSION_TOKEN = gql<GetGoogleSessionTokenResult>`
    query getGoogleSessionToken {
        googleSessionToken
    }
`;

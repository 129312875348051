import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import Tick from '@nexus/lib-react/dist/core/Icon/icons/default/Tick';
import type { CSSProperties } from 'react';

interface Props {
    shouldDisplayRoomChips: boolean;
}

const getListFlexProperties = ({ shouldDisplayRoomChips }: Props): SerializedStyles => {
    if (shouldDisplayRoomChips) {
        return css`
            flex-wrap: nowrap;
            gap: ${theme.space[8]} ${theme.space[8]};

            & > div {
                flex: 1;
            }
        `;
    }

    return css`
        flex-wrap: wrap;
        gap: ${theme.space[8]} ${theme.space[8]};
    `;
};

export const Fieldset = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;
`;

export const Legend = styled.legend`
    margin-bottom: ${theme.space[4]};
    padding: 0;
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.space[18]};
`;

export const ListItem = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTick = styled(Tick)`
    position: absolute;
    transition: 250ms opacity ease-in;
    opacity: 0;
`;

export const Label = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${theme.sizes[48]};
    transition: 250ms padding ease-in;
    border: ${theme.borderWidths[1]} ${theme.borderStyles.solid} transparent;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-secondary']};
    color: ${theme.colors['text-global-primary']};
    cursor: pointer;
    user-select: none;

    @media ${theme.mediaQueries.lgMin} {
        min-width: ${theme.sizes[56]};
    }
`;

export const ChipCheckbox = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;

    &:checked + label {
        border-color: ${theme.colors['background-brand-secondary']};
        background-color: ${theme.colors['background-global-primary']};

        ${StyledTick} {
            opacity: 1;
        }
    }

    &:focus-visible + label {
        outline: ${theme.sizes[4]} ${theme.borderStyles.solid} ${theme.colors['background-brand-alternative-light']};
    }
`;

export const List = styled.div<Props>`
    display: flex;

    ${({ shouldDisplayRoomChips }): SerializedStyles => getListFlexProperties({ shouldDisplayRoomChips })}

    ${ChipCheckbox} {
        &:checked + label {
            padding-right: ${({ shouldDisplayRoomChips }): CSSProperties['paddingRight'] =>
                shouldDisplayRoomChips ? theme.space[16] : theme.space[40]};
        }
    }

    ${Label} {
        padding: 0
            ${({ shouldDisplayRoomChips }): CSSProperties['padding'] =>
                shouldDisplayRoomChips ? '0' : theme.space[16]};

        ${StyledTick} {
            right: ${({ shouldDisplayRoomChips }): CSSProperties['right'] =>
                shouldDisplayRoomChips ? theme.space[8] : theme.space[16]};
        }
    }
`;

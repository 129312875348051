import type { GoogleSuggestion } from '@type/search/google';
import type { LocationsObjects } from '@type/search/location/dataItems';

import type { LocationSearchConfig, LocationSearchDataCy } from '../contexts/types';
import type { UseDistanceRadiusProps } from '../hooks/useDistanceRadius';
import type { UseSyncValues } from './syncValues';

export interface InitialSelectedProps {
    locations?: LocationsObjects[] | null;
    googleSuggestion?: GoogleSuggestion | null;
    geometry?: string | null;
    distanceRadius?: number | null;
    searchValue?: string;
}

export interface SelectedProps {
    locations: LocationsObjects[];
    googleSuggestion: GoogleSuggestion | null;
    geometry: string | null;
    distanceRadius: number | null;
}

export interface LocationSearchProps
    extends Pick<UseDistanceRadiusProps, 'distanceRadiusOptions' | 'distanceRadiusDefaultValues'> {
    initial?: InitialSelectedProps;
    config?: Partial<LocationSearchConfig>;
    dataCy?: Partial<LocationSearchDataCy>;
    useSyncValues?: UseSyncValues;
    className?: string;
}

interface SelectableLocationConfig {
    touchPointButton: string;
    locationListPosition: number;
    locationParentId?: string;
}
export interface SelectableLocations {
    onLocationClick: (suggestion: LocationsObjects, config: SelectableLocationConfig) => void;
}

export const LOCATION_PICKER_DEFAULT_VIEW = {
    default: 'default',
    street: 'street',
} as const;

export type LocationSearchDefaultView = ObjectValues<typeof LOCATION_PICKER_DEFAULT_VIEW>;

export const LOCATION_PICKER_VIEW = {
    ...LOCATION_PICKER_DEFAULT_VIEW,
    list: 'list',
    city: 'city',
} as const;

export type LocationSearchView = ObjectValues<typeof LOCATION_PICKER_VIEW>;

import { getNumberValue } from '../../../../core/helpers/getNumberValue';
import type { FieldAvailableMethods } from '../../../types';

const trackOnBlur =
    ({ trackEvent }: FieldAvailableMethods) =>
    (fromConstructionYear: string | null, toConstructionYear: string | null, trackedInput: 'min' | 'max'): void => {
        const touchPointButton = trackedInput === 'min' ? 'from_construction_year' : 'to_construction_year';

        trackEvent('construction_year_selected', {
            from_construction_year: getNumberValue(fromConstructionYear),
            to_construction_year: getNumberValue(toConstructionYear),
            touch_point_button: touchPointButton,
        });
    };

export const BUILD_YEAR_FIELD_PROPS = {
    trackOnBlur,
};

import { useTracking } from '@lib/tracking/useTracking';
import { Dropdown } from '@nexus/lib-react/dist/core/Dropdown';
import type { OptionsCollection } from '@nexus/lib-react/dist/core/MultiSelect/useSelectionState';
import type { JSX } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { SingleValueSelect } from './SingleValueSelect';

export const DistanceRadiusDropdown = (): JSX.Element => {
    const { trackEvent } = useTracking();
    const {
        distanceRadius: {
            distanceRadiusType,
            distanceRadiusDefaultValue,
            selectDistanceRadius,
            selectedDistanceRadius,
            availableDistanceRadiusOptions,
        },
        dataCy,
    } = useLocationSearch();

    const referenceValue = selectedDistanceRadius
        ? `+ ${selectedDistanceRadius} ${distanceRadiusType}`
        : `+ ${distanceRadiusDefaultValue} ${distanceRadiusType}`;

    const options: OptionsCollection = availableDistanceRadiusOptions.map((distanceRadiusOption) => ({
        name: `${distanceRadiusOption}`,
        value: distanceRadiusOption === selectedDistanceRadius,
        label: `+ ${distanceRadiusOption} ${distanceRadiusType}`,
        type: 'selectable',
    }));

    const handleChange = (nextOptions: OptionsCollection): void => {
        const selectedOption = nextOptions.find((option) => 'value' in option && option.value);
        const nextDistanceRadius = selectedOption?.name ? Number(selectedOption.name) : null;
        selectDistanceRadius(nextDistanceRadius);

        trackEvent('filter_distance_selected', {
            touch_point_button: 'filters',
            distance_filt: nextDistanceRadius,
        });
    };

    const onToggle = (isOpen?: boolean): void => {
        if (isOpen) {
            trackEvent('filter_distance_click', { touch_point_button: 'filters' });
        }
    };

    // dropdown does not pass any custom props into referenceComponent
    return (
        <div data-cy={dataCy?.distanceRadius}>
            <Dropdown
                onDropdownToggle={onToggle}
                referenceComponentValue={referenceValue}
                onChange={handleChange}
                value={options}
                flyOutComponent={SingleValueSelect}
                data-cy={dataCy?.distanceRadius}
            />
        </div>
    );
};

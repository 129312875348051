import { getFieldMetadataSetVariantKey } from '@domains/shared/helpers/getFieldMetadataSetVariantKey';
import type { FieldMetadata, FieldsMetadata } from '@type/search/fields';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';

export const getMetadata = (
    fieldName: SearchFormUniversalFieldName,
    fieldsMetadata: FieldsMetadata,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): FieldMetadata | null => {
    const fieldMetadataSets = fieldsMetadata[fieldName];

    if (!fieldMetadataSets) {
        return null;
    }

    const fieldMetadataSetVariantKey = getFieldMetadataSetVariantKey({
        fieldMetadataSets,
        fieldsMetadataExperimentsVariants,
    });

    return fieldMetadataSets[fieldMetadataSetVariantKey] || fieldMetadataSets.default;
};

import { assertGraphqlResponse } from '@lib/graphql/assertGraphqlResponse';
import { GOOGLE_SEARCH_TYPE, type GoogleSuggestion } from '@type/search/google';
import { useContext, useState } from 'react';
import { useDebounce } from 'react-use';
import { Context } from 'urql';

import { GET_GOOGLE_SUGGESTIONS } from '../graphql/queries/getGoogleSuggestions';
import { useGoogleSessionToken } from './useGoogleSessionToken';

export const useGoogleSearch = ({
    searchValue,
    selectedGoogleSuggestion,
    minLength = 2,
    googleSuggestionTypes,
}: {
    searchValue: string;
    selectedGoogleSuggestion?: GoogleSuggestion;
    minLength?: number;
    googleSuggestionTypes: string[];
}): {
    isFetching: boolean;
    isSuccess: boolean;
    suggestions: GoogleSuggestion[];
} => {
    const urqlClient = useContext(Context);
    const [queryState, setQueryState] = useState({ isFetching: false, isSuccess: false });
    const [suggestions, setSuggestions] = useState<GoogleSuggestion[]>([]);

    const { getGoogleSessionToken } = useGoogleSessionToken();

    useDebounce(
        async () => {
            const isSameSearchValue = !!selectedGoogleSuggestion?.name && selectedGoogleSuggestion.name === searchValue;

            if (isSameSearchValue) {
                return;
            }

            const isAutocompleteTriggered = searchValue.length >= minLength;

            if (!isAutocompleteTriggered) {
                setSuggestions([]);

                return;
            }

            setQueryState({ isFetching: true, isSuccess: false });

            const sessionToken = await getGoogleSessionToken();
            if (!sessionToken) {
                // do not send requests without session token!
                setSuggestions([]);
                setQueryState({ isFetching: false, isSuccess: false });

                return;
            }
            const { data, error, operation } = await urqlClient
                .query(GET_GOOGLE_SUGGESTIONS, {
                    searchValue,
                    types: googleSuggestionTypes,
                    sessionToken,
                })
                .toPromise();
            const googleAutocomplete = assertGraphqlResponse({
                data: data?.googleAutocomplete,
                expectedTypenames: ['FoundGooglePlacesPredictions'],
                graphqlError: error,
                logErrorPrefix: '[useGoogleSearch][googleAutocomplete]',
                operation,
            });

            if (googleAutocomplete) {
                setSuggestions(
                    googleAutocomplete.googlePlacePredictions.map((prediction) => ({
                        placeId: prediction.placeID,
                        name: `${prediction.description.split(', ')[0]}, ${prediction.description.split(', ')[1]}`,
                        type: GOOGLE_SEARCH_TYPE.street,
                    })),
                );
            }
            setQueryState({ isFetching: false, isSuccess: !!googleAutocomplete });
        },
        450,
        [searchValue, setQueryState],
    );

    return {
        ...queryState,
        suggestions,
    };
};

import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import type { GqlDomainIdNode } from '@widgets/search/LocationPicker/components/LocationPickerTree/types/locations';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from 'urql';

import { STORAGE_KEY_LOCATIONS } from '../constants';
import { checkIsNodesWithVersion } from '../helpers/checkIsNodesWithVersion';
import { createLocationListNodesFromGqlDomainIdNodes } from '../helpers/createLocationListNodesFromGqlDomainIdNodes';
import { fillRootSublocationsWithLocationListNodes } from '../helpers/fillRootSublocationsWithLocationListNodes';
import { findLocationListNodeById } from '../helpers/findLocationListNodeById';
import type { LocationListNode } from '../types/list';
import { useExecuteLocationTreeFetch } from './useExecuteLocationTreeFetch';
import { useLocationDatasetVersion } from './useLocationDatasetVersion';

interface UseLocationListReturnValue {
    allLocations: LocationListNode[];
    locations: LocationListNode[];
    isFetching: boolean;
    isError: boolean;
    parentLocation: LocationListNode | null;
    previousParent: LocationListNode | null;
    setParentLocation: Dispatch<SetStateAction<LocationListNode | null>>;
}

export const useLocationList = (): UseLocationListReturnValue => {
    const urqlClient = useContext(Context);
    const [getCachedLocations, setCachedLocations] = useLocalStorageCache(STORAGE_KEY_LOCATIONS);
    const [allLocations, setAllLocations] = useState<LocationListNode[]>([]);
    const [parentLocation, setParentLocation] = useState<LocationListNode | null>(null);

    const {
        locationDatasetVersion,
        isFetching: isFetchingVersion,
        isError: isErrorVersion,
    } = useLocationDatasetVersion();

    const handleSuccessResponse = useCallback(
        (fetchedNodes: GqlDomainIdNode[], rootId: string | null, loadedLocations: LocationListNode[]) => {
            const nodes = fetchedNodes;
            const nodesWithParentIds = createLocationListNodesFromGqlDomainIdNodes(nodes, rootId);
            const parsedNodes = rootId
                ? fillRootSublocationsWithLocationListNodes(nodesWithParentIds, loadedLocations, rootId)
                : nodesWithParentIds;
            setAllLocations(parsedNodes);
            setCachedLocations(parsedNodes);
        },
        [setCachedLocations],
    );

    const { executeFetching, isFetching, isError } = useExecuteLocationTreeFetch();

    const isFetchingAnything = isFetching || isFetchingVersion;
    const isErrorAnything = isError || isErrorVersion;
    const isFetchingOrError = isFetchingAnything || isErrorAnything;
    const isRootsFetched = allLocations.length > 0;

    useEffect(() => {
        // run once if nothing is fetched and not failed
        if (isFetchingOrError || isRootsFetched) {
            return;
        }

        // check cache data and use cache if version is up to date
        const cacheData = getCachedLocations();
        const nodesWithVersion = checkIsNodesWithVersion(cacheData) ? cacheData : undefined;
        const isSameDatasetVersion = locationDatasetVersion === nodesWithVersion?.version;
        if (isSameDatasetVersion && nodesWithVersion?.nodes) {
            setAllLocations(nodesWithVersion.nodes);

            return;
        }
        executeFetching(null, allLocations, handleSuccessResponse);
    }, [
        executeFetching,
        getCachedLocations,
        isFetchingOrError,
        isRootsFetched,
        locationDatasetVersion,
        setCachedLocations,
        allLocations,
        urqlClient,
        handleSuccessResponse,
    ]);

    useEffect(() => {
        // avoid executing same query mutiple times
        // can be used only when roots fetched
        // can be used only if any parent is selected
        if (isFetchingOrError || !isRootsFetched || !parentLocation) {
            return;
        }

        // is root location filled with children already? If yes do not fetch again as data is ready to use
        if (!allLocations.some((location) => location.id === parentLocation.id && location.sublocations.length === 0)) {
            return;
        }

        executeFetching(parentLocation.id, allLocations, handleSuccessResponse);
    }, [
        executeFetching,
        handleSuccessResponse,
        isFetchingOrError,
        isRootsFetched,
        allLocations,
        parentLocation,
        setCachedLocations,
        urqlClient,
    ]);

    const mappedLocations = parentLocation
        ? findLocationListNodeById(parentLocation.id, allLocations)?.sublocations ?? []
        : allLocations;

    const previousParent = parentLocation?.parentId
        ? findLocationListNodeById(parentLocation.parentId, allLocations)
        : null;

    return {
        allLocations,
        locations: mappedLocations,
        setParentLocation,
        parentLocation,
        previousParent,
        isFetching: isFetchingAnything,
        isError: isErrorAnything,
    };
};

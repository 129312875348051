import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledSkeleton = styled(Skeleton)`
    background-color: ${theme.colors['background-brand-tertiary']} !important;
    background-image: linear-gradient(
        90deg,
        ${theme.colors['background-brand-tertiary']},
        ${theme.colors['background-global-secondary']},
        ${theme.colors['background-brand-tertiary']}
    ) !important;
`;

export const ListItemSkeletonWrapper = styled.li`
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.ul`
    padding: 0 ${theme.space[8]};

    ${ListItemSkeletonWrapper} {
        margin: 0 0 ${theme.space[16]} 0;
    }

    ${ListItemSkeletonWrapper}:last-child {
        margin: 0;
    }
`;

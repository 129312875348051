export const GOOGLE_SEARCH_TYPE = {
    street: 'street',
    poi: 'poi',
} as const;

export type GoogleSearchType = ObjectValues<typeof GOOGLE_SEARCH_TYPE>;

export interface GoogleSuggestion {
    placeId: string;
    name: string;
    type: GoogleSearchType;
}

export type InputOnGoogleSuggestionsLoad = (params: { searchValue: string; suggestions: GoogleSuggestion[] }) => void;

export const removeAttributes = (input: string, allowedAttributes: string[] = []): string => {
    const tagRegExp = /<([a-z][\da-z]{0,500})[^>]{0,5000}?(\/?)>/gi; // only opening tag

    if (allowedAttributes.length === 0) {
        return input.replace(tagRegExp, (_, tagName, maybeClosingSlash) => `<${tagName}${maybeClosingSlash}>`);
    }

    return input.replace(tagRegExp, (tagWithAttributes, tagName, maybeClosingSlash) => {
        const regexp = new RegExp(`(${allowedAttributes.join('|')})=".+?"`, 'gi');
        const attributes = tagWithAttributes.match(regexp);

        if (attributes) {
            return `<${tagName} ${attributes.join(' ')}${maybeClosingSlash}>`;
        }

        return `<${tagName}${maybeClosingSlash}>`;
    });
};

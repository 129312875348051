import { REGEX_SAFE_STRING } from '@domains/shared/consts/regularExpressions';
import { pipe } from '@domains/shared/helpers/pipe';
import { removeAttributes } from '@domains/shared/helpers/removeAttributes';
import { stripTags } from '@domains/shared/helpers/stripTags';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Button } from '@nexus/lib-react/dist/core/Button';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import ChevronUp from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronUp';
import type { LocationsObjects } from '@type/search/location/dataItems';
import { type AriaRole, type JSX, useMemo } from 'react';

import { useChildrenSuggestion } from '../../hooks/useChildrenSuggestion';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { ButtonContainer, StyledListItem, StyledListItemSubtitle, StyledListItemWrapper } from '../Shared.theme';
import { StyledSuggestionRowListItemTitle } from './SuggestionsView.theme';
/**
 * Here we want to display suggestions and additionally its children for specific level (cities, districts & subdistricts).
 * The administrative division in Poland, Romania and Portugal is different.
 * In Poland, location with a `detailedLevel=city` has "districts" and `detailedLevel=district` has "residential".
 * In Romania, location with a `detailedLevel="county_capital" | "municipality" | "town"` has "districts"
 * and there are no subdistricts in Romania.
 * Bucharest is separate case - for `detailedLevel=county` we want to display its children which are `sectors`,
 * while Bucharest sector's children list (`districts`) should be closed.
 * In Portugal, location with a `detailedLevel=council` has "parish" and `detailedLevel=parish` has "neighborhoods".
 **/

interface Props {
    searchValue: string;
    suggestion: LocationsObjects;
    isSelected: boolean;
    selectedLocations: LocationsObjects[];
    sourceSuggestionIndex: number;
    sourceSuggestionId?: string;
    nestingIndex?: number;
    role?: AriaRole;
}

export const SuggestionRow = ({
    searchValue,
    suggestion,
    onLocationClick,
    isSelected,
    selectedLocations,
    sourceSuggestionIndex,
    sourceSuggestionId,
    nestingIndex = 0,
    role,
}: Props & SelectableLocations): JSX.Element => {
    const [t] = useTranslations();

    const innerHtmlTitle = useMemo(() => {
        // Replace values not safe for regex before adding them into regex part
        const searchValueWords = searchValue.replace(REGEX_SAFE_STRING, '').split(' ');

        const title = suggestion.name.replace(new RegExp(`${searchValueWords.join('|')}`, 'gi'), '<mark>$&</mark>');

        const stripNotAllowedTags = (input: string): string => {
            return stripTags(input, ['mark']);
        };
        const prepareTitle = pipe(stripNotAllowedTags, removeAttributes);

        return prepareTitle(title);
    }, [searchValue, suggestion.name]);

    const locationParentId = nestingIndex === 0 ? undefined : sourceSuggestionId;

    const { hasChildren, isOpen, isExpanded, data, fetching, onShowMoreClick, onOpenClick, onCloseClick, caption } =
        useChildrenSuggestion({ suggestion, nestingIndex, sourceSuggestionIndex, locationParentId });

    const checkIsLocationSelected = (locationId: string): boolean =>
        selectedLocations.some(({ id }) => id === locationId);

    return (
        <>
            <StyledListItemWrapper nestingIndex={nestingIndex}>
                <StyledListItem
                    aria-selected={isSelected}
                    isSelected={isSelected}
                    onClick={(): void =>
                        onLocationClick(suggestion, {
                            touchPointButton: 'autosuggest_locations',
                            locationListPosition: sourceSuggestionIndex,
                            locationParentId,
                        })
                    }
                    role={role}
                >
                    <StyledSuggestionRowListItemTitle dangerouslySetInnerHTML={{ __html: innerHtmlTitle }} />
                    <StyledListItemSubtitle>{caption}</StyledListItemSubtitle>
                </StyledListItem>

                {hasChildren && isOpen ? (
                    <Button variant="flat" aria-label={t('frontend.global.label.close')} onClick={onCloseClick}>
                        <ChevronUp />
                    </Button>
                ) : null}

                {hasChildren && !isOpen ? (
                    <Button variant="flat" aria-label={t('frontend.global.label.open')} onClick={onOpenClick}>
                        <ChevronDown />
                    </Button>
                ) : null}
            </StyledListItemWrapper>
            {isOpen && !isExpanded
                ? suggestion.children?.slice(0, 3).map((item) => {
                      const isSelected = checkIsLocationSelected(item.id);

                      return (
                          <SuggestionRow
                              key={item.id}
                              searchValue={searchValue}
                              onLocationClick={(s, c) => onLocationClick(s, c)}
                              aria-selected={isSelected}
                              isSelected={isSelected}
                              suggestion={item}
                              selectedLocations={selectedLocations}
                              nestingIndex={nestingIndex + 1}
                              sourceSuggestionIndex={sourceSuggestionIndex}
                              sourceSuggestionId={sourceSuggestionId}
                          />
                      );
                  })
                : null}
            {isOpen && !isExpanded && suggestion.children && suggestion.children?.length > 3 ? (
                <ButtonContainer>
                    <Button variant="tertiary" size="small" onClick={onShowMoreClick}>
                        {t('frontend.search.location-search.show-more')}
                    </Button>
                </ButtonContainer>
            ) : null}
            {fetching ? <LoadingSkeleton /> : null}
            {isOpen && isExpanded
                ? data?.locationDetails?.children?.map((item) => {
                      const isSelected = checkIsLocationSelected(item.id);

                      return (
                          <SuggestionRow
                              key={item.id}
                              searchValue={searchValue}
                              onLocationClick={(s, c) => onLocationClick(s, c)}
                              aria-selected={isSelected}
                              isSelected={isSelected}
                              suggestion={item}
                              selectedLocations={selectedLocations}
                              nestingIndex={nestingIndex + 1}
                              sourceSuggestionIndex={sourceSuggestionIndex}
                              sourceSuggestionId={sourceSuggestionId}
                          />
                      );
                  })
                : null}
        </>
    );
};

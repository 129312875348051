import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { withLoadingState } from '@nexus/lib-react/dist/core/Button';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { JSX, MouseEventHandler } from 'react';

import { useSearchFormSubmit } from './hooks/useSearchQuery';
import { FormButton, StyledNexusButton } from './SubmitButton.theme';

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    variant?: ButtonVariant;
    sellerId?: string;
    shouldUseNexusTheme?: boolean;
    onClick?: MouseEventHandler;
}

export const SubmitButton = ({
    variant = ButtonVariant.Action,
    onClick,
    sellerId,
    fieldsMetadataExperimentsVariants,
    shouldUseNexusTheme = false,
}: Props): JSX.Element => {
    const { isDefaultState, fetching, isSubmitting, title } = useSearchFormSubmit({
        sellerId,
        fieldsMetadataExperimentsVariants,
    });

    const dataCy = isDefaultState ? 'search.submit-form.default' : 'search.submit-form.results';

    if (shouldUseNexusTheme) {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- its HOC
        const LoadingButton = withLoadingState(StyledNexusButton);

        return (
            <LoadingButton
                prefixIcon={SearchIcon}
                variant="primary"
                dataAttributes={{
                    cy: dataCy,
                }}
                id="search-form-submit"
                type="submit"
                aria-busy={fetching}
                onClick={onClick}
                disabled={isSubmitting}
                loading={fetching}
            >
                {title}
            </LoadingButton>
        );
    }

    return (
        <FormButton
            data-cy={dataCy}
            id="search-form-submit"
            type="submit"
            variant={variant}
            aria-busy={fetching}
            onClick={onClick}
            disabled={isSubmitting}
        >
            {fetching ? <Icon icon={faSpinner} spin /> : title}
        </FormButton>
    );
};

import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Badge } from '@nexus/lib-react/dist/core/Badge';
import { Button } from '@nexus/lib-react/dist/core/Button';
import Close from '@nexus/lib-react/dist/core/Icon/icons/default/Close';
import type { JSX, MouseEventHandler } from 'react';
import { useContext } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { CloseContainer, SuffixWrapper } from './LocationReferenceInputSuffix.theme';

export interface LocationReferenceInputSuffixProps {
    onClear: MouseEventHandler;
    counterLength: number;
    multiple?: boolean;
}

export const LocationReferenceInputSuffix = ({
    onClear,
    counterLength,
    multiple,
}: LocationReferenceInputSuffixProps): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { searchValue, dataCy } = useLocationSearch();
    const shouldShowCounter = multiple && counterLength > 0;
    const shouldEnableClearButton = !!isDesktop && (!!searchValue || counterLength > 0);

    return (
        <SuffixWrapper>
            {shouldShowCounter ? <Badge value={counterLength} size="small" variant="primary" /> : null}
            {shouldEnableClearButton ? (
                <CloseContainer>
                    <Button
                        variant="flat"
                        onClick={onClear}
                        aria-label={t('frontend.shared.input-with-clear.clear-button')}
                        suffixIcon={Close}
                        data-cy={dataCy?.clearButton}
                    />
                </CloseContainer>
            ) : null}
        </SuffixWrapper>
    );
};

import type { LocationListNode } from '../types/list';
import { findLocationListNodeById } from './findLocationListNodeById';

export const getAllParentsForLocationListNode = (
    location: LocationListNode,
    allLocations: LocationListNode[],
    parents: LocationListNode[] = [],
): LocationListNode[] => {
    const parentId = location.parentId;
    if (parentId) {
        const parent = findLocationListNodeById(parentId, allLocations);
        if (parent) {
            return getAllParentsForLocationListNode(parent, allLocations, [...parents, parent]);
        }
    }

    return parents;
};

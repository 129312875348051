import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';

import { DEFAULT_USE_DISTANCE_RADIUS } from '../hooks/useDistanceRadius';
import { DEFAULT_USE_GOOGLE_SUGGESTION } from '../hooks/useGoogleSuggestion';
import { DEFAULT_USE_LOCATIONS } from '../hooks/useLocations';
import { LOCATION_PICKER_DEFAULT_VIEW, LOCATION_PICKER_VIEW } from '../types/locationSearchProps';

export const DEFAULT_LOCATION_PICKER_CONTEXT_RETURN_VALUE = {
    searchValue: '',
    setSearchValue: (): void => undefined,
    locations: DEFAULT_USE_LOCATIONS,
    googleSuggestion: DEFAULT_USE_GOOGLE_SUGGESTION,
    distanceRadius: DEFAULT_USE_DISTANCE_RADIUS,
    locationSearchView: LOCATION_PICKER_VIEW.default,
    changeLocationSearchView: (): void => undefined,
    resetState: (): void => undefined,
    config: {
        defaultView: LOCATION_PICKER_DEFAULT_VIEW.default,
        multiple: false,
        streetSearchConfig: {
            enabled: false,
            googleSuggestionTypes: [],
        },
        enableSearchFromList: false,
        recentSuggestions: {
            enabled: false,
            localStorageKey: '',
            locationsToRemember: 6,
        },
        tracking: {
            touchPointPage: null,
            entryInputTouchPointButton: null,
        },
        referenceInputId: 'location-search',
        referenceInputPrefix: SearchIcon,
    },
};

import type { LocationsObjects } from '@type/search/location/dataItems';

import { useLocationSearch } from '../contexts/useLocationSearch';

interface TrackingSharedValues {
    locations: Pick<LocationsObjects, 'id' | 'detailedLevel' | 'name'>[];
    eventType: string;
    touchPointButton?: string;
    locationParentId?: string;
    locationListPosition?: number;
    additionalLocationId?: string;
}
export const useGetTrackingSharedValues = (): {
    getTrackingSharedValue: (extraData: TrackingSharedValues) => Record<string, unknown> | null;
} => {
    const {
        searchValue,
        config: {
            tracking: { touchPointPage },
        },
    } = useLocationSearch();
    const getTrackingSharedValue = ({
        locations,
        eventType,
        touchPointButton,
        locationParentId,
        locationListPosition,
        additionalLocationId,
    }: TrackingSharedValues): Record<string, unknown> | null => {
        return {
            event_type: eventType,
            touch_point_button: touchPointButton,
            ...(locationParentId ? { location_parent_id: locationParentId } : null),
            ...(locationListPosition || locationListPosition === 0
                ? { location_list_position: locationListPosition }
                : null),
            ...(additionalLocationId ? { additional_location_id: additionalLocationId } : null),
            touch_point_page: touchPointPage,
            location_query: searchValue || null,
            selected_locations_id: locations.map((s) => s.id),
            selected_locations_display_name: locations.map((s) => s.name),
            selected_locations_scope: locations.map((s) => s.detailedLevel),
        };
    };

    return { getTrackingSharedValue };
};

import type { RWD } from '@domains/shared/contexts/RWDContext';
import { GET_NON_GENERIC_TRANSLATION_KEY } from '@domains/shared/helpers/getNonGenericTranslationKey';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FieldsMetadata } from '@type/search/fields';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';
import { UNITS } from '@widgets/search/FieldsFactory/const/fieldsUnits';
import type { JSX } from 'react';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DefaultValueOptionVariant } from './const/defaultValueOptionVariant';
import { DEFAULT_VALUE_OPTION_VARIANT } from './const/defaultValueOptionVariant';
import { FIELD_NAMES, FIELD_TYPES } from './const/fieldInfo';
import { FieldLabel, FieldRow } from './FieldsFactory.theme';
import { checkShouldShowLabel } from './helpers/checkShouldShowLabel';
import { createCallbackProps } from './helpers/createCallbackProps';
import { createFieldOptions } from './helpers/createFieldOptions';
import { getMetadata } from './helpers/getMetadata';
import { COMPONENT_MAP } from './rendering/fields/collection';
import type { FieldAvailableMethods } from './types';

interface Props {
    estate: Estate | DefaultValueOptionVariant;
    fieldsMetadata: FieldsMetadata;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    keys: SearchFormUniversalFieldName[];
    formDefaultValues?: Record<string, unknown>;
    isDesktop?: RWD['isDesktop'];
    isExtendedForm?: boolean;
    shouldRenderLabels?: boolean;
    shouldSetMobileLabels?: boolean;
    shouldUseGridArea?: boolean;
    transaction?: Transaction;
    shouldSpaceSiblings?: boolean;
    shouldDisplayUnitInSuffix?: boolean;
    shouldOverflowFieldLabel?: boolean;
    trackingData?: Partial<Record<SearchFormUniversalFieldName, Record<string, unknown>>>;
}

const BaseFieldsFactory = ({
    estate = DEFAULT_VALUE_OPTION_VARIANT,
    fieldsMetadata,
    fieldsMetadataExperimentsVariants,
    keys = [],
    formDefaultValues,
    isDesktop = false,
    isExtendedForm = false,
    shouldRenderLabels = false,
    shouldSetMobileLabels = false,
    shouldUseGridArea = false,
    shouldSpaceSiblings = false,
    shouldDisplayUnitInSuffix = false,
    shouldOverflowFieldLabel = false,
    transaction,
    trackingData,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { getValues, setValue } = useFormContext();

    // trackEvent is used here as only a dummy function we can pass down to the dynamically rendered components
    const fieldAvailableMethods: FieldAvailableMethods = { getValues, trackEvent, setValue };

    return (
        <>
            {keys.map((fieldName) => {
                /**
                 * Omit some components on mobile version like submit button as it is displayed
                 * absolutely at the bottom of the form
                 */
                if (FIELD_NAMES.toOmitOnMobile.has(fieldName) && !isDesktop) {
                    return null;
                }

                const metadata = getMetadata(fieldName, fieldsMetadata, fieldsMetadataExperimentsVariants);

                if (!metadata) {
                    return null;
                }

                const Component = COMPONENT_MAP[metadata.fieldType];

                if (!Component) {
                    return null;
                }

                const unit = UNITS[fieldName];
                const label = t(GET_NON_GENERIC_TRANSLATION_KEY.label(fieldName));

                const props: Record<string, unknown> = {
                    name: fieldName,
                    inputId: fieldName,
                    instanceId: fieldName,
                    dataCy: `search-form--field--${fieldName}`,
                    trackingData: trackingData?.[fieldName],
                };

                // Here we are adding to props all properties that came from backend
                for (const [key, value] of Object.entries(metadata.payload)) {
                    props[key] = value;
                }

                if (FIELD_TYPES.withLabelsToFind.has(metadata.fieldType)) {
                    const fieldOptions = createFieldOptions(
                        fieldName,
                        metadata,
                        estate,
                        transaction,
                        formDefaultValues,
                        t,
                    );

                    if (!fieldOptions) {
                        return;
                    }

                    props.options = fieldOptions.values;
                    props.defaultValue = fieldOptions.defaultValue;
                }

                if (FIELD_TYPES.withPlaceholder.has(metadata.fieldType)) {
                    const isOnBothForms = FIELD_NAMES.onMainAndExtendedForm.has(fieldName);
                    const shouldSetCustomPlaceholder = isExtendedForm && isOnBothForms;

                    props.ariaLabel = label;
                    props.placeholder = shouldSetCustomPlaceholder
                        ? t(GET_NON_GENERIC_TRANSLATION_KEY.placeholderExtended(fieldName))
                        : t(GET_NON_GENERIC_TRANSLATION_KEY.placeholder(fieldName));
                }

                if (FIELD_TYPES.withLegend.has(metadata.fieldType)) {
                    props.legend = t(GET_NON_GENERIC_TRANSLATION_KEY.label(fieldName));
                }

                if (shouldDisplayUnitInSuffix && unit) {
                    props.suffix = unit;
                }

                const shouldShowLabel = checkShouldShowLabel({
                    fieldName,
                    metadata,
                    isDesktop,
                    isExtendedForm,
                    shouldSetMobileLabels,
                    shouldRenderLabels,
                });

                /**
                 * Note: This is really important to ACTUALLY CREATE new component inside this function
                 * I tried to refactor it and simply render `Component` in the `return` block.
                 * But after that, dropdown values were not updated correctly.
                 * Yeah, I know that it's against the standards, we shouldn't create components inside the component.
                 * As I said, I spoted it but then I discovered the above.
                 */
                const Filter = (): JSX.Element => (
                    <Component {...props} {...createCallbackProps(fieldName, props, fieldAvailableMethods)} />
                );

                const gridArea = shouldUseGridArea ? fieldName : undefined;

                return (
                    <FieldRow gridArea={gridArea} key={fieldName} shouldSpaceSiblings={shouldSpaceSiblings}>
                        {shouldShowLabel ? (
                            <FieldLabel htmlFor={fieldName} shouldOverflowFieldLabel={shouldOverflowFieldLabel}>
                                {label}
                            </FieldLabel>
                        ) : null}
                        <Filter />
                    </FieldRow>
                );
            })}
        </>
    );
};

export const FieldsFactory = memo(BaseFieldsFactory);

import { gql } from 'urql';

interface LocationsDatasetVersion {
    locationsDatasetVersion: {
        locations: string;
    };
}

export const GET_LOCATION_DATASET_VERSION = gql<LocationsDatasetVersion>`
    query {
        locationsDatasetVersion {
            ... on LocationsDatasetVersion {
                locations
            }
            ... on ErrorInternal {
                message
                code
            }
        }
    }
`;

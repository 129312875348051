import type { FieldAvailableMethods } from '../../../types';

const trackOnMenuOpen =
    ({ trackEvent }: FieldAvailableMethods) =>
    (): void => {
        trackEvent('nb_people_click', {
            touch_point_page: 'listing',
        });
    };

const trackOnSelect =
    ({ trackEvent }: FieldAvailableMethods) =>
    (eventName: string | null): void => {
        trackEvent('nb_people_selected', { nb_people: eventName, touch_point_page: 'listing' });
    };

export const PEOPLE_PER_ROOM_FIELD_PROPS = {
    trackOnMenuOpen,
    trackOnSelect,
};

import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import ListView from '@nexus/lib-react/dist/core/Icon/icons/default/ListView';
import SearchIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import { useLocationSearch } from '@widgets/search/LocationSearch/contexts/useLocationSearch';
import type { JSX } from 'react';

import { StyledListContainer, StyledListItem, StyledListItemWrapper } from '../../Shared.theme';

export const ActionButtons = (): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const {
        changeLocationSearchView,
        config: {
            streetSearchConfig: { enabled: shouldEnableStreetSearch },
            enableSearchFromList,
            tracking: { touchPointPage },
            additionalActions,
        },
    } = useLocationSearch();

    const onChooseFromListClick = (): void => {
        changeLocationSearchView('list');
        trackEvent('location_from_list_click', {
            event_type: 'click',
            touch_point_button: 'location_picker_main',
            touch_point_page: touchPointPage,
        });
    };

    const onStreetClick = (): void => {
        changeLocationSearchView('street');
        trackEvent('search_near_address_click', {
            event_type: 'click',
            touch_point_button: 'location_picker_main',
            touch_point_page: touchPointPage,
        });
    };

    return (
        <StyledListContainer role="list">
            {additionalActions?.map(({ text, icon, isHidden, onClick }) => {
                if (isHidden) {
                    return null;
                }

                return (
                    <StyledListItemWrapper key={text}>
                        <StyledListItem prefixIcon={icon} suffixIcon={ArrowRight} onClick={onClick} role="listitem">
                            {text}
                        </StyledListItem>
                    </StyledListItemWrapper>
                );
            })}

            {shouldEnableStreetSearch ? (
                <StyledListItemWrapper>
                    <StyledListItem
                        prefixIcon={SearchIcon}
                        suffixIcon={ArrowRight}
                        onClick={onStreetClick}
                        role="listitem"
                    >
                        {t('frontend.global.search.street-search-near-location')}
                    </StyledListItem>
                </StyledListItemWrapper>
            ) : null}
            {enableSearchFromList ? (
                <StyledListItemWrapper>
                    <StyledListItem
                        prefixIcon={ListView}
                        suffixIcon={ArrowRight}
                        onClick={onChooseFromListClick}
                        role="listitem"
                    >
                        {t('frontend.search.suggestions-view.choose-from-list')}
                    </StyledListItem>
                </StyledListItemWrapper>
            ) : null}
        </StyledListContainer>
    );
};

import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { JSX, PropsWithChildren } from 'react';
import { useRef } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { decideInitialLocationSearchView } from '../../helpers/decideInitialLocationSearchView';
import { Container } from './DesktopContainer.theme';

export const DesktopContainer = ({
    setDropdownState,
    children,
}: PropsWithChildren<FlyoutProps<unknown>>): JSX.Element => {
    const {
        setSearchValue,
        changeLocationSearchView,
        googleSuggestion: { selectedGoogleSuggestion },
        locations: { selectedLocations },
        config,
    } = useLocationSearch();
    const containeRef = useRef(null);
    useClickOutside(containeRef, () => {
        // reset view to expected one to show street or default on open
        const expectedLocationView = decideInitialLocationSearchView(
            { locations: selectedLocations, googleSuggestion: selectedGoogleSuggestion },
            config,
        );
        changeLocationSearchView(expectedLocationView);
        setSearchValue('');
        setDropdownState?.(false);
    });

    return <Container ref={containeRef}>{children}</Container>;
};

import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { toast } from '@domains/shared/components/Toast/toast';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX } from 'react';
import { useMemo } from 'react';

import { useDebounceFetchSuggestions } from '../../hooks/useDebounceFetchSuggestions';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { ContentContainer, StyledListContainer } from '../Shared.theme';
import { SuggestionRow } from './SuggestionRow';

export const SuggestionsList = ({
    selectedLocations,
    searchValue,
    onLocationClick,
}: {
    searchValue: string;
    selectedLocations: LocationsObjects[];
} & SelectableLocations): JSX.Element => {
    const [t] = useTranslations();
    const { locationLevelLikeDistrictAndSubdistrict } = SEARCH_CONFIG;

    const isSingleLocationSelected = selectedLocations.length === 1;
    const selectedSuggestionNameToSearch = isSingleLocationSelected ? selectedLocations[0].name : '';
    const suggestionsSearchValue = searchValue || selectedSuggestionNameToSearch;

    const { suggestions, isPreloading } = useDebounceFetchSuggestions({
        searchValue: suggestionsSearchValue,
        onFetchError: () => {
            toast.error(t('frontend.shared.toast.error'));
        },
        locationLevelLikeDistrictAndSubdistrict,
    });

    const sortedSuggestions = useMemo(() => {
        // we should not sort suggestions for search by phrase from user
        if (searchValue) {
            return suggestions;
        }

        // if selected suggestion exist in autocomplete result and it is not search by phrase from user we should move selected suggestion to first position
        return [...suggestions].sort((suggestion) =>
            selectedLocations.some((selectedLocation) => selectedLocation.id === suggestion.id) ? -1 : 0,
        );
    }, [searchValue, selectedLocations, suggestions]);

    return (
        <ContentContainer alwaysFullHeight>
            <StyledListContainer role="list">
                {isPreloading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        {sortedSuggestions.map((suggestion, index) => {
                            const isSelected = selectedLocations.some(({ id }) => id === suggestion.id);

                            return (
                                <SuggestionRow
                                    key={suggestion.id}
                                    searchValue={suggestionsSearchValue}
                                    suggestion={suggestion}
                                    isSelected={isSelected}
                                    onLocationClick={onLocationClick}
                                    selectedLocations={selectedLocations}
                                    sourceSuggestionIndex={index}
                                    sourceSuggestionId={suggestion.id}
                                    role="listitem"
                                />
                            );
                        })}
                    </>
                )}
            </StyledListContainer>
        </ContentContainer>
    );
};

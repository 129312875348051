import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import { getLocationDetailedLevelTranslation } from '@widgets/search/core/helpers/getLocationDetailedLevelTranslation';
import type { JSX } from 'react';

import type { LocationListNode, SelectableLocationListNode } from '../../types/list';
import {
    ListLabel,
    StyledListContainer,
    StyledListItem,
    StyledListItemSubtitle,
    StyledListItemTitle,
    StyledListItemWrapper,
} from '../Shared.theme';

export const LocationItems = ({
    onLocationClick,
    parentLocation,
    locations,
}: {
    locations: LocationListNode[];
    parentLocation: LocationListNode | null;
} & SelectableLocationListNode): JSX.Element => {
    const [t] = useTranslations();

    // get detailed level from first location as all of them should be the same
    const listDetailedLevelTranslatedText = locations[0]
        ? getLocationDetailedLevelTranslation(t, locations[0].detailedLevel)
        : '';

    return (
        <>
            {parentLocation ? (
                <StyledListContainer>
                    <StyledListItemWrapper>
                        <StyledListItem
                            onClick={() =>
                                onLocationClick(parentLocation, {
                                    tryOpenSublocations: false,
                                    touchPointButton: 'location_tree',
                                })
                            }
                        >
                            <StyledListItemTitle data-cy="location.listview.parent.item">
                                {t('frontend.search.form.location.select-parent-item-text', {
                                    name: parentLocation.name,
                                    locationLevel: getLocationDetailedLevelTranslation(t, parentLocation.detailedLevel),
                                })}
                            </StyledListItemTitle>
                        </StyledListItem>
                    </StyledListItemWrapper>
                </StyledListContainer>
            ) : null}

            <ListLabel variant="bold">
                {t('frontend.search.form.location.select-location-for-location-level-label', {
                    locationLevel: listDetailedLevelTranslatedText,
                })}
            </ListLabel>
            <StyledListContainer role="list">
                {locations.map((location) => {
                    const caption = getLocationDetailedLevelTranslation(t, location.detailedLevel);
                    const haveChildren = location.sublocations.length > 0;
                    const isOpenable = haveChildren || !location.parentId;

                    return (
                        <StyledListItemWrapper key={location.id}>
                            <StyledListItem
                                suffixIcon={isOpenable ? ArrowRight : undefined}
                                onClick={(): void => {
                                    onLocationClick(location, {
                                        touchPointButton: isOpenable ? 'expand_location' : 'location_tree',
                                    });
                                }}
                                role="listitem"
                            >
                                <StyledListItemTitle>{location.name}</StyledListItemTitle>
                                <StyledListItemSubtitle>{caption}</StyledListItemSubtitle>
                            </StyledListItem>
                        </StyledListItemWrapper>
                    );
                })}
            </StyledListContainer>
        </>
    );
};

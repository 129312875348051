import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const InitialViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${theme.space[16]} ${theme.space[16]} ${theme.space[16]};
`;

export const InitialViewIcon = styled.img`
    max-width: 120px;
    height: auto;
`;

export const InitialTextP2 = styled(P2)`
    padding: ${theme.space[16]} 0 0 0;
    text-align: center;
`;

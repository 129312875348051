import type { ErrorInvalidSearchFilters } from '@domains/search/types/search';
import type { GenericGraphQLError } from '@lib/graphql/error';
import type { FilterLocationByDomainId } from '@type/location/filterLocation';
import type { SearchingFilters } from '@type/search/searchingFilters';
import { gql } from '@urql/core';

interface CountAds {
    __typename: 'CountAds';
    count: number;
}

export const EXPECTED_COUNT_ADS_TYPENAMES: readonly CountAds['__typename'][] = ['CountAds'] as const;

interface Variables {
    filterAttributes?: Partial<SearchingFilters>;
    filterLocations?: FilterLocationByDomainId;
}

export const COUNT_ADS_QUERY = gql<
    {
        countAds: CountAds | GenericGraphQLError | ErrorInvalidSearchFilters;
    },
    Variables | undefined
>`
    query CountAds($filterAttributes: FilterAttributes, $filterLocations: FilterLocations) {
        countAds(filterAttributes: $filterAttributes, filterLocations: $filterLocations) {
            __typename
            ... on CountAds {
                count
            }
        }
    }
`;

import { LOCATION_PICKER_DEFAULT_VIEW } from './types/locationSearchProps';

export const DEFAULT_CONFIG = {
    defaultView: LOCATION_PICKER_DEFAULT_VIEW.default,
    multiple: false,
    streetSearchConfig: {
        enabled: false,
        googleSuggestionTypes: [],
    },
    enableSearchFromList: false,
    recentSuggestions: {
        enabled: false,
        localStorageKey: '',
        locationsToRemember: 0,
    },
    tracking: {
        touchPointPage: null,
        entryInputTouchPointButton: null,
    },
};

export const STORAGE_KEY_LOCATIONS = 'LocationSearchListLocations';

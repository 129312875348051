import { gql } from '@urql/core';

export const AUTOCOMPLETE_QUERY = gql`
    query autocomplete(
        $query: String!
        $ranking: RankingSystemInput
        $levels: [String!]
        $isLocationSearch: Boolean!
        $locationLevelLikeDistrictAndSubdistrict: [String!]
    ) {
        autocomplete(query: $query, ranking: $ranking, levels: $levels) {
            ... on FoundLocations {
                locationsObjects {
                    id
                    detailedLevel
                    name
                    fullName
                    parents {
                        id
                        detailedLevel
                        name
                        fullName
                    }
                    parentIds
                    children(input: { limit: 4, filters: { levels: $locationLevelLikeDistrictAndSubdistrict } })
                        @include(if: $isLocationSearch) {
                        id
                        detailedLevel
                        name
                        fullName
                        parents {
                            id
                            detailedLevel
                            name
                            fullName
                        }
                        children(input: { limit: 1, filters: { levels: $locationLevelLikeDistrictAndSubdistrict } }) {
                            id
                            detailedLevel
                            name
                            fullName
                            parents {
                                id
                                detailedLevel
                                name
                                fullName
                            }
                        }
                    }
                }
            }
            ... on ErrorInternal {
                message
            }
        }
    }
`;

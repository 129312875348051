import type { ExplicitDropdownOption } from '@type/search/dropdownOption';
import type { DropdownProps } from '@widgets/search/types/dropdownProps';
import type { JSX } from 'react';

import { useMultiCheckboxField } from './hooks/useMultiCheckboxField';
import { ChipCheckbox, Fieldset, Label, Legend, List, ListItem, StyledTick } from './MultiCheckboxField.theme';

type Props = Omit<DropdownProps, 'trackOnSelect'> & {
    legend: string;
    isInline?: boolean;
    trackOnSelect: (option: ExplicitDropdownOption<boolean> | null, allCheckedOptions?: string[]) => void;
};

export const MultiCheckboxField = ({
    legend,
    options,
    name,
    inputId,
    dataCy,
    isInline,
    trackOnSelect,
}: Props): JSX.Element => {
    const shouldDisplayRoomChips = Boolean(isInline);

    const { fieldValue, handleOnCheckboxChange } = useMultiCheckboxField({
        name,
        trackOnSelect,
    });

    return (
        <Fieldset>
            <Legend>{legend}</Legend>
            <List data-cy={dataCy} id={inputId} shouldDisplayRoomChips={shouldDisplayRoomChips}>
                {options.map((item, index) => {
                    const isChecked = Boolean(fieldValue?.includes(item.value));

                    return (
                        <ListItem key={`list-item-${index}-${item.value}`}>
                            <ChipCheckbox
                                checked={isChecked}
                                data-cy={`${dataCy}--${item.value}`}
                                id={`list-item-${item.value}`}
                                type="checkbox"
                                value={item.value}
                                onChange={handleOnCheckboxChange}
                            />
                            <Label data-cy={`${dataCy}--${item.value}--label`} htmlFor={`list-item-${item.value}`}>
                                {item.label}
                                <StyledTick size="icon-small" />
                            </Label>
                        </ListItem>
                    );
                })}
            </List>
        </Fieldset>
    );
};

import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { type JSX, useContext, useMemo } from 'react';

import { DistanceRadiusDropdown } from './components/DistanceRadius';
import { EntryInput } from './components/EntryInput';
import { useLocationSearch } from './contexts/useLocationSearch';
import { getDropdownContent } from './helpers/getDropdownContent';
import { LocationSearchContainer } from './LocationSearchBase.theme';

export const LocationSearchBase = ({ className }: { className?: string }): JSX.Element => {
    const {
        distanceRadius: { isDistanceRadiusVisible },
        locationSearchView,
        config,
        locations: { selectedLocations, temporarySuggestions },
    } = useLocationSearch();

    const { isDesktop } = useContext(RWDContext);

    const content = useMemo(
        () =>
            getDropdownContent({
                locationSearchType: locationSearchView,
                selectedLocations,
                temporarySuggestions,
                isDesktop,
                config,
            }),
        [config, isDesktop, locationSearchView, selectedLocations, temporarySuggestions],
    );

    return (
        <LocationSearchContainer
            className={className}
            onClick={(event): void => {
                // avoid closing dropdown when performing any action in dropdown
                // close on click outside of container still should work with this
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <EntryInput Content={content} />
            {isDistanceRadiusVisible ? <DistanceRadiusDropdown /> : null}
        </LocationSearchContainer>
    );
};

import type { JSX } from 'react';

import { LocationsChipList } from './LocationsChipList';
import { ChipsInputContainer } from './LocationsInput.theme';
import type { LocationsInputProps } from './types';

export const ChipsInput = ({ selectedLocations, onUnselectLocation }: LocationsInputProps): JSX.Element => {
    return (
        <ChipsInputContainer>
            <LocationsChipList selectedLocations={selectedLocations} onUnselectLocation={onUnselectLocation} />
        </ChipsInputContainer>
    );
};

import Search from '@nexus/lib-react/dist/core/Icon/icons/default/Search';
import type { JSX } from 'react';

import { DEFAULT_INPUT_ID } from './constants';
import { ChipsInput } from './LocationsChipsInput';
import { Label } from './LocationsInput.theme';
import type { LocationsInputProps } from './types';

export const LocationsInput = ({
    inputId = DEFAULT_INPUT_ID,
    selectedLocations,
    onUnselectLocation,
}: LocationsInputProps): JSX.Element => {
    return (
        <Label htmlFor={inputId}>
            <Search test-id="search-icon" />
            <ChipsInput
                inputId={inputId}
                selectedLocations={selectedLocations}
                onUnselectLocation={onUnselectLocation}
            />
        </Label>
    );
};

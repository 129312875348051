import { useTracking } from '@lib/tracking/useTracking';
import type { FlyoutProps } from '@nexus/lib-react/dist/core/Dropdown';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { useLocationSearch } from '../../contexts/useLocationSearch';
import { checkShouldDisplayCityView } from '../../helpers/checkShouldDisplayCityView';
import { useGetTrackingSharedValues } from '../../hooks/useGetTrackingSharedValues';
import type { SelectableLocations } from '../../types/locationSearchProps';
import { DesktopContainer } from '../DesktopContainer';
import { LocationInput } from '../LocationInput';
import { InitialView } from './InitialView';
import { SuggestionsList } from './SuggestionsList';

export const DesktopSuggestions = ({ setDropdownState }: FlyoutProps<unknown>): JSX.Element => {
    const { trackEvent } = useTracking();
    const {
        searchValue,
        setSearchValue,
        resetState,
        changeLocationSearchView,
        locations: { selectedLocations, selectLocations },
        config: {
            tracking: { touchPointPage },
            multiple,
        },
    } = useLocationSearch();

    const { getTrackingSharedValue } = useGetTrackingSharedValues();
    const isSingleLocationSelected = selectedLocations.length === 1;
    const shouldDisplaySuggestionsList = searchValue || isSingleLocationSelected;
    const touchPointButton = shouldDisplaySuggestionsList ? 'autosuggest_locations' : 'location_picker_main';

    const onLocationClick: SelectableLocations['onLocationClick'] = useCallback(
        (suggestion: LocationsObjects, { touchPointButton, locationListPosition, locationParentId }): void => {
            trackEvent(
                'search_box_list_selection_click',
                getTrackingSharedValue({
                    eventType: 'click',
                    touchPointButton,
                    locationParentId,
                    locationListPosition,
                    locations: [suggestion],
                }),
            );

            selectLocations([suggestion]);
            setSearchValue('');

            const shouldDisplayCityView = checkShouldDisplayCityView(suggestion);

            if (shouldDisplayCityView && multiple) {
                changeLocationSearchView('city');

                return;
            }

            setDropdownState?.(false);
        },
        [
            changeLocationSearchView,
            getTrackingSharedValue,
            multiple,
            selectLocations,
            setDropdownState,
            setSearchValue,
            trackEvent,
        ],
    );

    const onClear = useCallback(() => {
        resetState();
        trackEvent('clear_filters', {
            event_type: 'click',
            touch_point_button: touchPointButton,
            touch_point_page: touchPointPage,
        });
    }, [resetState, touchPointButton, touchPointPage, trackEvent]);

    return (
        <DesktopContainer setDropdownState={setDropdownState}>
            <LocationInput selectedLocation={selectedLocations[0]} onClear={onClear} />
            {shouldDisplaySuggestionsList ? (
                <SuggestionsList
                    searchValue={searchValue}
                    onLocationClick={onLocationClick}
                    selectedLocations={selectedLocations}
                />
            ) : (
                <InitialView onLocationClick={onLocationClick} />
            )}
        </DesktopContainer>
    );
};

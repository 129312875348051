import type { GoogleSuggestion } from '@type/search/google';
import type { JSX } from 'react';

import { GoogleLicence } from '../GoogleLicence/GoogleLicence';
import {
    StyledListContainer,
    StyledListItem,
    StyledListItemSubtitle,
    StyledListItemTitle,
    StyledListItemWrapper,
} from '../Shared.theme';

export interface StreetListProps {
    suggestions: GoogleSuggestion[];
    selectedSuggestion: GoogleSuggestion | null;
    onSuggestionClick: (suggestion: GoogleSuggestion, config: { locationListPosition: number }) => void;
    captionPrefix: string;
}

export const StreetList = ({
    suggestions,
    selectedSuggestion,
    onSuggestionClick,
    captionPrefix,
}: StreetListProps): JSX.Element => {
    // selected suggestion if exist in search should appear at first position
    const sortedSuggestions = [...suggestions].sort((suggestion) =>
        suggestion.placeId === selectedSuggestion?.placeId ? -1 : 0,
    );

    return (
        <>
            <StyledListContainer role="list">
                {sortedSuggestions.map((suggestion, index) => {
                    return (
                        <StyledListItemWrapper key={suggestion.placeId}>
                            <StyledListItem
                                onClick={(): void => onSuggestionClick(suggestion, { locationListPosition: index })}
                                role="listitem"
                            >
                                <StyledListItemTitle>{suggestion.name.split(',')[0]}</StyledListItemTitle>
                                <StyledListItemSubtitle>
                                    {captionPrefix}, {suggestion.name.split(',')[1]}
                                </StyledListItemSubtitle>
                            </StyledListItem>
                        </StyledListItemWrapper>
                    );
                })}
            </StyledListContainer>
            <GoogleLicence />
        </>
    );
};

import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { InitialTextP2, InitialViewContainer, InitialViewIcon } from './StreetView.theme';

export const InitialView = (): JSX.Element => {
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();

    return (
        <InitialViewContainer>
            <InitialViewIcon
                src={`${staticAssetsPrefix}/images/search/document-magnifying-glass.png`}
                alt={t('frontend.search.no-results.title')}
                loading="lazy"
            />
            <InitialTextP2>{t('frontend.search.form.location.street.suggestions.instruction')}</InitialTextP2>
        </InitialViewContainer>
    );
};

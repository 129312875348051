import styled from '@emotion/styled';

import { SEARCH_WIDGET_THEME } from '../../../theme/[[[SITECODE]]]';

// Override styles for placeholder so the text color matches other filters text on search form
export const Container = styled.div`
    .react-select__control .react-select__placeholder {
        opacity: 0.6;
        color: ${SEARCH_WIDGET_THEME.searchForm.dropdown.placeholder.color};
    }
`;

import type { DropdownOption } from '@type/search/dropdownOption';
import type { FilterOption as Option } from '@type/search/filters/option';
import type { ChangeEvent, Dispatch, RefObject, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import type { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';

import { useIsDropdownExpanded } from './useIsDropdownExpanded';
import { usePickedOptions } from './usePickedOptions';

interface Props {
    name: string;
    options: ReadonlyArray<Option> & ReadonlyArray<DropdownOption>;
    withChips?: boolean;
    trackingOnDropdownExpanded?: () => void;
    trackOnSelect?: (value: string[]) => void;
}

interface ReturnValue {
    dropdownRef: RefObject<HTMLDivElement> | null;
    fieldValue: string[];
    isDropdownExpanded: boolean;
    pickedOptions: string | Option<string>[] | null;
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
    setIsDropdownExpanded: Dispatch<SetStateAction<boolean>>;
    setValue: UseFormSetValue<FieldValues>;
}

export const useDropdownWithCheckboxes = ({
    name,
    options,
    withChips = false,
    trackingOnDropdownExpanded = (): void => undefined,
    trackOnSelect = (): void => undefined,
}: Props): ReturnValue => {
    const { setValue } = useFormContext();
    const fieldValue = useWatch({ name });
    const [currentValues, setCurrentValues] = useState(fieldValue);
    const pickedOptions = usePickedOptions({ fieldValue, options, withChips });

    const handleCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            const itemValue = event.target.value;
            const currentFieldValues: string[] = fieldValue ?? [];

            if (currentFieldValues.includes(itemValue)) {
                setValue(
                    name,
                    currentFieldValues.filter((value) => value !== itemValue),
                    { shouldDirty: true },
                );
            } else {
                setValue(name, [...currentFieldValues, itemValue], { shouldDirty: true });
            }
        },
        [fieldValue, name, setValue],
    );

    const handleClickOutside = (): void => {
        const values = fieldValue;

        if (JSON.stringify(values) !== JSON.stringify(currentValues)) {
            trackOnSelect(values);
            setCurrentValues(values);
        }
    };

    const [dropdownRef, isDropdownExpanded, setIsDropdownExpanded] = useIsDropdownExpanded({
        onClickOutside: handleClickOutside,
        onDropdownExpanded: trackingOnDropdownExpanded,
    });

    return {
        dropdownRef,
        fieldValue,
        isDropdownExpanded,
        pickedOptions,
        handleCheckboxChange,
        setIsDropdownExpanded,
        setValue,
    };
};

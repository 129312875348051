import type { FieldMetadata } from '@type/search/fields';
import type { ElementType } from 'react';

import { CheckboxField } from './CheckboxField/CheckboxField';
import { InputField } from './InputField/InputField';
import { MultiCheckboxField } from './MultiCheckboxField/MultiCheckboxField';
import { MultiSelectField } from './MultiSelectField/MultiSelectField';
import { RadioGroupField } from './RadioGroupField/RadioGroupField';
import { RangeField } from './RangeField/RangeField';
import { SelectField } from './SelectField/SelectField';

export const COMPONENT_MAP: Record<FieldMetadata['fieldType'], ElementType> = {
    checkbox: CheckboxField,
    input: InputField,
    multiCheckbox: MultiCheckboxField,
    multiSelect: MultiSelectField,
    radioGroup: RadioGroupField,
    range: RangeField,
    select: SelectField,
};
